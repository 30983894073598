<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-left">Category</th>
            <th class="text-left">Lbs</th>
            <th class="text-left">%</th>
            <th class="text-left">Rejection</th>
            <th class="text-left">Tare Applicable</th>
            <th class="text-left">Cull Tare</th>
            <th class="text-left">Reject</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>MOT</b></td>
            <td>{{ gradedData.mot.lbs | number('0,0.00') }}</td>
            <td>{{ gradedData.mot.percentage | number('0,0.00') }}</td>
            <td>{{ currentSeason.grading_data.mot.rejection | number('0,0.00') }}</td>
            <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_mot">{{ comp.from | number('0,0.00') }}-{{ comp.to | number('0,0.00') }}%x{{ comp.tear | number('0,0.00') }}</div></td>
            <td>{{ gradedData.mot.cullTear | number('0,0.00') }}</td>
            <td>{{gradedData.mot.reject ? '*' : ''}}</td>
          </tr>
          <tr>
            <td><b>Grass Green</b></td>
            <td>{{ gradedData.grassGreen.lbs | number('0,0.00') }}</td>
            <td>{{ gradedData.grassGreen.percentage | number('0,0.00') }}</td>
            <td>{{ currentSeason.grading_data.grassGreen.rejection | number('0,0.00') }}</td>
            <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_grassGreen">{{ comp.from | number('0,0.00') }}-{{ comp.to | number('0,0.00') }}%x{{ comp.tear | number('0,0.00') }}</div></td>
            <td>{{ gradedData.grassGreen.cullTear | number('0,0.00') }}</td>
            <td>{{gradedData.grassGreen.reject ? '*' : ''}}</td>
          </tr>
          <tr>
            <td><b>Processing Green</b></td>
            <td>{{ gradedData.processingGreen.lbs | number('0,0.00') }}</td>
            <td>{{ gradedData.processingGreen.percentage | number('0,0.00') }}</td>
            <td>{{ currentSeason.grading_data.processingGreen.rejection | number('0,0.00') }}</td>
            <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_processingGreen">{{ comp.from | number('0,0.00') }}-{{ comp.to | number('0,0.00') }}%x{{ comp.tear | number('0,0.00') }}</div></td>
            <td>{{ gradedData.processingGreen.cullTear | number('0,0.00') }}</td>
            <td>{{gradedData.processingGreen.reject ? '*' : ''}}</td>
          </tr>
          <tr>
            <td><b>Processing Breakers</b></td>
            <td>{{ gradedData.processingBreakers.lbs | number('0,0.00') }}</td>
            <td>{{ gradedData.processingBreakers.percentage | number('0,0.00') }}</td>
            <td>{{ currentSeason.grading_data.processingBreakers.rejection | number('0,0.00') }}</td>
            <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_processingBreakers">{{ comp.from | number('0,0.00') }}-{{ comp.to | number('0,0.00') }}%x{{ comp.tear | number('0,0.00') }}</div></td>
            <td>{{ gradedData.processingBreakers.cullTear | number('0,0.00') }}</td>
            <td>{{gradedData.processingBreakers.reject ? '*' : ''}}</td>
          </tr>
          <tr>
            <td><b>Other Defects</b></td>
            <td>{{ gradedData.otherDefects.lbs | number('0,0.00') }}</td>
            <td>{{ gradedData.otherDefects.percentage | number('0,0.00') }}</td>
            <td>{{ currentSeason.grading_data.otherDefects.rejection | number('0,0.00') }}</td>
            <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_otherDefects">{{ comp.from | number('0,0.00') }}-{{ comp.to | number('0,0.00') }}%x{{ comp.tear | number('0,0.00') }}</div></td>
            <td>{{ gradedData.otherDefects.cullTear | number('0,0.00') }}</td>
            <td>{{ gradedData.otherDefects.reject ? '*' : ''}}</td>
          </tr>
          <tr>
            <td><b>Limited Use</b></td>
            <td>{{ gradedData.limitedUse.lbs | number('0,0.00') }}</td>
            <td>{{ gradedData.limitedUse.percentage | number('0,0.00') }}</td>
            <td>{{ currentSeason.grading_data.limitedUse.rejection | number('0,0.00') }}</td>
            <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_limitedUse">{{ comp.from | number('0,0.00')}}-{{ comp.to | number('0,0.00') }}%x{{ comp.tear | number('0,0.00') }}</div></td>
            <td>{{ gradedData.limitedUse.cullTear | number('0,0.00') }}</td>
            <td>{{ gradedData.limitedUse.reject ? '*' : ''}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>Clean</b></td>
            <td>{{ (gradeData.total_sample_weight - gradedData.mot.lbs - gradedData.grassGreen.lbs - gradedData.processingGreen.lbs - gradedData.processingBreakers.lbs - gradedData.otherDefects.lbs - gradedData.limitedUse.lbs) | number('0,0.00') }}</td>
            <td>{{ (((gradeData.total_sample_weight - gradedData.mot.lbs - gradedData.grassGreen.lbs - gradedData.processingGreen.lbs - gradedData.processingBreakers.lbs - gradedData.otherDefects.lbs - gradedData.limitedUse.lbs) / gradeData.total_sample_weight) * 100) | number('0,0.00') }}%</td>
            <td>{{  }}</td>
            <td></td>
            <td>{{  }}</td>
            <td>{{  }}</td>
          </tr>
          <tr>
            <td><b>Total</b></td>
            <td>{{ gradeData.total_sample_weight | number('0,0.00') }}</td>
            <td>100%</td>
            <td>{{  }}</td>
            <td></td>
            <td>{{  }}</td>
            <td>{{  }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>AGTRON</b></td>
            <td>{{ gradeData.agtron | number('0,0.00') }}</td>
            <!-- <td>{{ currentSeason.grading_data.agtron.rejection }}</td> -->
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>Refractometer</b></td>
            <td>{{ gradeData.refractometer | number('0,0.00') }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>

  import axios from '../service/axios.config';
  import grading from '../util/grading';


  export default {
    name: 'CertificatePreview',
    components: {
    
    },
    props: {
      gradeData: {}
    },
    data: function() {
      return {
        gradedData: {}
      }
    },
    methods: {
      loadData: function(){

        axios.get("/season", {
          params: {
            active: true,
          }
        }).then((response) => {
          this.currentSeason = response.data.seasons[0];
          this.gradedData = grading(this.currentSeason.grading_data, this.gradeData);
        });
      }
    },
    computed: {
      compounding_mot: function() {
        return this.currentSeason.grading_data.mot.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_grassGreen: function() {
        return this.currentSeason.grading_data.grassGreen.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_processingGreen: function() {
        return this.currentSeason.grading_data.processingGreen.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_processingBreakers: function() {
        return this.currentSeason.grading_data.processingBreakers.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_otherDefects: function() {
        return this.currentSeason.grading_data.otherDefects.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_limitedUse: function() {
        return this.currentSeason.grading_data.limitedUse.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
    },
    watch: {

    },
    mounted: function(){
      this.loadData();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

<style scoped>
.compounding_li{
  font-size: 0.7em;
}
</style>
