<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card width="100%">
          <v-card-text>
            <div id="printable" class="cert">
              <div class="container">
                <div class="row">
                  <div class="col">

                    <div class="row">
                      <h5 class="d-flex justify-content-center">Highbury Canco Corporation Tomato Waste Certificate</h5>
                    </div>

                    <div class="row">
                      <h5 class="d-flex justify-content-end">Certificate No: {{disposal.id}}</h5>
                    </div>

                    <div class="row">
                      <h5 class="d-flex justify-content-end">Scale at: {{ new Date(disposal.gross_weight_at + ' UTC').toLocaleString() }}</h5>
                    </div>

                    <div class="row" style="margin-top: 25px;">
                      <table>
                        <tr>
                          <td style="padding-right: 100px;"><b>Hauler:</b></td>
                          <td>{{`${disposal.hauler.id} - ${disposal.hauler.name}`}}</td>
                        </tr>
                        <tr>
                          <td style="padding-right: 100px;"><b>Address:</b></td>
                          <td>
                            <div>{{ disposal.hauler.street_address }}</div>
                            <div>Lot: {{ disposal.hauler.lot }} Concession: {{ disposal.hauler.concession }} Township: {{ disposal.hauler.township }}</div>
                            <div>{{ disposal.hauler.city }}, {{ disposal.hauler.province }}</div>
                            <div>{{ disposal.hauler.postal }} {{ disposal.hauler.township }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-right: 100px;"> <b>Truck:</b></td>
                          <td>{{ disposal.truck.code }}</td>
                        </tr>
                        <tr>
                          <td style="padding-right: 100px;"> <b>Destination:</b></td>
                          <td>{{ disposal.destination }}</td>
                        </tr>
                        <tr>
                          <td style="padding-right: 100px;"> <b>Waste Type:</b></td>
                          <td>{{ disposal.type.name }}</td>
                        </tr>
                      </table>
                    </div>

                    <div class="row" style="margin-top: 50px;">
                      <table>
                        <tr>
                          <td style="padding-right: 100px;"><b>Tare:</b></td>
                          <td>{{ disposal.tear_weight }} Lbs</td>
                        </tr>
                        <tr>
                          <td style="padding-right: 100px;"><b>Gross:</b></td>
                          <td>{{ disposal.gross_weight }} Lbs</td>
                        </tr>
                        <tr>
                          <td style="padding-right: 100px;"> <b>Net:</b></td>
                          <td>{{ disposal.gross_weight - disposal.tear_weight }} Lbs</td>
                        </tr>
                      </table>
                    </div>

                    <div v-if="disposal.scaler" style="margin-top: 50px; text-align: center;">Scale User: {{ disposal.scaler.name }} (ID: {{ disposal.scaler.id }})</div>

                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" v-on:click="printCertificate()">Print Cert</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  // import axios from '../service/axios.config';
  // import grading from '../util/grading';


  export default {
    name: 'WasteCertificateViewer',
    components: {
    
    },
    props: {
      disposal: {}
    },
    data: function() {
      return {
      }
    },
    methods: {
      printCertificate: function(){
        this.$htmlToPaper("printable");
      },
    },
    computed: {
    },
    watch: {

    },
    mounted: function(){
      console.log(this.disposal);
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

<style scoped>
  .cert{
    padding: 25px;
  }

  .compounding_li{
    list-style: none;
  }
</style>
