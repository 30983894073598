<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="newCountry"
              label="New country name"
              solo
            ></v-text-field>
            <v-btn v-on:click="store()" color="primary" block>Add</v-btn>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">
            <v-list>
              <template v-for="(country, index) in countries">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="country.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn v-on:click="destroy(country)" color="error">Delete</v-btn>
                  </v-list-item-action>
                </v-list-item>
                  <v-divider
                    v-if="index < countries.length - 1"
                    :key="index"
                  ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'CountrySettings',
    components: {},
    data: function() {
      return {
        countries: [],
        newCountry: ''
      }
    },
    methods: {
      destroy: function(country){
        axios.delete(`/country/${country.id}`).then((response) => {
          if(response.status == 200){
            this.countries = this.countries.filter((loopedCountry) => {
              if(loopedCountry.id != country.id){
                return loopedCountry;
              }
            });
          }
        });
      },
      store: function(){
        axios.post(`/country`, {
          name: this.newCountry
        }).then((response) => {
          this.countries = [...this.countries, response.data.country];
        });
        this.newCountry = '';
      }
    },
    watch: {

    },
    mounted: function() {
      axios.get('/country').then((response) => {
        this.countries = response.data.countries;
      });
    },
    created: function() {

    },
    destroyed: function() {

    }
  }
</script>
