<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDatePicker"
              label="Selected date for deliveries"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDatePicker"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>

        <v-card
          class="d-flex pa-2 flex-wrap"
          outlined
          tile
          :key="rerenderCalendar"
        >
          <div v-for="hour in hours" v-bind:key="hour">
            <h3>{{ hour }}:00</h3>
            <h5>{{ hourlyTonnage[hour] }}</h5>

            <table style="width: 20%">
              <tr>
                <th>:00</th>
                <th>:15</th>
                <th>:30</th>
                <th>:45</th>
              </tr>

              <tr class="dateSelectionRow" v-for="i in is" v-bind:key="i">
                <td
                  :class="calendar[`${hour}:0`] ? 'selected' : ''"
                  :id="hour + ':0-' + i"
                  v-on:click="clickDate(`${hour}:0-${i}`)"
                ></td>
                <td
                  :class="calendar[`${hour}:15`] ? 'selected' : ''"
                  :id="hour + ':15-' + i"
                  v-on:click="clickDate(`${hour}:15-${i}`)"
                ></td>
                <td
                  :class="calendar[`${hour}:30`] ? 'selected' : ''"
                  :id="hour + ':30-' + i"
                  v-on:click="clickDate(`${hour}:30-${i}`)"
                ></td>
                <td
                  :class="calendar[`${hour}:45`] ? 'selected' : ''"
                  :id="hour + ':45-' + i"
                  v-on:click="clickDate(`${hour}:45-${i}`)"
                ></td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="overlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card light style="width: 500px">
              <v-card-title>Delivery Viewer</v-card-title>
              <v-card-text v-if="Object.keys(this.selectedDelivery).length > 0">
                <v-row>
                  <v-col cols="12" class="text-left">
                    <p>ID: {{ selectedDelivery.id }}</p>
                    <p>
                      Grower Code: {{ selectedDelivery.grower.code }} -
                      {{ selectedDelivery.grower.name }}
                    </p>
                    <p>
                      Start: {{ selectedDelivery.start.toLocaleTimeString() }}
                    </p>
                    <p>End: {{ selectedDelivery.end.toLocaleTimeString() }}</p>
                    <p>Weight In: {{ selectedDelivery.weight_in }}</p>
                    <p>Weight Out: {{ selectedDelivery.weight_out }}</p>

                    <v-progress-linear
                      :value="selectedStatus.val"
                      height="25"
                      rounded
                    >
                      <strong>{{ selectedStatus.msg }}</strong>
                    </v-progress-linear>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-if="!selectedDelivery.arrived">
                    <v-select
                      v-model="selectedVariety"
                      :items="varieties"
                      item-text="code"
                      label="Select tomato variety"
                      solo
                      return-object
                    ></v-select>
                    <v-select
                      v-model="selectedGrowerField"
                      :items="selectedDeliveryGrowerFields"
                      item-text="name"
                      label="Select grower field"
                      solo
                      return-object
                    ></v-select>
                    <v-text-field
                      v-model="trailerNumber"
                      label="Trailer No"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="error" text v-on:click="closeOverlay()"
                      >Close</v-btn
                    >
                    <v-btn
                      color="primary"
                      text
                      v-on:click="checkIn()"
                      v-if="!selectedDelivery.arrived"
                      >Check In</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "../service/axios.config";

export default {
  name: "ReceivingDeliveryCalendar",
  components: {},
  data: function () {
    return {
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      is: [1, 2, 3, 4, 5],
      calendar: {},
      deliveries: [],
      overlay: false,
      selectedDate: "",
      selectedDelivery: false,
      selectedDeliveryGrowerFields: [],
      selectedGrowerField: {},
      newDeliveryGrower: {},
      growers: [],
      currentSeason: {},
      menu: false,
      selectedDatePicker: "",
      queryDate: "",
      rerenderCalendar: false,
      selectedStatus: false,
      hourlyTonnage: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
      },
      varieties: [],
      selectedVariety: {},
      trailerNumber: "",
    };
  },
  methods: {
    loadData: function () {
      this.calendar = new Object();
      this.deliveries = new Array();
      this.selectedDate = "";
      this.selectedDelivery = false;
      this.selectedDeliveryGrowerFields = [];
      this.selectedGrowerField = {};
      this.newDeliveryGrower = {};
      this.growers = [];
      this.selectedStatus = false;

      this.hourlyTonnage = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
      };

      this.rerenderCalendar = !this.rerenderCalendar;

      let now = this.queryDate;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      // axios.get("/variety").then((response) => {
      //   this.varieties = response.data.varieties;
      //   this.selectedVariety = this.varieties[0];
      // });

      axios
        .get("/delivery", {
          params: {
            date: now,
          },
        })
        .then((response) => {
          this.deliveries = response.data.deliveries.map((el) => {
            el.start = new Date(el.start + " UTC");
            el.end = new Date(el.end + " UTC");
            el.name = el.grower.code;
            el.timed = true;
            return el;
          });

          this.deliveries.forEach((delivery) => {
            let date = new Date(delivery.start);
            this.hourlyTonnage[date.getHours()] += Number(
              delivery.grower.load_size
            );
          });

          this.deliveries.forEach((delivery) => {
            let date = new Date(delivery.start);
            if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${1}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${1}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${2}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${2}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${3}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${3}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${4}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${4}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${5}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${5}`] =
                delivery;
            }
          });

          Object.entries(this.calendar).forEach(([key, value]) => {
            document.getElementById(key).removeAttribute("class");
            document.getElementById(key).innerHTML =
              this.calendar[key].grower.code;
            if (value.canceled || !value.active) {
              document.getElementById(key).classList.add("canceled");
            } else if (value.scaled) {
              document.getElementById(key).classList.add("done");
            } else if (value.sentToScale) {
              document.getElementById(key).classList.add("scale");
            }  else if (value.graded || value.sentToGrade) {
              document.getElementById(key).classList.add("grade");
            } else if (value.arrived) {
              document.getElementById(key).classList.add("checked_in");
            } else {
              document.getElementById(key).classList.add("delivery");
            }

            if(value.default_id == null && value.active){
              document.getElementById(key).classList.add("extra");
            }
          });
        });
    },
    checkIn() {
      if (this.trailerNumber != "") {
        let updateDelivery = this.selectedDelivery;
        updateDelivery.arrived = true;
        updateDelivery.trailer_number = this.trailerNumber;
        updateDelivery.variety_id = this.selectedVariety.id;
        updateDelivery.field_id = this.selectedGrowerField.id;

        axios
          .patch(`/delivery/${updateDelivery.id}`, updateDelivery)
          .then((response) => {
            this.deliveries = this.deliveries.filter((delivery) => {
              if (response.data.delivery.id != delivery.id) {
                return delivery;
              }
            });

            this.selectedDelivery = this.deliveries[0];
            this.closeOverlay();
            this.loadData();
          });
      } else {
        this.errorMessage = "Must enter trailer number.";
        this.toggleError = true;
        setTimeout(() => {
          this.toggleError = false;
        }, 2000);
      }

      this.trailerNumber = "";
    },
    clickDate: function (id) {
      axios.get("/grower").then((response) => {
        this.growers = response.data.growers;
      });

      axios.get("/season", {
        params: {
          active: true,
        }
      }).then((response) => {
        this.currentSeason = response.data.seasons[0];

        this.varieties = this.currentSeason.varieties;
        this.selectedVariety = this.varieties[0];

        this.selectedDate = id;
        this.selectedDelivery = this.calendar[id];

        if(this.selectedDelivery.active && !this.selectedDelivery.arrived && !this.selectedDelivery.canceled){
          this.overlay = true;
        }
        
        this.selectedDeliveryGrowerFields = this.selectedDelivery.grower.grower_fields.filter((field) => {
          if(field.season_id == this.currentSeason.id){
            return field;
          }
        });

        this.selectedGrowerField = this.selectedDeliveryGrowerFields[0];

        if (this.selectedDelivery.weight_out) {
          this.selectedStatus = {
            val: 100,
            msg: "Weighed Out",
          };
        } else if (this.selectedDelivery.weight_in) {
          this.selectedStatus = {
            val: 75,
            msg: "Weighed In",
          };
        } else if (this.selectedDelivery.grades.length > 0) {
          this.selectedStatus = {
            val: 50,
            msg: "Graded",
          };
        } else if (this.selectedDelivery.arrived) {
          this.selectedStatus = {
            val: 25,
            msg: "Arrived",
          };
        } else {
          this.selectedStatus = {
            val: 0,
            msg: "Awaiting Arrival",
          };
        }
      });
    },
    closeOverlay: function () {
      this.overlay = false;
      this.selectedDate = "";
      this.selectedDelivery = false;
    },
    cancelDelivery: function (delivery) {
      let updateDelivery = delivery;
      updateDelivery.active = false;

      axios
        .patch(`/delivery/${updateDelivery.id}`, updateDelivery)
        .then(() => {
          this.loadData();
          this.closeOverlay();
        });
    },
    activateDelivery: function (delivery) {
      let updateDelivery = delivery;
      updateDelivery.active = true;

      axios
        .patch(`/delivery/${updateDelivery.id}`, updateDelivery)
        .then(() => {
          this.loadData();
          this.closeOverlay();
        });
    },
    saveNewDelivery: function () {
      // get selected time from box id
      let [hours, minutes] = this.selectedDate.split("-")[0].split(":");

      let [year, month, day] = this.selectedDatePicker.split("-");
      let date = new Date(year, month - 1, day, hours, minutes, 0, 0);

      let dateString = date.toISOString();

      axios
        .post("/delivery", {
          growerId: this.newDeliveryGrower.id,
          start: dateString,
          seasonId: this.currentSeason.id,
        })
        .then(() => {
          this.loadData();
          this.overlay = false;
        });
    },
  },
  watch: {
    selectedDatePicker: function () {
      let [year, month, day] = this.selectedDatePicker.split("-");
      this.queryDate = new Date(year, month - 1, day, 0, 0, 0, 0);
      this.loadData();
    },
  },
  mounted: function () {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    this.selectedDatePicker = now.toISOString().substr(0, 10);
    let [year, month, day] = this.selectedDatePicker.split("-");
    this.queryDate = new Date(year, month - 1, day, 0, 0, 0, 0);
  },
  created: () => {},
  destroyed: () => {},
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid gray;
  padding: 2px;
  border-radius: 2px;
}

td:hover {
  cursor: pointer;
  transform: scale(1.1);
  outline: 2px solid gray;
}

.canceled {
  background-color: red;
}

/* .done {
  background-color: LightGreen;
} */

.scale {
  background-color: black;
  color: white;
}

.grade {
  background-color: lightgreen;

}

.checked_in {
  background-color: dodgerblue;
}

.delivery {
  background-color: lightgray;
}

.extra {
  text-decoration: underline;
}

.dateSelectionRow {
  font-size: 0.75em;
}

.dateSelectionRow td {
  height: 28px;
  margin: 0px;
}

.dateSelectionRow td.delivery {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.canceled {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.done {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.arrived {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.active {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.notActive {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.extra {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.extraDeactivated {
  padding: 0px;
  margin: 0px;
}
</style>
