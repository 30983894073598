<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <v-row>
          <v-col>
            <v-select
              v-model="selectedCountry"
              :items="countries"
              item-text="name"
              return-object
              label="Select Country"
              outlined
              v-on:change="selectCountry()"
            ></v-select>
          </v-col>
          <v-col v-if="Object.keys(selectedCountry).length != 0">
            <v-select
              v-model="selectedProvince"
              :items="provinces"
              item-text="name"
              return-object
              label="Select Province"
              outlined
              v-on:change="selectProvince()"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="Object.keys(selectedProvince).length != 0">
          <v-col cols="12">
            <v-text-field
              v-model="newRegion"
              label="New region name"
              solo
            ></v-text-field>
            <v-btn v-on:click="store()" color="primary" block>Add</v-btn>
          </v-col>
        </v-row>


        <v-row v-if="Object.keys(selectedProvince).length != 0">
          <v-col cols="12">
            <v-list>
              <template v-for="(region, index) in regions">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="region.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn v-on:click="destroy(region)" color="error">Delete</v-btn>
                  </v-list-item-action>
                </v-list-item>
                  <v-divider
                    v-if="index < region.length - 1"
                    :key="index"
                  ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'RegionSettings',
    components: {},
    data: function() {
      return {
        countries: [],
        selectedCountry: {},
        provinces: [],
        selectedProvince: {},
        regions: [],
        newRegion: ''
      }
    },
    methods: {
      destroy: function(region){
        axios.delete(`/region/${region.id}`).then((response) => {
          if(response.status == 200){
            this.regions = this.regions.filter((loopedRegion) => {
              if(loopedRegion.id != region.id){
                return loopedRegion;
              }
            });
          }
        });
      },
      store: function(){
        axios.post(`/region`, {
          name: this.newRegion,
          provinceId: this.selectedProvince.id
        }).then((response) => {
          this.regions = [...this.regions, response.data.region];
        });
        this.newRegion = '';
      },
      selectCountry: function(){
        axios.get('/province', {
          params: {
            countryId: this.selectedCountry.id
          }
        }).then((response) => {
          this.provinces = response.data.provinces;
        });
      },
      selectProvince: function(){
        axios.get('/region', {
          params: {
            countryId: this.selectedCountry.id,
            provinceId: this.selectedProvince.id
          }
        }).then((response) => {
          this.regions = response.data.regions;
        });
      }
    },
    watch: {

    },
    mounted: function() {
      axios.get('/country').then((response) => {
        this.countries = response.data.countries;
      });
    },
    created: function() {

    },
    destroyed: function() {

    }
  }
</script>
