<template>
  <div class="wasteManagement">
    <v-row>
      <v-col cols="12">
        <h1>Waste Management</h1>

        <v-row>
          <v-col>
            <v-btn color="primary" v-on:click="toggleNewHauler()">Toggle New Hauler</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn color="primary" v-on:click="toggleNewTruck()">Toggle New Truck</v-btn>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
    <v-row>

      <v-col v-if="newHaulerMenuOpen" cols="12">
        <v-card>
          <v-card-title>Create Hauler</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="newHaulerName"
                  label="Name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="newHaulerLot"
                  label="Lot"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="newHaulerConcession"
                  label="Concession"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newHaulerStreetAddress"
                  label="Street Address"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="newHaulerProvince"
                  label="Province"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="newHaulerPostal"
                  label="Postal"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newHaulerCity"
                  label="City"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newHaulerRegion"
                  label="Region"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newHaulerTownship"
                  label="Township"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" v-on:click="saveNewHauler()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col v-if="newTruckMenuOpen" cols="12">
        <v-card>
          <v-card-title>Create Truck</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="newDisposalTruckHauler"
                  :items="haulers"
                  item-text="name"
                  return-object
                  label="Select Hauler"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="newDisposalTruckCode"
                  label="Truck Code"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="newDisposalTruckTare"
                  label="Tare weight"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" v-on:click="saveNewTruck()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Create Pickup</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-select
                  v-model="newDisposalHauler"
                  :items="haulers"
                  item-text="name"
                  return-object
                  label="Select Hauler"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-if="newDisposalHauler"
                  v-model="newDisposalTrailer"
                  :items="newDisposalHauler.trucks"
                  item-text="code"
                  return-object
                  label="Select Truck"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="newDisposalDestination"
                  label="Destination"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="newDisposalType"
                  :items="disposalTypes"
                  item-text="name"
                  return-object
                  label="Select waste type"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" v-on:click="saveNewDisposal()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Open Disposals</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card>
                  <v-container
                    style="height: 40vh"
                    class="overflow-y-auto"
                  >
                    <v-list two-line>
                      <template v-for="(disposal, index) in openDisposals">
                        <v-list-item :key="disposal.id" v-on:click="selectOpenDisposal(disposal)">
                          <v-list-item-content>
                            <v-list-item-title>Trailer No. {{disposal.truck.code}}</v-list-item-title>
                            <v-list-item-subtitle>Hauler: {{disposal.hauler.name}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider
                          v-if="index < disposal.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card key="selectedOpenDisposal" v-if="selectedOpenDisposal != null">
                  <v-card-title>Disposal Viewer</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-left">
                        <p>ID: {{selectedOpenDisposal.id}}</p>
                        <p>Hauler: {{selectedOpenDisposal.hauler.name}}</p>
                        <p>Trailer No: {{selectedOpenDisposal.truck.code}}</p>
                        <p>Tare Weight: {{selectedOpenDisposal.tear_weight}}</p>
                        <p>Gross Weight: {{selectedOpenDisposal.gross_weight}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="selectedOpenDisposalTearWeight"
                          label="Tare Weight"
                          suffix="lbs"
                          :disabled="(selectedOpenDisposal.tear_weight)?true:false"
                        ></v-text-field>
                        <v-btn v-if="!selectedOpenDisposal.tear_weight" color="primary" v-on:click="selectedOpenDisposalTear()">Tare Weight</v-btn>
                      </v-col>

                      <v-col cols="12" v-if="selectedOpenDisposal.tear_weight">
                        <v-text-field
                          v-model="selectedOpenDisposalGrossWeight"
                          label="Gross Weight"
                          suffix="lbs"
                          :disabled="(selectedOpenDisposal.gross_weight)?true:false"
                        ></v-text-field>
                        <v-btn v-if="!selectedOpenDisposal.gross_weight" color="primary" v-on:click="selectedOpenDisposalGross()">Gross Weight</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Closed Disposals</v-card-title>
          <v-card-text>
            <v-btn v-on:click="exportClosedDisposals()">Export</v-btn>
            <a v-if="exportDownloadLink" :href="exportDownloadLink" target="_blank" download="waste_summary_export.csv">Download Export</a>
            <!-- <v-progress-circular
              v-if="loadExport"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular> -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">ID</th>
                    <th class="text-left">Hauler</th>
                    <th class="text-left">Trailer No</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Tare Weight</th>
                    <th class="text-left">Tare At</th>
                    <th class="text-left">Gross Weight</th>
                    <th class="text-left">Gross At</th>
                    <th class="text-left">Net Weight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="disposal in closedDisposals"
                    :key="disposal.id"
                    v-on:click="selectDisposal(disposal)"
                  >
                    <td>{{ disposal.id }}</td>
                    <td>{{ disposal.hauler.name }}</td>
                    <td>{{ disposal.truck.id }}</td>
                    <td>{{ disposal.type.name }}</td>
                    <td>{{ disposal.tear_weight }}</td>
                    <td>{{ disposal.tear_weight_at }}</td>
                    <td>{{ disposal.gross_weight }}</td>
                    <td>{{ disposal.gross_weight_at }}</td>
                    <td>{{ disposal.gross_weight - disposal.tear_weight}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="viewCertificate"
      width="100%"
      
    >
      <WasteCertificateViewer :disposal="selectedDisposal" />
      <v-btn color="error" v-on:click="viewCertificate = false">Close</v-btn>
    </v-overlay>

  </div>
</template>

<script>

import axios from '../service/axios.config';
import Papa from 'papaparse';
import WasteCertificateViewer from '@/components/WasteCertificateViewer.vue';

export default {
  name: 'WasteManagement',
  components: {
    WasteCertificateViewer
  },
  data: function() {
    return {
      newHaulerMenuOpen: false,
      newTruckMenuOpen: false,
      newDisposalTruckHauler: {},
      newDisposalTruckCode: "",
      newDisposalTruckTare: "",
      haulers: [],
      openDisposals: [],
      selectedOpenDisposal: null,
      selectedOpenDisposalTearWeight: '',
      selectedOpenDisposalGrossWeight: '',
      closedDisposals: [],
      newHaulerName: '',
      newHaulerLot: '',
      newHaulerConcession: '',
      newHaulerStreetAddress: '',
      newHaulerCity: '',
      newHaulerPostal: '',
      newHaulerProvince: '',
      newHaulerRegion: '',
      newHaulerTownship: '',
      newDisposalHauler: null,
      newDisposalTrailer: '',
      selectedDisposal: {},
      viewCertificate: false,
      newDisposalDestination: "",
      loadExport: false,
      newDisposalType: {},
      disposalTypes: [],
      exportDownloadLink: null
    }
  },
  methods: {
    loadDataReset: function(){
      this.newHaulerMenuOpen = false;
      this.newTruckMenuOpen = false;
      this.newDisposalTruckHauler = {},
      this.newDisposalTruckCode = "",
      this.newDisposalTruckTare = "",
      this.haulers = [];
      this.openDisposals = [];
      this.selectedOpenDisposal = null;
      this.selectedOpenDisposalTearWeight = '';
      this.selectedOpenDisposalGrossWeight = '';
      this.closedDisposals = [];
      this.newHaulerName = '';
      this.newHaulerLot = '';
      this.newHaulerConcession = '';
      this.newHaulerStreetAddress = '';
      this.newHaulerCity = '';
      this.newHaulerPostal = '';
      this.newHaulerProvince = '';
      this.newHaulerRegion = '';
      this.newHaulerTownship = '';
      this.newDisposalHauler = null;
      this.newDisposalTrailer = '';
      this.selectedDisposal = {};
      this.viewCertificate = false;
      this.newDisposalDestination = "";
      this.loadExport = false;
      this.newDisposalType = {};
      this.disposalTypes = [];
      this.exportDownloadLink = null;

      axios.get(`/waste_type`).then((response) => {
        this.disposalTypes = response.data.waste_types;
      });

      axios.get(`/waste_hauler`).then((response) => {
        this.newDisposalHauler = response.data.waste_haulers[0];
        this.haulers = response.data.waste_haulers;
      });

      axios.get(`/waste_disposal`).then((response) => {
        this.openDisposals = response.data.waste_disposals.filter((disposal) => {
          if(disposal.gross_weight == null){
            return disposal;
          }
        }).sort((a, b) => {
          return a.trailer_number - b.trailer_number;
        });

        this.closedDisposals = response.data.waste_disposals.filter((disposal) => {
          if(disposal.gross_weight != null){
            return disposal;
          }
        });
      });

    },
    saveNewHauler: function(){
      axios.post(`/waste_hauler`, {
        name: this.newHaulerName,
        street_address: this.newHaulerStreetAddress,
        lot: this.newHaulerLot,
        concession: this.newHaulerConcession,
        province: this.newHaulerProvince,
        postal: this.newHaulerPostal,
        city: this.newHaulerCity,
        region: this.newHaulerRegion,
        township: this.newHaulerTownship,
      }).then(() => {
        this.loadDataReset();
      });
    },
    toggleNewHauler: function(){
      this.newHaulerMenuOpen = !this.newHaulerMenuOpen;
    },
    saveNewDisposal: function(){
      axios.post(`/waste_disposal`, {
        haulerId: this.newDisposalHauler.id,
        truckId: this.newDisposalTrailer.id,
        typeId: this.newDisposalType.id,
        destination: this.newDisposalDestination,
        gross_weight: null,
        tear_weight: null,
        gross_weight_at: null,
        tear_weight_at: null
      }).then(() => {
        this.loadDataReset();
      });
    },
    selectOpenDisposal: function(disposal){
      this.selectedOpenDisposal = disposal;
      this.selectedOpenDisposalTearWeight = disposal.truck.tare_weight;
    },
    selectedOpenDisposalTear: function(){
      let now = new Date();
      axios.patch(`/waste_disposal/${this.selectedOpenDisposal.id}`, {
        hauler_id: this.selectedOpenDisposal.hauler.id,
        trailer_number: this.selectedOpenDisposal.trailer_number,
        tear_weight: this.selectedOpenDisposalTearWeight,
        tear_weight_at: now,
        gross_weight: null,
        gross_weight_at: null,
      }).then(() => {
        this.loadDataReset();
      });
    },
    selectedOpenDisposalGross: function(){
      let now = new Date();
      axios.patch(`/waste_disposal/${this.selectedOpenDisposal.id}`, {
        hauler_id: this.selectedOpenDisposal.hauler.id,
        trailer_number: this.selectedOpenDisposal.trailer_number,
        tear_weight: this.selectedOpenDisposal.tear_weight,
        tear_weight_at: this.selectedOpenDisposal.tear_weight_at,
        gross_weight: this.selectedOpenDisposalGrossWeight,
        gross_weight_at: now,
      }).then(() => {
        this.loadDataReset();
      });
    },
    toggleNewTruck: function(){
      this.newTruckMenuOpen = !this.newTruckMenuOpen;
    },
    saveNewTruck: function(){
      axios.post(`/waste_truck`, {
        hauler_id: this.newDisposalTruckHauler.id,
        code: this.newDisposalTruckCode,
        tare_weight: this.newDisposalTruckTare,
      }).then(() => {
        this.loadDataReset();
      });
    },
    selectDisposal: function(disposal){
      this.viewCertificate = true;
      this.selectedDisposal = disposal;
    },
    exportClosedDisposals: function(){
      this.loading = true;
        let sheet = [];

        for (const disposal of this.closedDisposals) {

          const row = {
            ID: disposal.id,
            Hauler: disposal.hauler.name,
            TrailerNo: disposal.truck.id,
            TareWeight: Number(disposal.tear_weight).toFixed(2),
            TareAt: disposal.tear_weight_at,
            GrossWeight: Number(disposal.gross_weight).toFixed(2),
            GrossAt: disposal.gross_weight_at,
            NetWeight: (Number(disposal.gross_weight) - Number(disposal.tear_weight)).toFixed(2),
          }

          sheet = [...sheet, row];
        }

        const csv = Papa.unparse(sheet);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        this.exportDownloadLink = url;

        this.loading = false;
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function() {
    this.loadDataReset();
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
