<template>
  <div class="scaling">
    <h1>Receiving</h1>

    <ReceivingDeliveryCalendar/>

    <!-- <v-row>
      <v-col cols="6">
        <v-card>
          <v-container
            style="height: 80vh"
            class="overflow-y-auto"
          >
            <v-list two-line>
              <template v-for="(delivery, index) in deliveries">
                <v-list-item :key="delivery.id" v-on:click="selectDelivery(delivery)">
                  <v-list-item-content>
                    <v-list-item-title v-text="'Delivery ID: ' + delivery.id"></v-list-item-title>
                    <v-list-item-subtitle v-text="'Grower: ' + delivery.grower.code"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="delivery.start.toLocaleTimeString()"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < deliveries.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>Delivery Viewer</v-card-title>
          <v-card-text v-if="Object.keys(this.selectedDelivery).length > 0">
            <v-row>
              <v-col cols="12" class="text-left">
                <p>ID: {{selectedDelivery.id}}</p>
                <p>Grower Code: {{selectedDelivery.grower.code}}</p>
                <p>Active {{selectedDelivery.active}}</p>
                <p>Arrived: {{selectedDelivery.arrived}}</p>
                <p>Start: {{selectedDelivery.start.toLocaleTimeString()}}</p>
                <p>End: {{selectedDelivery.end.toLocaleTimeString()}}</p>
                <p>Weight In: {{selectedDelivery.weight_in}}</p>
                <p>Weight Out: {{selectedDelivery.weight_out}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="selectedVariety"
                  :items="varieties"
                  item-text="code"
                  label="Select tomato variety"
                  solo
                  return-object
                ></v-select>
                <v-text-field
                  v-model="trailerNumber"
                  label="Trailer No"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="!selectedDelivery.arrived">
                <v-btn color="primary" v-on:click="checkIn()">Check In</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->

    <!-- <v-alert
      dense
      type="error"
      v-if="toggleError"
    >{{errorMessage}}</v-alert> -->

  </div>
</template>

<script>

import axios from '../service/axios.config';
import ReceivingDeliveryCalendar from '@/components/ReceivingDeliveryCalendar.vue';

export default {
  name: 'ReceivingView',
  components: {
    ReceivingDeliveryCalendar
  },
  data: function() {
    return {
      selectedDelivery: {},
      deliveries: [],
      varieties: [],
      selectedVariety: {},
      trailerNumber: '',
      toggleError: false,
      errorMessage: ''
    }
  },
  methods: {
    selectDelivery: function(delivery){
      this.selectedDelivery = delivery;
      this.trailerNumber = '';
      this.selectedVariety = {};
    },
    checkIn(){
      if(this.trailerNumber != ""){
        let updateDelivery = this.selectedDelivery;
        updateDelivery.arrived = true;
        updateDelivery.trailer_number = this.trailerNumber;
        updateDelivery.variety_id = this.selectedVariety.id;

        axios.patch(`/delivery/${updateDelivery.id}`, updateDelivery).then((response) => {
          this.deliveries = this.deliveries.filter((delivery) => {
            if(response.data.delivery.id != delivery.id){
              return delivery
            }
          });

          this.selectedDelivery = this.deliveries[0];
        });
      } else {
        this.errorMessage = "Must enter trailer number."
        this.toggleError = true;
        setTimeout(() => {
          this.toggleError = false;
        }, 2000);
      }

      this.trailerNumber = '';
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function(){

    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    axios.get('/delivery', {
      params: {
        date: now,
        active: true,
        arrived: false
      }
    }).then((response) => {

      this.deliveries = response.data.deliveries.filter((el) => {
        if(el.weight_out == null){
          el.start = new Date(el.start  + ' UTC');
          el.end = new Date(el.end  + ' UTC');
          return el;
        }
      }).sort((el1, el2) => {
        return el1.start - el2.start;
      });

      this.selectedDelivery = this.deliveries[0];
    });

    axios.get('/variety').then((response) => {
      this.varieties = response.data.varieties;
    });
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
