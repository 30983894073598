<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" v-if="!otpGenerated">
        <v-text-field
          v-model="userName"
          label="User Name"
          placeholder="johndoe"
          solo
        ></v-text-field>

        <v-text-field
          v-model="password"
          type="password"
          label="Password"
          solo
        ></v-text-field>

        <v-btn color="primary" block v-on:click="generateOTP()">
          Login
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="otpGenerated">
        <v-text-field v-model="otp" label="OTP" solo></v-text-field>

        <v-btn color="primary" block v-on:click="login()"> Confirm OTP </v-btn>
      </v-col>
    </v-row>

    <!-- <v-alert v-if="loginError" dense outlined type="error">
      Invalid login credentials. Please try again.
    </v-alert> -->

    <v-snackbar v-model="loginError" timeout="2000">
      Invalid login credentials.
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "../service/axios.config";

export default {
  name: "LogIn",
  components: {},
  data: function () {
    return {
      userName: "",
      password: "",
      otp: "",
      otpGenerated: false,
      loginError: false,
    };
  },
  methods: {
    loadData: function () {
      this.userName = "";
      this.password = "";
      this.otp = "";
      this.otpGenerated = false;
      //this.loginError = false;
    },
    login: function () {
      this.$store
        .dispatch("AuthStore/login", {
          user_name: this.userName,
          password: this.password,
          otp: this.otp,
        })
        .then((loginAttempt) => {
          // this.userName = "";
          // this.password = "";
          // this.otp = "";

          if (loginAttempt) {
            this.loginError = false;
            this.$router.push("/");
          } else {
            this.loginError = true;

            // Reset
            // this.userName = "";
            // this.password = "";
            // this.otp = "";
            this.otpGenerated = false;
          }
        });
    },
    generateOTP: function () {
      axios
        .post("/auth/login", {
          user_name: this.userName,
          password: this.password,
        })
        .then((response) => {
          if(response.data.access_token){
            this.$store.dispatch("AuthStore/loginSave", {
              access_token: response.data.access_token,
              user: response.data.user
            }).then(() => {
              // this.userName = "";
              // this.password = "";
              // this.otp = "";

              this.$router.push("/");
            });
          } else {
            this.loginError = false;
            this.otpGenerated = true;
          }
        })
        .catch(() => {
          this.loginError = true;
          this.loadData();
        });
    },
  },
  watch: {},
  mounted: () => {},
  created: () => {},
  destroyed: () => {},
};
</script>
