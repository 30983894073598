<template>
  <div class="dashboard">
    <h1>Certificate Fix</h1>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>

            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="gradeNumber"
                  label="Certificate Number"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" v-on:click="getCertificate()">Search</v-btn>
              </v-col>
            </v-row>

            <v-row v-if="gradeData">
              <v-col>
                <p v-if="gradeData.previous_grade_id">REGRADE OF <b class="clickable" v-on:click="selectGrade(gradeData.previous_grade_id)">#{{ gradeData.previous_grade_id }}</b></p>
                <p v-if="gradeData.regrade_id">REGRADE BY <b class="clickable" v-on:click="selectGrade(gradeData.regrade_id)">#{{ gradeData.regrade_id }}</b></p>
                <v-text-field
                  v-model="selectedSearchGrowerDisplayText"
                  label="Grower"
                  outlined
                  disabled
                ></v-text-field>
                <v-select
                  v-model="selectedVariety"
                  :items="varieties"
                  item-text="code"
                  label="Select tomato variety"
                  outlined
                  return-object
                ></v-select>
                <v-select
                  v-model="selectedGrowerField"
                  :items="selectedGrowerFields"
                  item-text="name"
                  label="Select grower field"
                  outlined
                  return-object
                ></v-select>
                <v-text-field
                  type="number"
                  v-model="gradeData.refractometer"
                  label="Refractometer"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="deliveryData.trailer_number"
                  label="Trailer"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="deliveryData.weight_in"
                  label="Gross Weight"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="deliveryData.weight_out"
                  label="Tare Weight"
                  outlined
                ></v-text-field>
                <v-textarea
                  v-model="gradeData.fix_reason"
                  outlined
                  label="Reason"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn color="primary" v-on:click="updateGrade()">Update Certificate</v-btn>
                <v-btn color="primary" v-on:click="printCertificate(gradeData.delivery_id, gradeData.id)">Certificate</v-btn>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>

        <v-card>
          <v-card-title></v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                <v-row>
                  <v-select
                    v-model="selectedGrower"
                    :items="growers"
                    item-text="display-text"
                    label="Select Grower"
                    return-object
                    solo
                  ></v-select>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-menu
                      v-model="startMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Select start date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="startMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="endMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="Select end date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="endMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="onlyRegrade"
                    label="Only Regrade"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="onlyFixed"
                    label="Only Fixed"
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-btn color="primary" v-on:click="getFilteredGrades()">Filter</v-btn>
              <v-btn color="primary" v-on:click="clearFilters()">Clear</v-btn>
              <v-btn
                color="primary"
                v-on:click="exportTable()"
              >
                Generate
              </v-btn>

              <a v-if="CSVDownloadLink" :href="CSVDownloadLink" target="_blank" download="TableExport.csv">Download Export CSV</a>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Certificate #
                        </th>
                        <th class="text-left">
                          Grower
                        </th>
                        <th class="text-left">
                          Delivery ID
                        </th>
                        <th class="text-left">
                          Variety
                        </th>
                        <th class="text-left">
                          Scheduled
                        </th>
                        <!-- <th class="text-left">
                          Time
                        </th> -->
                        <th class="text-left">
                          Previous Grade
                        </th>
                        <th class="text-left">
                          Active Grade
                        </th>
                        <th class="text-left">
                          Fixed by
                        </th>
                        <th class="text-left">
                          Voided
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="grade in grades"
                        :key="grade.id"
                      >
                        <td>{{ grade.seasonal_certificate_id }}</td>
                        <td>{{ grade.delivery.grower.code }} - {{ grade.delivery.grower.name }}</td>
                        <td>{{ grade.delivery_id }}</td>
                        <td>{{ grade.variety ? grade.variety.code : '---' }}</td>
                        <td>{{ new Date(grade.delivery.start + " UTC").toLocaleString() }}</td>
                        <!-- <td>{{ new Date(grade.updated_at).toLocaleString() }}</td> -->
                        <td>{{ grade.previous_grade_id || '-' }}</td>
                        <td>{{ grade.regrade_id || '-' }}</td>
                        <td>{{ grade.fix_user ? grade.fix_user.name : '-' }}</td>
                        <td>{{ grade.voided }}</td>
                        <td>
                          <v-btn color="primary" v-on:click="selectGrade(grade.id)">Select</v-btn>
                          <v-btn color="primary" v-on:click="printCertificate(grade.delivery_id, grade.id)">Certificate</v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <v-btn color="primary" block v-on:click="loadMoreCertificates()">Load More</v-btn>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="viewCertificate"
      width="100%"
      
    >
    <CertificateViewer :deliveryId="selectedDeliveryId" :certificateId="selectedDeliveryGradeId" />
    <v-btn color="error" v-on:click="closeCert()">Close</v-btn>
  </v-overlay>

  <v-snackbar v-model="certNotification" timeout="2000">
    Certificate updated.
  </v-snackbar>

  </div>
</template>

<script>

import axios from '../service/axios.config';
import Papa from 'papaparse';
import CertificateViewer from '@/components/CertificateViewer.vue';

export default {
  name: 'CertificateFixView',
  components: {
    CertificateViewer
  },
  data: function() {
    return {
      gradeNumber: '',
      gradeData: null,
      deliveryData: null,
      varieties: null,
      selectedVariety: null,
      grades: [],
      viewCertificate: false,
      selectedDeliveryId: null,
      currentSeason: null,
      selectedGrowerFields: [],
      selectedGrowerField: {},
      onlyRegrade: false,
      onlyFixed: false,
      selectedDeliveryGradeId: null,
      certNotification: false,
      growers: [],
      selectedGrower: {},
      startMenu: false,
      startDate: null,
      endMenu: false,
      endDate: null,
      CSVDownloadLink: null,
      page: 1,
      page_quantity: 100,
      selectedSearchGrower: null,
      selectedSearchGrowerDisplayText: ''
    }
  },
  methods: {
    exportTable: function(){

      let sheet = [];

      for (const grade of this.grades) {

        const totalCullTare = Number(grade.calculated_grade.mot.cullTear) + Number(grade.calculated_grade.grassGreen.cullTear) + Number(grade.calculated_grade.processingGreen.cullTear) + Number(grade.calculated_grade.processingBreakers.cullTear) + Number(grade.calculated_grade.otherDefects.cullTear) + Number(grade.calculated_grade.limitedUse.cullTear);
        const netWeightDelivered = Number(grade.delivery.weight_in) - Number(grade.delivery.weight_out);
        const tarePenalty = netWeightDelivered * (totalCullTare / 100);
        const netPayableWeight = netWeightDelivered - tarePenalty;

        const row = {
          processor: "Highbury Canco Corporation",
            gradeType: 'Original',
            grower: grade.delivery.grower.name,
            certificate: grade.id,
            dateTime: grade.created_at,

            gradeOption: 7,
            colour: grade.agtron,
            colourlimit: 37,
            colorRejected: '',
            soluableSolids: grade.refractometer,
            variety: grade.variety.code,

            motWeight: grade.calculated_grade.mot.lbs,
            motPct: grade.calculated_grade.mot.percentage,
            motLimit: this.currentSeason.grading_data.mot.rejection,
            motReject: grade.calculated_grade.mot.reject,
            motTare: grade.calculated_grade.mot.cullTear,

            grassGreenWeight: grade.calculated_grade.grassGreen.lbs,
            grassGreenPct: grade.calculated_grade.grassGreen.percentage,
            grassGreenLimit: this.currentSeason.grading_data.grassGreen.rejection,
            grassGreenReject: grade.calculated_grade.grassGreen.reject,
            grassGreenTare: grade.calculated_grade.grassGreen.cullTear,

            processingGreenWeight: grade.calculated_grade.processingGreen.lbs,
            processingGreenPct: grade.calculated_grade.processingGreen.percentage,
            processingGreenLimit: this.currentSeason.grading_data.processingGreen.rejection,
            processingGreenReject: grade.calculated_grade.processingGreen.reject,
            processingGreenTare: grade.calculated_grade.processingGreen.cullTear,

            processingBreakersWeight: grade.calculated_grade.processingBreakers.lbs,
            processingBreakersPct: grade.calculated_grade.processingBreakers.percentage,
            processingBreakersLimit: this.currentSeason.grading_data.processingBreakers.rejection,
            processingBreakersReject: grade.calculated_grade.processingBreakers.reject,
            processingBreakersTare: grade.calculated_grade.processingBreakers.cullTear,

            otherWeight: grade.calculated_grade.otherDefects.lbs,
            otherPct: grade.calculated_grade.otherDefects.percentage,
            otherLimit: this.currentSeason.grading_data.otherDefects.rejection,
            otherReject: grade.calculated_grade.otherDefects.reject,
            otherTare: grade.calculated_grade.otherDefects.cullTear,

            limitedUseWeight: grade.calculated_grade.limitedUse.lbs,
            limitedUsePct: grade.calculated_grade.limitedUse.percentage,
            limitedUseLimit: this.currentSeason.grading_data.limitedUse.rejection,
            limitedUseReject: grade.calculated_grade.limitedUse.reject,
            limitedUseTare: grade.calculated_grade.limitedUse.cullTear,

            totalCullTare: totalCullTare,
            loadStatus: 'ACCEPTED',
            incomingWeight: grade.delivery.weight_in,
            outgoingWeight: grade.delivery.weight_out,
            netWeightDelivered: netWeightDelivered,
            tarePenalty: tarePenalty,
            netPayableWeight: netPayableWeight,
            finalLoadStatus: "ACCEPTED",
        }

        sheet = [...sheet, row];
      }

      const csv = Papa.unparse(sheet);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      this.CSVDownloadLink = url;
    },
    getFilteredGrades: function(){

      this.page = 1;

      let peramsObj = {
        regrade_only: this.onlyRegrade,
        fix_only: this.onlyFixed,
        page: this.page,
        page_quantity: this.page_quantity
      }

      if(this.selectedGrower.id){
        peramsObj.grower_id = this.selectedGrower.id;
      }

      if(this.startDate){
        peramsObj.date_start = this.startDate;
      }

      if(this.endDate){
        peramsObj.date_end = this.endDate;
      }

      axios.get('/grade', {
        params: peramsObj
      }).then((response) => {
        this.grades = response.data.grades;
      });
    },
    clearFilters: function(){
      this.onlyRegrade = false;
      this.onlyFixed = false;
      this.selectedGrower = {};
      this.startMenu = false;
      this.startDate = null;
      this.endMenu = false;
      this.endDate = null;
    },
    selectGrade: function(gradeId){
      this.gradeNumber = gradeId;
      this.getCertificate();
    },
    getCertificate: function(){
      axios.get(`/grade/${this.gradeNumber}`).then((response) => {
        this.gradeData = response.data.grade;
        this.deliveryData = response.data.delivery;

        this.varieties.forEach((variety) => {
          if(variety.id == this.gradeData.variety_id){
            this.selectedVariety = variety;
          }
        });

        this.selectedGrowerFields = this.currentSeason.grower_fields.filter((field) => {
          if(field.grower_id == this.deliveryData.grower_id){
            return field;
          }
        });

        this.selectedGrowerField = this.selectedGrowerFields.filter((field) => {
          if(field.id == this.deliveryData.field_id){
            return field;
          }
        })[0];

        this.selectedSearchGrower = this.growers.filter((grower) => {
          if(grower.id == this.deliveryData.grower_id){
            return grower;
          }
        })[0];

        this.selectedSearchGrowerDisplayText = this.selectedSearchGrower['display-text'];

        // console.log('selectedSearchGrower', this.selectedSearchGrower);

        // console.log(this.selectedGrowerFields);
      });
    },
    updateGrade: function(){
      axios.patch(`/delivery/${this.deliveryData.id}/fix`, {
        weight_in: this.deliveryData.weight_in,
        weight_out: this.deliveryData.weight_out,
        trailer_number: this.deliveryData.trailer_number,
        variety_id: this.selectedVariety.id,
        field_id: this.selectedGrowerField.id,
        refractometer: this.gradeData.refractometer,
        fix_reason: this.gradeData.fix_reason
      }).then(() => {
        this.gradeNumber = "";
        this.getCertificate();
        this.certNotification = true;
      });
    },
    printCertificate: function(deliveryId, gradeId){
      this.selectedDeliveryId = deliveryId;
      this.selectedDeliveryGradeId = gradeId;
      this.viewCertificate = true;
    },
    closeCert: function(){
      this.selectedDeliveryId = null;
      this.viewCertificate = false;
    },
    loadMoreCertificates: function(){

      this.page  = this.page + 1;

      let peramsObj = {
        regrade_only: this.onlyRegrade,
        fix_only: this.onlyFixed,
        page: this.page,
        page_quantity: this.page_quantity
      }

      if(this.selectedGrower.id){
        peramsObj.grower_id = this.selectedGrower.id;
      }

      if(this.startDate){
        peramsObj.date_start = this.startDate;
      }

      if(this.endDate){
        peramsObj.date_end = this.endDate;
      }

      axios.get('/grade', {
        params: peramsObj
      }).then((response) => {
        this.grades = [...this.grades, ...response.data.grades];
      });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function() {

    axios.get('/grade', {
      params: {
        regrade_only: this.onlyRegrade,
        fix_only: this.onlyFixed,
        page: this.page,
        page_quantity: this.page_quantity
      }
    }).then((response) => {
      this.grades = response.data.grades;
    });

    // axios.get('/variety').then((response) => {
    //   this.varieties = response.data.varieties;
    // });

    axios.get('/season', {
      params: {
        active: true,
      }
    }).then((response) => {
      this.currentSeason = response.data.seasons[0];

      this.varieties = this.currentSeason.varieties;

      this.growers = response.data.seasons[0].growers.map((grower) => {
        grower["display-text"] = `${grower.code} - ${grower.name}`; //  - ${grower.user.name}
        return grower;
      }).sort((a, b) => {
        return a.code - b.code;
      });

      // console.log(this.currentSeason);
    });
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
