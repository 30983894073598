<template>
  <v-app>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="authenticated" v-bind:style="{ 'color': 'white' }">{{currentUserGreeting}}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Highbury Canco
          </v-list-item-title>
          <v-list-item-subtitle>
            Process Management Portal
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
        v-if="authenticated"
      >
        <v-list-item
          v-for="item in allowedMenu"
          :key="item.title"
          link
          v-on:click="redirect(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item key="logout">
          <v-btn v-if="authenticated" v-on:click="logout()" block color="primary">
            Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app></v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: function() {
    return {
      menu: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/', name: 'home', allowed_roles: [
          'grower',
          'super_user',
          'admin',
          'check_in',
          'scale',
          'ag_dept',
          'harvest',
          'storage',
          'finance'
        ]},
        { title: 'Reporting', icon: 'mdi-chart-bar', route: '/reporting', name: 'reporting', allowed_roles: [
          'grower',
          'super_user',
          'admin',
          'check_in',
          'scale',
          'ag_dept',
          'harvest',
          'storage',
          'finance'
        ]},
        { title: 'Season', icon: 'mdi-tree', route: '/season', name: 'season', allowed_roles: [
        'super_user',
          'admin',
          'ag_dept',
        ]},
        { title: 'Permanent Schedule', icon: 'mdi-truck-outline', route: '/default_deliveries', name: 'default_deliveries', allowed_roles: [
        'super_user',
          'admin',
          'check_in',
          'ag_dept',
        ]},
        { title: 'Deliveries', icon: 'mdi-truck-outline', route: '/deliveries', name: 'deliveries', allowed_roles: [
        'super_user',
          'admin',
          'check_in',
          'ag_dept',
        ]},
        { title: 'Receiving', icon: 'mdi-train-car-centerbeam-full', route: '/receiving', name: 'receiving', allowed_roles: [
        'super_user',
          'admin',
          'check_in',
          'ag_dept',
        ]},
        { title: 'Yard Management', icon: 'mdi-call-split', route: '/yard_management', name: 'yard_management', allowed_roles: [
        'super_user',
          'admin',
          'check_in',
          'ag_dept',
        ]},
        { title: 'Grading', icon: 'mdi-clipboard-check-outline', route: '/grading', name: 'grading', allowed_roles: [
        'super_user',
          'admin',
          'grade',
          'ag_dept',
        ]},
        { title: 'Scaling', icon: 'mdi-scale', route: '/scaling', name: 'scaling', allowed_roles: [
        'super_user',
          'admin',
          'scale',
          'ag_dept',
        ]},
        { title: 'Certificate Fix', icon: 'mdi-certificate', route: '/certificate_fix', name: 'certificate_fix', allowed_roles: [
        'super_user',
          'admin',
          'scale',
          'ag_dept',
        ]},
        { title: 'Waste Management', icon: 'mdi-delete-empty', route: '/waste_management', name: 'waste_management', allowed_roles: [
        'super_user',
          'admin',
          'scale',
          'ag_dept',
        ]},
        { title: 'Accounting', icon: 'mdi-bank', route: '/accounting', name: 'accounting', allowed_roles: [
        'super_user',
          'admin',
          'finance'
        ]},
        { title: 'Grower Management', icon: 'mdi-nature-people', route: '/grower_management', name: 'grower_management', allowed_roles: [
        'super_user',
          'admin',
        ]},
        { title: 'User Management', icon: 'mdi-account-group', route: '/user_management', name: 'user_management', allowed_roles: [
        'super_user',
          'admin',
        ]},
        { title: 'Transfer Load', icon: 'mdi-swap-horizontal', route: '/transfer_load', name: 'transfer_load', allowed_roles: [
        'super_user',
          'admin',
          'ag_dept',
        ]},
        { title: 'System Settings', icon: 'mdi-cog', route: '/settings', name: 'settings', allowed_roles: [
        'super_user',
          'admin',
        ]},
      ],
      allowedMenu: [],
      right: null,
      drawer: true,
      userRoles: [],
    }
  },
  methods: {
    loadData: function(){
      const currentUser = this.$store.getters['AuthStore/user'];

      // console.log(currentUser);

      if(currentUser){
        this.userRoles = currentUser.roles.map((role) => {
          return role.name;
        });

        this.allowedMenu = this.menu.filter((item) => {
          if(this.allowedRole(item.allowed_roles)){
            return item;
          }
        });
      }
    },
    redirect: function(item) {
      if(item.name != this.$route.name){
        this.$router.push(item.route);
      }
    },
    logout: async function() {
      await this.$store.dispatch('AuthStore/logout');
      this.$router.push('/login');
    },
    allowedRole: function(arr){
      let allowed = false;
      for (const userRole of this.userRoles) {
        if(arr.includes(userRole)){
          allowed = true;
        }
      }
      return allowed;
    }
  },
  computed: {
    authenticated: function() {
      this.loadData();
      return this.$store.getters['AuthStore/authenticated'];
    },
    currentUserGreeting: function() {
      return `Welcome, ${this.$store.getters['AuthStore/user'].name}!`;
    },
    currentUser: function(){
      return this.$store.getters['AuthStore/user'];
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  mounted: function(){
    this.loadData();
  },
  created: () => {

  },
  destroyed: () => {

  }
};
</script>
