<template>
  <div class="user_management">
    <v-row>
      <v-col cols="12">

        <h1>Grower Management</h1>

        <!-- <v-select
          v-model="selectedGrower"
          :items="growers"
          item-text="name"
          label="Select Grower"
          return-object
          solo
        ></v-select> -->

        <v-row>
          <v-col>
            <v-btn color="primary" v-on:click="newGrowerOverlay = true">New Grower</v-btn>
          </v-col>
        </v-row>

        <!-- List of searched Growers -->
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Code
                    </th>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Load Size (tons)
                    </th>
                    <th class="text-left">
                      Street Address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="grower in growers"
                    :key="grower.id"
                    v-on:click="selectGrower(grower)"
                  >
                    <td>{{ grower.code }}</td>
                    <td>{{ grower.name }}</td>
                    <td>{{ grower.load_size }}</td>
                    <td>{{ grower.address ? grower.address.street_address : '' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- Update Grower -->
        <v-overlay
          :absolute="true"
          :value="updateGrowerOverlay"
          class="d-flex align-start"
        >
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card light>
                  <v-card-title>Manage Grower</v-card-title>
                  <v-card-subtitle>You can now manage this specific grower.</v-card-subtitle>
                  <v-card-text>
                    <GrowerManage :grower="selectedGrower"/>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="error" text @click="updateGrowerOverlay = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-overlay>

        <!-- New Grower -->
        <v-overlay
          :absolute="true"
          :value="newGrowerOverlay"
          class="d-flex align-start"
        >
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card light>
                  <v-card-title>New Grower</v-card-title>
                  <v-card-subtitle>Add a new grower to the system.</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="newGrower.name"
                          label="New grower name"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="newGrower.code"
                          label="New grower code"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="newGrower.loadSize"
                          label="New grower load size"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="newGrower.address.streetAddress"
                          label="Street Address"
                          outlined
                        ></v-text-field>
                        <v-select
                          v-model="newGrower.address.country"
                          :items="countries"
                          item-text="name"
                          label="Select Country"
                          return-object
                          outlined
                        ></v-select>
                        <v-select
                          v-if="Object.keys(newGrower.address.country).length > 0"
                          v-model="newGrower.address.province"
                          :items="newGrower.address.country.provinces"
                          item-text="name"
                          label="Select Province"
                          return-object
                          outlined
                        ></v-select>
                        <v-select
                          v-if="Object.keys(newGrower.address.province).length > 0"
                          v-model="newGrower.address.city"
                          :items="newGrower.address.province.cities"
                          item-text="name"
                          label="Select City"
                          return-object
                          outlined
                        ></v-select>
                        <v-select
                          v-if="Object.keys(newGrower.address.province).length > 0"
                          v-model="newGrower.address.region"
                          :items="newGrower.address.province.regions"
                          item-text="name"
                          label="Select Region"
                          return-object
                          outlined
                        ></v-select>
                        <v-select
                          v-if="Object.keys(newGrower.address.province).length > 0"
                          v-model="newGrower.address.town"
                          :items="newGrower.address.province.townships"
                          item-text="name"
                          label="Select Town"
                          return-object
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="error" text @click="newGrowerOverlay = false">
                      Close
                    </v-btn>
                    <v-btn color="primary" text @click="createNewGrower()">
                      Create new user
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-overlay>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import axios from '../service/axios.config';
import GrowerManage from '../components/GrowerManage.vue'

export default {
  name: 'UserManagementView',
  components: {
    GrowerManage
  },
  data: function() {
    return {
      growers: [],
      countries: [],
      provinces: [],
      cities: [],
      regions: [],
      towns: [],
      selectedCountry: {},
      selectedProvince: {},
      selectedCity: {},
      selectedTown: {},
      selectedRegion: {},
      selectedGrower: {},
      updateGrowerOverlay: false,
      newGrowerOverlay: false,
      newGrower: {
        name: '',
        code: '',
        loadSize: '',
        address: {
          streetAddress: '',
          country: {},
          province: {},
          city: {},
          region: {},
          town: {}
        }
      },
      outsideProcessor: false,
    }
  },
  methods: {
    selectGrower(grower){
      this.updateGrowerOverlay = true;
      this.selectedGrower = grower;
    },
    createNewGrower(){
      axios.post('/grower', {
        name: this.newGrower.name,
        code: this.newGrower.code,
        loadSize: this.newGrower.loadSize,
        address: this.newGrower.address,
      }).then(() => {
        this.newGrower = {
          name: '',
          code: '',
          loadSize: '',
          address: {
            streetAddress: '',
            country: {},
            province: {},
            city: {},
            region: {},
            town: {}
          }
        }

        this.newGrowerOverlay = false;

        this.loadData();
      });
    },
    loadData(){
      axios.get('/grower').then((response) => {
        this.growers = response.data.growers.sort((a, b) => {
          const nameA = a.code;
          const nameB = b.code;

          if (nameA < nameB) {
            return -1; // a should come before b
          }
          if (nameA > nameB) {
            return 1; // b should come before a
          }
          return 0; // names are equal
        });
        this.selectedGrower = response.data.growers[0];
      });

      axios.get('/country').then((response) => {
        this.countries = response.data.countries;
      });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function() {
    this.loadData();
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
