<template>
  <div class="dashboard">
    <h1>Grower Dashboard</h1>
  </div>
</template>

<script>

export default {
  name: 'GrowerDashboardView',
  components: {
  },
  data: function() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: () => {

  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
