<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <v-rom>
          <v-col>
            <v-select
              v-model="selectedCountry"
              :items="countries"
              item-text="name"
              return-object
              label="Select Country"
              outlined
              v-on:change="selectCountry()"
            ></v-select>
          </v-col>
          <v-col v-if="Object.keys(selectedCountry).length != 0">
            <v-select
              v-model="selectedProvince"
              :items="provinces"
              item-text="name"
              return-object
              label="Select Province"
              outlined
              v-on:change="selectProvince()"
            ></v-select>
          </v-col>
        </v-rom>

        <v-row v-if="Object.keys(selectedProvince).length != 0">
          <v-col cols="12">
            <v-text-field
              v-model="newCity"
              label="New city name"
              solo
            ></v-text-field>
            <v-btn v-on:click="store()" color="primary" block>Add</v-btn>
          </v-col>
        </v-row>


        <v-row v-if="Object.keys(selectedProvince).length != 0">
          <v-col cols="12">
            <v-list>
              <template v-for="(city, index) in cities">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="city.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn v-on:click="destroy(city)" color="error">Delete</v-btn>
                  </v-list-item-action>
                </v-list-item>
                  <v-divider
                    v-if="index < city.length - 1"
                    :key="index"
                  ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'CitySettings',
    components: {},
    data: function() {
      return {
        countries: [],
        selectedCountry: {},
        provinces: [],
        selectedProvince: {},
        cities: [],
        newCity: ''
      }
    },
    methods: {
      destroy: function(city){
        axios.delete(`/city/${city.id}`).then((response) => {
          if(response.status == 200){
            this.cities = this.cities.filter((loopedCity) => {
              if(loopedCity.id != city.id){
                return loopedCity;
              }
            });
          }
        });
      },
      store: function(){
        axios.post(`/city`, {
          name: this.newCity,
          provinceId: this.selectedProvince.id
        }).then((response) => {
          this.cities = [...this.cities, response.data.city];
        });
        this.newCity = '';
      },
      selectCountry: function(){
        axios.get('/province', {
          params: {
            countryId: this.selectedCountry.id
          }
        }).then((response) => {
          this.provinces = response.data.provinces;
        });
      },
      selectProvince: function(){
        axios.get('/city', {
          params: {
            countryId: this.selectedCountry.id,
            provinceId: this.selectedProvince.id
          }
        }).then((response) => {
          this.cities = response.data.cities;
        });
      }
    },
    watch: {

    },
    mounted: function() {
      axios.get('/country').then((response) => {
        this.countries = response.data.countries;
      });
    },
    created: function() {

    },
    destroyed: function() {

    }
  }
</script>
