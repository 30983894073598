<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card width="100%">
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="6">
                <v-text-field
                  label="Name"
                  outlined
                  v-model="updatedUserInfo.name"
                ></v-text-field>
                <v-text-field
                  label="Email"
                  outlined
                  v-model="updatedUserInfo.email"
                ></v-text-field>
                <v-text-field
                  label="Phone Number"
                  outlined
                  v-model="updatedUserInfo.phone"
                ></v-text-field>
                <v-checkbox
                  v-if="is_su && user.email"
                  v-model="otpRequired"
                  label="Require OTP to login."
                ></v-checkbox>
                <v-checkbox
                  v-if="user.email"
                  v-model="updatedUserInfo.inventory_notification"
                  label="Receive Inventory Notifications?"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="updatedRoles"
                  :items="roles"
                  item-text="name"
                  attach
                  chips
                  label="Select Roles for this user."
                  return-object
                  multiple
                  outlined
                ></v-select>
                <v-select
                  v-if="isGrower"
                  v-model="updatedGrower"
                  :items="growers"
                  item-text="name"
                  attach
                  return-object
                  label="Select Grower this user has access to."
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" v-on:click="updateInfo()">Update Info</v-btn>
          </v-card-actions>
        </v-card>

        <v-card width="100%">
          <v-card-text>
            <v-text-field
              label="New Password"
              outlined
              type="password"
              v-model="newPassword"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" v-on:click="updatePassword()">Update Password</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'UserManage',
    components: {},
    props: {
      user: Object
    },
    data: function() {
      return {
        updatedUserInfo: {
          name: null,
          email: null,
          phone: null,
          inventory_notification: false
        },
        roles: [],
        growers: [],
        updatedRoles: [],
        updatedGrower: null,
        newPassword: '',
        countries: [],
        otpRequired: true,
        is_su: false,
        isGrower: false,
      }
    },
    computed: {
      authenticated: function() {
        return this.$store.getters['AuthStore/authenticated'];
      },
      authenticatedUser: function() {
        return this.$store.getters['AuthStore/user'];
      }
    },
    methods: {
      updateInfo: function(){
        axios.patch(`/user/${this.user.id}`, {
          name: this.updatedUserInfo.name,
          email: this.updatedUserInfo.email == "" ? null : this.updatedUserInfo.email,
          phone: this.updatedUserInfo.phone,
          roles: this.updatedRoles,
          grower_id: (this.updatedGrower ? this.updatedGrower.id : null),
          require_otp: this.otpRequired,
          inventory_notification: this.updatedUserInfo.inventory_notification,
        }).then(() => {
          this.updateEvent();
        });
      },
      updatePassword: function(){
        axios.post(`/user/${this.user.id}/new_password`, {
          password: this.newPassword
        }).then(() => {
          this.updateEvent();
        });
      },
      updateEvent() {
        this.$emit('update-event', true);
      }
    },
    watch: {

    },
    mounted: function (){

      this.authenticatedUser.roles.forEach((userRole) => {
        if(userRole.name == "super_user"){
          this.is_su = true;
        }
      });

      this.updatedUserInfo.name = this.user.name;
      this.updatedUserInfo.email = this.user.email;
      this.updatedUserInfo.phone = this.user.phone;
      this.updatedUserInfo.inventory_notification = this.user.inventory_notification;
      this.otpRequired = this.user.require_otp;

      axios.get('/grower').then((response) => {
        this.growers = response.data.growers;

        this.updatedGrower = this.growers.filter((grower) => {
          if(grower.id == this.user.grower_id){
            return grower;
          }
        })[0];
      });

      axios.get('/role').then((response) => {
        this.roles = response.data.roles.filter((role) => {
          if(role.name == 'super_user'){
            if(this.is_su){
              return role;
            }
          } else {
            return role;
          }
        });
        this.updatedRoles = this.user.roles;
        this.updatedRoles.forEach((role) => {
          if(role.name == "grower"){
            this.isGrower = true;
          }
        });
      });
      
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

