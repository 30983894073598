<template>
  <div class="season">
    <v-row class="text-center">
      <v-col cols="12">
        <v-card>
          <v-card-title>Seasons</v-card-title>
          <v-simple-table fixed-header height="200px">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Tomato Price</th>
                <th>Start</th>
                <th>End</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="season in seasons" :key="season.id">
                <td class="text-left">{{ season.id }}</td>
                <td class="text-left">{{ season.type_id }}</td>
                <td class="text-left">{{ season.tomato_price / 100 }}</td>
                <td class="text-left">{{ season.start }}</td>
                <td class="text-left">
                  {{ season.end !== null ? season.end : "--" }}
                </td>
                <td class="text-left">
                  <v-chip v-if="!season.active" color="red">Ended</v-chip>
                  <v-chip v-if="season.active" color="green">Active</v-chip>
                </td>
                <td class="text-left">
                  <v-btn
                    v-if="season.active"
                    color="error"
                    v-on:click="endSeason(season.id)"
                    >End Season</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-card-actions>
            <v-btn
              v-if="!isActiveSeason"
              color="primary"
              v-on:click="newSeason()"
              >New Season</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col cols="6">
        <v-card>
          <v-card-title>Growers</v-card-title>
          <v-card-subtitle class="text-left"
            >Select growers to participate in the season.</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-list lines="one">
                  <!-- [{{availableGrowers.length}}/{{growers.length}}] -->
                  <v-list-item-subtitle>Growers to add</v-list-item-subtitle>
                  <v-card height="400" class="overflow-y-auto">
                    <v-list-item
                      v-for="grower in availableGrowers"
                      :key="grower.id"
                      v-on:click="addGrowerToSeason(grower)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          grower.code + ":" + grower.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list lines="one">
                  <!-- [{{activeSeason.growers.length}}/{{growers.length}}] -->
                  <v-list-item-subtitle>Growers added</v-list-item-subtitle>
                  <v-card height="400" class="overflow-y-auto">
                    <v-list-item
                      v-for="grower in activeSeason.growers"
                      :key="grower.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          grower.code + ":" + grower.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-list>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-select
                      v-model="selectedGrowerForSeasonalData"
                      v-on:change="selectGrowerSeasonalData()"
                      :items="activeSeason.growers"
                      item-text="code"
                      label="Select Grower"
                      return-object
                      solo
                    ></v-select>
                    <div v-if="selectedGrowerSeasonalData">
                      <v-text-field
                        label="Contracted Tons"
                        outlined
                        dense
                        suffix="tons"
                        v-model="selectedGrowerSeasonalData.contracted_tons"
                      ></v-text-field>
                      <v-text-field
                        label="Estimated Tons"
                        outlined
                        dense
                        suffix="tons"
                        v-model="
                          selectedGrowerSeasonalData.estimated_tons_per_acre
                        "
                      ></v-text-field>
                    </div>

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        v-on:click="saveSeasonalGrowerData()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card-text>
                </v-card>

                <v-card>
                  <v-card-text>
                    <v-text-field
                      v-model="newField.name"
                      label="Field name"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="newField.acres"
                      label="Field acres"
                      outlined
                      dense
                    ></v-text-field>
                    <v-card-actions>
                      <v-btn color="primary" v-on:click="addFields()"
                        >Add grower field to season</v-btn
                      >
                    </v-card-actions>
                  </v-card-text>

                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Field Name</th>
                          <th class="text-left">Acres</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="field in selectedGrowerSeasonalData.fields"
                          :key="field.id"
                          @click="selectField(field)"
                        >
                          <td class="text-left">{{ field.name }}</td>
                          <td class="text-left">{{ field.acres }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row> -->

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>Grading Info</v-card-title>
          <v-card-subtitle class="text-left"
            >Set grade defect thresholds.</v-card-subtitle
          >
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header> MOT </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-text-field
                      label="Rejection"
                      outlined
                      dense
                      suffix="%"
                      v-model="gradingData.mot.rejection"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[0].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[0].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.mot.compounding[0].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[1].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[1].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.mot.compounding[1].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[2].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[2].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.mot.compounding[2].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[3].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[3].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.mot.compounding[3].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[4].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.mot.compounding[4].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.mot.compounding[4].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Grass Green
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-text-field
                      label="Rejection"
                      outlined
                      dense
                      suffix="%"
                      v-model="gradingData.grassGreen.rejection"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[0].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[0].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.grassGreen.compounding[0].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[1].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[1].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.grassGreen.compounding[1].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[2].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[2].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.grassGreen.compounding[2].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[3].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[3].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.grassGreen.compounding[3].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[4].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.grassGreen.compounding[4].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.grassGreen.compounding[4].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Processing Green
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-text-field
                      label="Rejection"
                      outlined
                      dense
                      suffix="%"
                      v-model="gradingData.processingGreen.rejection"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingGreen.compounding[0].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.processingGreen.compounding[0].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingGreen.compounding[0].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingGreen.compounding[1].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.processingGreen.compounding[1].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingGreen.compounding[1].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingGreen.compounding[2].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.processingGreen.compounding[2].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingGreen.compounding[2].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingGreen.compounding[3].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.processingGreen.compounding[3].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingGreen.compounding[3].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingGreen.compounding[4].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.processingGreen.compounding[4].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingGreen.compounding[4].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Processing Breakers
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-text-field
                      label="Rejection"
                      outlined
                      dense
                      suffix="%"
                      v-model="gradingData.processingBreakers.rejection"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[0].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[0].to
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingBreakers.compounding[0].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[1].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[1].to
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingBreakers.compounding[1].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[2].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[2].to
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingBreakers.compounding[2].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[3].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[3].to
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingBreakers.compounding[3].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[4].from
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="
                          gradingData.processingBreakers.compounding[4].to
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="
                          gradingData.processingBreakers.compounding[4].tear
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Other Defects
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-text-field
                      label="Rejection"
                      outlined
                      dense
                      suffix="%"
                      v-model="gradingData.otherDefects.rejection"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[0].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[0].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.otherDefects.compounding[0].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[1].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[1].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.otherDefects.compounding[1].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[2].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[2].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.otherDefects.compounding[2].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[3].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[3].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.otherDefects.compounding[3].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[4].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.otherDefects.compounding[4].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.otherDefects.compounding[4].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Limited use
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-text-field
                      label="Rejection"
                      outlined
                      dense
                      suffix="%"
                      v-model="gradingData.limitedUse.rejection"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[0].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[0].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.limitedUse.compounding[0].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[1].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[1].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.limitedUse.compounding[1].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[2].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[2].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.limitedUse.compounding[2].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[3].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[3].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.limitedUse.compounding[3].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="From"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[4].from"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="To"
                        outlined
                        dense
                        suffix="%"
                        v-model="gradingData.limitedUse.compounding[4].to"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Penalty"
                        outlined
                        dense
                        v-model="gradingData.limitedUse.compounding[4].tear"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="!activeSeason.grading_data_locked"
              color="primary"
              v-on:click="confirmGradingData()"
              >Save grade data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>Varieties</v-card-title>
          <v-card-subtitle class="text-left"
            >Select varieties to be included in the season.</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-list lines="one">
                  <!-- [{{availableGrowers.length}}/{{growers.length}}] -->
                  <v-list-item-subtitle>Varieties to add</v-list-item-subtitle>
                  <v-card height="400" class="overflow-y-auto">
                    <v-list-item
                      v-for="variety in availableVarieties"
                      :key="variety.id"
                      v-on:click="addVarietyToSeason(variety)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          variety.code
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list lines="one">
                  <v-list-item-subtitle>Varieties added</v-list-item-subtitle>
                  <v-card height="400" class="overflow-y-auto">
                    <v-list-item
                      v-for="variety in activeSeason.varieties"
                      :key="variety.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          variety.code
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- New season -->
    <v-overlay
      :absolute="true"
      :value="newSeasonOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-card light>
            <v-card-title>New Season</v-card-title>
            <v-card-subtitle
              >By creating a new season it will deactivate and end the current
              season as well as mark any open deliveries as
              canceled.</v-card-subtitle
            >
            <v-card-text>
              <v-select
                v-model="newSeasonObject.seasonType"
                :items="seasonTypes"
                item-text="name"
                label="Select Season Type"
                return-object
                solo
              ></v-select>
              <v-text-field
                v-model="newSeasonObject.tomatoPrice"
                label="Tomato Price"
                prefix="$"
                outlined
              ></v-text-field>

              <v-menu
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newSeasonObject.start"
                    label="Start of season"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newSeasonObject.start"
                  @input="startMenu = !startMenu"
                ></v-date-picker>
              </v-menu>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" text @click="newSeasonOverlay = false">
                Close
              </v-btn>
              <v-btn color="primary" text @click="saveNewSeason()">
                Save Season
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="confirmGradeDataOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-card light style="width: 500px">
            <v-card-title>Confirm grading data</v-card-title>
            <v-card-actions>
              <v-btn color="primary" text @click="saveGradingData()">
                Confirm
              </v-btn>
              <v-btn
                color="error"
                text
                @click="confirmGradeDataOverlay = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

    <!-- <v-overlay
      :z-index="11"
      :absolute="true"
      :value="editFieldOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-card light style="width: 500px">
            <v-card-title>Edit field acres</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="updateFieldName"
                  label="Field Name"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="updateFieldAcres"
                  label="Field acres"
                  outlined
                  dense
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="updateFieldFcn()">
                  Confirm
                </v-btn>
                <v-btn
                  color="error"
                  text
                  @click="editFieldOverlay = false"
                >
                  Cancel
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay> -->

    <v-snackbar v-model="newSeasonError" timeout="2000">
      A season is still active, end active season.
    </v-snackbar>
  </div>
</template>

<script>
import axios from "../service/axios.config";

export default {
  name: "SeasonView",
  components: {},
  data: function () {
    return {
      allVarieties: [],
      availableVarieties: [],
      seasonVarietyIds: [],
      updateField: null,
      updateFieldAcres: 0,
      updateFieldName: '',
      editFieldOverlay: false,
      isActiveSeason: true, // If an active season exists
      newSeasonError: false,
      confirmGradeDataOverlay: false,
      newField: {
        name: "",
        acres: "",
      },
      selectedGrowerForSeasonalData: {},
      selectedGrowerSeasonalData: {},
      selectedGrowerSeasonalDataTotAcres: 0,
      selectedSeason: {},
      activeSeason: {},
      seasons: [],
      newSeasonOverlay: false,
      seasonTypes: [],
      startMenu: false,
      newSeasonObject: {
        seasonType: {},
        tomatoPrice: 0,
        start: "",
      },
      growers: [],
      availableGrowers: [],
      seasonGrowerIds: [],
      gradingData: {
        mot: {
          rejection: "",
          compounding: [
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
          ],
        },
        grassGreen: {
          rejection: "",
          compounding: [
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
          ],
        },
        processingGreen: {
          rejection: "",
          compounding: [
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
          ],
        },
        processingBreakers: {
          rejection: "",
          compounding: [
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
          ],
        },
        otherDefects: {
          rejection: "",
          compounding: [
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
          ],
        },
        limitedUse: {
          rejection: "",
          compounding: [
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
            { from: 0, to: 0, tear: 0 },
          ],
        },
      },
    };
  },
  methods: {
    loadData(selectedGrower = null) {
      this.editFieldOverlay = false;
      this.isActiveSeason = false;
      this.newSeasonError = false;
      (this.confirmGradeDataOverlay = false),
        (this.newField = {
          name: "",
          acres: "",
        }),
        (this.selectedSeason = {}),
        (this.activeSeason = {});
      (this.seasons = []),
        (this.newSeasonOverlay = false),
        (this.seasonTypes = []),
        (this.startMenu = false),
        (this.endMenu = false),
        (this.newSeasonObject = {
          seasonType: {},
          tomatoPrice: 0,
          start: "",
        }),
        (this.growers = []),
        (this.availableGrowers = []),
        (this.seasonGrowerIds = []),
        (this.gradingData = {
          mot: {
            rejection: "",
            compounding: [
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
            ],
          },
          grassGreen: {
            rejection: "",
            compounding: [
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
            ],
          },
          processingGreen: {
            rejection: "",
            compounding: [
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
            ],
          },
          processingBreakers: {
            rejection: "",
            compounding: [
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
            ],
          },
          otherDefects: {
            rejection: "",
            compounding: [
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
            ],
          },
          limitedUse: {
            rejection: "",
            compounding: [
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
              { from: 0, to: 0, tear: 0 },
            ],
          },
        });

      axios.get("/season").then((response) => {
        this.seasons = response.data.seasons.sort((a, b) => {
          return b.id - a.id;
        });

        this.seasons.forEach((season) => {
          if (season.active) {
            this.isActiveSeason = true;
            this.activeSeason = season;

            this.gradingData = this.activeSeason.grading_data;

            this.seasonGrowerIds = season.growers.map((grower) => {
              return grower.id;
            });

            this.seasonVarietyIds = season.varieties.map((variety) => {
              return variety.id;
            });
          }
        });

        axios.get("/grower").then((response) => {
          this.growers = response.data.growers;
          this.availableGrowers = response.data.growers.filter((grower) => {
            if (!this.seasonGrowerIds.includes(grower.id)) {
              return grower;
            }
          });

          if(selectedGrower){
            this.selectedGrowerForSeasonalData = selectedGrower;
            this.selectGrowerSeasonalData();
          }
        });

        axios.get("/variety").then((res) => {
          this.allVarieties = res.data.varieties;
          this.availableVarieties = this.allVarieties.filter((variety) => {
            if (!this.seasonVarietyIds.includes(variety.id)) {
              return variety;
            }
          });
        });
      });

      axios.get("/season-type").then((response) => {
        this.seasonTypes = response.data.seasonTypes;
      });
    },
    getEventColor(event) {
      if (event.active) {
        return "primary";
      } else {
        return "red";
      }
    },
    newSeason: function () {
      this.newSeasonOverlay = true;
    },
    saveNewSeason: function () {
      axios
        .post("/season", {
          typeId: this.newSeasonObject.seasonType.id,
          tomatoPrice:
            Number(this.newSeasonObject.tomatoPrice).toFixed(2) * 100,
          start: this.newSeasonObject.start,
          end: this.newSeasonObject.end,
        })
        .then(() => {

          this.newSeasonObject = {
            seasonType: {},
            tomatoPrice: 0,
            start: "",
            end: "",
          };

          this.newSeasonOverlay = false;

          this.loadData();
        })
        .catch(() => {
          this.newSeasonError = true;
        });
    },
    endSeason: function (seasonId) {
      axios.delete(`/season/${seasonId}`).then(() => {
        this.loadData();
      });
    },
    addGrowerToSeason: function (grower) {
      axios
        .patch(`/season/${this.activeSeason.id}`, {
          growers: [...this.seasonGrowerIds, grower.id],
        })
        .then(() => {
          this.loadData();
        });
    },
    addVarietyToSeason: function(variety){
      axios
        .patch(`/season/${this.activeSeason.id}`, {
          varieties: [...this.seasonVarietyIds, variety.id],
        })
        .then(() => {
          this.loadData();
        });
    },
    saveGradingData: function () {
      axios
        .patch(`/season/${this.activeSeason.id}/grade`, {
          grading_data: this.gradingData,
        })
        .then(() => {
          this.loadData();
        });
    },
    selectGrowerSeasonalData: function () {
      axios
        .get(`/season/${this.activeSeason.id}/growers/data`)
        .then((response) => {
          this.selectedGrowerSeasonalData =
            response.data.seasonal_grower_data.filter((growerSeasonData) => {
              if (
                growerSeasonData.grower_id ==
                this.selectedGrowerForSeasonalData.id
              ) {
                return growerSeasonData;
              }
            })[0];

          this.selectedGrowerSeasonalDataTotAcres = 0;

          for (const field in this.selectedGrowerSeasonalData.fields) {
            this.selectedGrowerSeasonalDataTotAcres += Number(field.acres);
          }

          if (!this.selectedGrowerSeasonalData) {
            this.selectedGrowerSeasonalData = {
              contracted_tons: 0,
              estimated_tons_per_acre: 0,
            };
          }

          this.selectedGrowerSeasonalData.fields = response.data.seasonal_grower_fields.filter((field) => {
            if(field.grower_id == this.selectedGrowerForSeasonalData.id){
              return field;
            }
          });
        });
    },
    saveSeasonalGrowerData: function () {
      axios
        .patch(
          `/season/${this.activeSeason.id}/grower/${this.selectedGrowerForSeasonalData.id}/data`,
          {
            contracted_tons: this.selectedGrowerSeasonalData.contracted_tons,
            estimated_tons_per_acre:
              this.selectedGrowerSeasonalData.estimated_tons_per_acre,
          }
        )
        .then(() => {
          this.loadData(this.selectedGrowerForSeasonalData);
        });
    },
    addFields: function () {
      axios
        .post(`/grower/${this.selectedGrowerForSeasonalData.id}/field`, {
          name: this.newField.name,
          acres: this.newField.acres,
        })
        .then(() => {
          this.loadData(this.selectedGrowerForSeasonalData);
        });
    },
    confirmGradingData: function () {
      this.confirmGradeDataOverlay = true;
    },
    selectField: function(field){
      this.updateField = field;
      this.updateFieldName = field.name;
      this.updateFieldAcres = field.acres;
      this.editFieldOverlay = true;
    },
    updateFieldFcn: function(){

      axios
        .patch(`/grower/${this.updateField.grower_id}/field/${this.updateField.id}`, {
          acres: this.updateFieldAcres,
          name: this.updateFieldName
        })
        .then(() => {
          this.editFieldOverlay = false;
          this.loadData(this.selectedGrowerForSeasonalData);
        });
    }
  },
  computed: {
    authenticated: function () {
      return this.$store.getters["AuthStore/authenticated"];
    },
  },
  watch: {},
  mounted: function () {
    this.loadData();
  },
  created: () => {},
  destroyed: () => {},
};
</script>
