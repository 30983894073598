<template>
  <div class="home">
    <v-row>
      <v-col>
        <h1>Dashboard</h1>
        <GrowerDashBoard v-if="isGrower"/>
        <DashBoard v-if="!isGrower"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import axios from '../service/axios.config';
import DashBoard from '../components/DashBoard.vue';
import GrowerDashBoard from '@/components/GrowerDashBoard.vue';

export default {
  name: 'HomeView',
  components: {
    DashBoard,
    GrowerDashBoard
  },
  data: function() {
    return {
    }
  },
  methods: {
    testRequest: async () => {

      const fromDate = new Date();
      const toDate = new Date();

      await axios.post('/test', {
        fromDate: fromDate,
        toDate: toDate
      });
      }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    },
    currentUser: function(){
      return this.$store.getters['AuthStore/user'];
    },
    isGrower: function(){
      const user = this.$store.getters['AuthStore/user'];
      let ifGrower = false;

      for(const role of user.roles){
        if(role.name == "grower"){
          ifGrower = true;
        }
      }
      return ifGrower;
    }
  },
  watch: {

  },
  mounted: function () {

  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
