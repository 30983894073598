<template>
  <div class="scaling">
    <h1>Scaling</h1>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-container
            style="height: 40vh"
            class="overflow-y-auto"
          >
            <div><b>Scale In</b></div>
            <v-list two-line>
              <template v-for="(delivery, index) in deliveries">
                <v-list-item :key="delivery.id" v-on:click="selectDelivery(delivery)" v-if="!delivery.weight_in" v-bind:class="(delivery.id == selectedDelivery.id) ? 'selected' : ''">
                  <v-list-item-content>
                    <v-list-item-title>{{ 'Trailer No. ' + delivery.trailer_number }}</v-list-item-title>
                    <v-list-item-subtitle>Certificate: {{delivery.grades.length > 0 ? delivery.grades[0].seasonal_certificate_id : "NULL"}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ 'Grower: ' + delivery.grower.code + ': ' + delivery.grower.name }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ 'Grades: ' + delivery.grades.length }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ delivery.start.toLocaleTimeString() }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < deliveries.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-container>
        </v-card>

        <v-card>
          <v-container
            style="height: 40vh; margin-top: 5px"
            class="overflow-y-auto"
          >
          <div><b>Scale Out</b></div>
            <v-list two-line>
              <template v-for="(delivery, index) in deliveries">
                <v-list-item :key="delivery.id" v-on:click="selectDelivery(delivery)" v-if="delivery.weight_in" v-bind:class="(delivery.id == selectedDelivery.id) ? 'selected' : ''">
                  <v-list-item-content>
                    <v-list-item-title>{{ 'Trailer No. ' + delivery.trailer_number }}</v-list-item-title> 
                    <v-list-item-subtitle>Certificate: {{delivery.grades.length > 0 ? delivery.grades[0].seasonal_certificate_id : "NULL"}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ 'Grower: ' + delivery.grower.code + ': ' + delivery.grower.name }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ 'Grades: ' + delivery.grades.length }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ delivery.start.toLocaleTimeString() }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < deliveries.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>Delivery Viewer</v-card-title>
          <v-card-text v-if="Object.keys(this.selectedDelivery).length > 0">
            <v-row>
              <v-col cols="12" class="text-left">
                <p>ID: {{selectedDelivery.id}}</p>
                <p>Active Certificate: {{selectedDelivery.grades.length > 0 ? selectedDelivery.grades[0].seasonal_certificate_id : "NULL"}}</p>
                <p>Grower: {{selectedDelivery.grower.code}} - {{selectedDelivery.grower.name}}</p>
                <p>Active {{selectedDelivery.active}}</p>
                <p>Arrived: {{selectedDelivery.arrived}}</p>
                <p>Start: {{selectedDelivery.start.toLocaleTimeString()}}</p>
                <p>End: {{selectedDelivery.end.toLocaleTimeString()}}</p>
                <p>Weight In: {{selectedDelivery.weight_in}}</p>
                <p>Tare Weight: {{selectedDelivery.weight_out}}</p>
              </v-col>
            </v-row>
            <v-row v-if="!selectedDelivery.weight_in || !selectedDelivery.weight_out">
              <v-col cols="12">
                <v-text-field
                  v-model="verifyTrailerNumber"
                  label="VERIFY Trailer Number"
                ></v-text-field>
                <v-text-field
                  v-model="verifyGrowerCode"
                  label="VERIFY Grower Code"
                ></v-text-field>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="weightInValue"
                      label="Gross Weight"
                      suffix="lbs"
                      :disabled="(selectedDelivery.weight_in)?true:false"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn v-on:click="openScaleIoOverlay()">Scale IO</v-btn>
                  </v-col>
                </v-row>
                <v-btn v-if="!selectedDelivery.weight_in" color="primary" v-on:click="weighIn()">Weigh In</v-btn>
              </v-col>

              <v-col cols="12" v-if="selectedDelivery.weight_in">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="weightOutValue"
                      label="Tare Weight"
                      suffix="lbs"
                      :disabled="(selectedDelivery.weight_out)?true:false"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn v-on:click="openScaleIoOverlay()">Scale IO</v-btn>
                  </v-col>
                </v-row>
                
                <v-btn color="primary" v-on:click="openCertOverlay()" v-if="!selectedDelivery.weight_out">Print Cert</v-btn>
                <v-btn v-if="!selectedDelivery.weight_out" color="primary" v-on:click="weighOut()">Weigh Out</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="selectedDelivery.weight_in && selectedDelivery.weight_out">
              <v-col cols="3">
                <v-btn color="primary" v-on:click="openCertOverlay()">Print Cert</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn v-if="certPrinted" color="primary" v-on:click="done()">Done</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="certOverlay"
      width="100%"
      
    >
      <CertificateViewer :deliveryId="selectedDelivery.id" />
      <v-btn color="error" v-on:click="closeCert()">Close</v-btn>
    </v-overlay>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="showScaleio"
      width="100%"
      
    >
      <ScaleInput @scaleRead="scaleioRead" @close="() => showScaleio = false"/>
    </v-overlay>

    <v-snackbar
      v-model="snackbarError"
      timeout="2000"
    >
      Invalid trailer or grower code
    </v-snackbar>

  </div>
</template>

<script>

import axios from '../service/axios.config';
import CertificateViewer from '@/components/CertificateViewer.vue';
import ScaleInput from '@/components/ScaleInput.vue';

export default {
  name: 'ScalingView',
  components: {
    CertificateViewer,
    ScaleInput
  },
  data: function() {
    return {
      selectedDelivery: {},
      deliveries: [],
      weightInValue: '',
      weightOutValue: '',
      verifyTrailerNumber: '',
      verifyGrowerCode: '',
      certPrinted: false,
      certOverlay: false,
      snackbarError: false,
      showScaleio: false,
    }
  },
  methods: {
    openCertOverlay: function() {
      this.certPrinted = true;
      this.certOverlay = true;
    },
    closeCert: function() {
      this.certOverlay = false;
    },
    openScaleIoOverlay: function() {
      this.showScaleio = true;
    },
    scaleioRead: function(data) {
      this.showScaleio = false;
      console.log(data);

      if(!this.selectedDelivery.weight_in){
        this.weightInValue = data.weight;
      } else if (this.selectedDelivery.weight_in && !this.selectedDelivery.weight_out){
        this.weightOutValue = data.weight;
      }
    },
    loadData: function(activeDeliveryId = null){

      this.selectedDelivery = {};
      this.deliveries = [];
      this.weightInValue = '';
      this.weightOutValue = '';
      this.verifyTrailerNumber = '';
      this.verifyGrowerCode = '';
      this.certPrinted = false;
      this.certOverlay = false;

      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      axios.get('/delivery', {
        params: {
          sentToScale: true,
          scaled: false
        }
      }).then((response) => {
        this.deliveries = response.data.deliveries.filter((el) => {
          if(el.grades.length > 0){
            el.start = new Date(el.start  + ' UTC');
            el.end = new Date(el.end  + ' UTC');
            return el;
          }
        }).sort((el1, el2) => {
          return el1.start - el2.start;
        });

        if(activeDeliveryId){
          this.selectDelivery(this.deliveries.find((delivery) => {
            return delivery.id == activeDeliveryId;
          }));
        } else {
          this.selectDelivery(this.deliveries[0]);
        }
      });
    },
    selectDelivery: function(delivery){
      this.weightInValue = delivery.weight_in;
      this.weightOutValue = delivery.weight_out;
      this.selectedDelivery = delivery;
    },
    weighIn(){
      if(this.verifyTrailerNumber == this.selectedDelivery.trailer_number && this.verifyGrowerCode == this.selectedDelivery.grower.code){
        let updateDelivery = this.selectedDelivery;
        updateDelivery.weight_in = this.weightInValue;

        axios.patch(`/delivery/${updateDelivery.id}`, updateDelivery).then(() => {
        });

        this.verifyTrailerNumber = '';
        this.verifyGrowerCode = '';
      } else {
        this.verifyTrailerNumber = '';
        this.verifyGrowerCode = '';
        this.weightInValue = '';
        this.weightOutValue = '';
        this.snackbarError=true;
      }
    },
    weighOut(){
      if(this.verifyTrailerNumber == this.selectedDelivery.trailer_number && this.verifyGrowerCode == this.selectedDelivery.grower.code){
        let updateDelivery = this.selectedDelivery;
        updateDelivery.weight_out = this.weightOutValue;

        axios.patch(`/delivery/${updateDelivery.id}`, updateDelivery).then(() => {
          this.selectedDelivery = this.deliveries[0] || {};

          this.loadData(updateDelivery.id);
        });

        this.verifyTrailerNumber = '';
        this.verifyGrowerCode = '';
      } else {
        this.verifyTrailerNumber = '';
        this.verifyGrowerCode = '';
        this.weightInValue = '';
        this.weightOutValue = '';
        this.snackbarError=true;
      }
      
    },
    done(){
      let updateDelivery = this.selectedDelivery;
      updateDelivery.scaled = true;

      axios.patch(`/delivery/${updateDelivery.id}`, updateDelivery).then(() => {
          this.loadData();
        });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function(){
    this.loadData();
    
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>

<style lang="scss" scoped>
  .selected {
    background-color: Gainsboro;
  }
</style>
