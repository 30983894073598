<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Tomato Delivered Summary
          </v-col>
          <v-col align-self="end">
            <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle>
        <v-row>
          <v-col>
            <v-btn
              v-on:click="generateExport()"
            >
              Generate
            </v-btn>
            <!-- <v-btn
              v-on:click="test"
            >
              test
            </v-btn> -->

            <a v-if="exportDownloadLink" :href="exportDownloadLink" target="_blank" download="tomato_delivered_summary_export.csv">Download Export</a>
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Select start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Select end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-checkbox v-model="includeTransfer" label="Include transfer loads"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" v-on:click="search()">Search</v-btn>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <v-data-table
              :headers="generateTableData().headers"
              :items="generateTableData().items"
              disable-pagination
              hide-default-footer
              fixed-header
              height="500px"
            ></v-data-table>

            <br>

            <v-data-table
              :headers="generateTableData().headers"
              :items="generateTableData().totals"
              disable-pagination
              hide-default-footer
              fixed-header
            ></v-data-table>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import axios from '../../service/axios.config';
  import Papa from 'papaparse';
  import numeral from 'numeral';
  import decimalShiftRound from '@/util/decimalShiftRound';

  export default {
    name: 'TomatoDeliveredSummary',
    components: {},
    data: function() {
      return {
        growers: [],
        startDate: null,
        startMenu: false,
        endDate: null,
        endMenu: false,
        includeTransfers: false,
        deliveries: [],
        season: {},
        selectedGrower: {},
        loading: false,
        includeTransfer: false,
        exportDownloadLink: null,
        growerDeliveries: {},
        growerDeliveriesArr: [],
        growerDeliveriesTotals: {},
        grossDelivered: 0,
        netDelivered: 0,
        value: 0,
        totalTotals: {
          agtron: 0,
          refractometer: 0,
          mot: 0,
          grGrn: 0,
          prGrn: 0,
          PrBrk: 0,
          otherD: 0,
          LimUse: 0,
          clean: 0,
          cullTare: 0,
          grossDeliveredLBS: 0,
          netDeliveredLBS: 0,
          loadValue: 0,
        }
      }
    },
    methods: {
      search: function(){
        this.loadData();
      },
      test: function(){
        console.log(this.generateTableData());
      },
      loadData: async function(){
        this.growerDeliveries = {};
        this.growerDeliveriesArr = [];
        this.growerDeliveriesTotals = {};

        this.loading = true;
        this.exportDownloadLink = null;
        this.deliveries = [];
        this.growerDeliveriesArr = [];

        let seasonRes = await axios.get("/season", {
          params: {
            active: true,
          },
        });

        this.season = seasonRes.data.seasons[0];

        this.growers = seasonRes.data.seasons[0].growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`;
          return grower;
        }).sort((a, b) => {
          return Number(a.code) - Number(b.code);
        });

        for (const grower of this.growers) {
          this.growerDeliveries[grower.id] = {
            grower: grower,
            deliveries: []
          };
        }

        this.selectedGrower = this.growers[0];

        // Format date string
        let [yearS, monthS, dayS] = this.startDate.split('-');
        let queryStartDate = new Date(yearS, monthS-1, dayS, 0, 0, 0, 0);

        let [yearE, monthE, dayE] = this.endDate.split('-');
        let queryEndDate = new Date(yearE, monthE-1, dayE, 0, 0, 0, 0);
          
        let Difference_In_Days = (queryEndDate.getTime() - queryStartDate.getTime()) / (1000 * 3600 * 24);

        axios.get("/delivery", {
          params: {
            scaled: true,
            scale_date: queryStartDate,
            scale_addDays: Difference_In_Days
          },
        }).then((res) => {
          this.deliveries = res.data.deliveries.map((delivery) => {
            delivery.grades[0].clean_lbs = Number(delivery.grades[0].total_sample_weight) - Number(delivery.grades[0].defect_grass_green) - Number(delivery.grades[0].defect_limited_use) - Number(delivery.grades[0].defect_mot) - Number(delivery.grades[0].defect_other) - Number(delivery.grades[0].defect_processing_breakers) - Number(delivery.grades[0].defect_processing_green);
            delivery.grades[0].total_cull = Number(delivery.grades[0].calculated_grade.grassGreen.cullTear) + Number(delivery.grades[0].calculated_grade.limitedUse.cullTear) + Number(delivery.grades[0].calculated_grade.mot.cullTear) + Number(delivery.grades[0].calculated_grade.otherDefects.cullTear) + Number(delivery.grades[0].calculated_grade.processingBreakers.cullTear) + Number(delivery.grades[0].calculated_grade.processingGreen.cullTear);
            delivery.net_delivered = Math.round(((Number(delivery.weight_in) - Number(delivery.weight_out)) - (((Number(delivery.weight_in) - Number(delivery.weight_out)) / 100) * Number(delivery.grades[0].total_cull))));
            delivery.load_value = ((delivery.net_delivered / 2000) * Number(this.season.tomato_price)) / 100;
            delivery.cleanPerc = (Number(delivery.grades[0].clean_lbs) / Number(delivery.grades[0].total_sample_weight)) * 100;
            return delivery;
          });

          for (const delivery of this.deliveries) {
            this.growerDeliveries[delivery.grower_id].deliveries = [...this.growerDeliveries[delivery.grower_id].deliveries, delivery];
          }

          for (const grower of this.growers) {

            const totals = {
                agtron: 0,
                refractometer: 0,
                defect_mot: 0,
                defect_grass_green: 0,
                defect_processing_green: 0,
                defect_processing_breakers: 0,
                defect_other: 0,
                defect_limited_use: 0,
                cleanPerc: 0,
                total_cull: 0,
                gross_weight: 0,
                net_delivered: 0,
                load_value: 0,
              };

            for (const delivery of this.growerDeliveries[grower.id].deliveries) {

              // this.grossDelivered += Number((Number(delivery.weight_in) - Number(delivery.weight_out)));
              // this.netDelivered += Number(delivery.net_delivered);
              // this.value += Number(delivery.load_value);

              totals.agtron += Number(delivery.grades[0].agtron);
              totals.refractometer += Number(delivery.grades[0].refractometer);

              totals.defect_mot += Number(delivery.grades[0].calculated_grade.mot.percentage);
              totals.defect_grass_green += Number(delivery.grades[0].calculated_grade.grassGreen.percentage);
              totals.defect_processing_green += Number(delivery.grades[0].calculated_grade.processingGreen.percentage);
              totals.defect_processing_breakers += Number(delivery.grades[0].calculated_grade.processingBreakers.percentage);
              totals.defect_other += Number(delivery.grades[0].calculated_grade.otherDefects.percentage);
              totals.defect_limited_use += Number(delivery.grades[0].calculated_grade.limitedUse.percentage);

              totals.cleanPerc += Number(decimalShiftRound(delivery.cleanPerc));
              totals.total_cull += Number(delivery.grades[0].total_cull);

              totals.gross_weight += Number((Number(delivery.weight_in) - Number(delivery.weight_out)));
              totals.net_delivered += Number(delivery.net_delivered);
              totals.load_value += Number(delivery.load_value);
            }

            this.growerDeliveriesTotals[grower.id] = totals;
          }

          this.loading = false;
        });
      },
      generateExport: function(){
        const tableData = this.generateTableData();

        this.loading = true;
        let sheet = [];

        for (const tableDataItem of tableData.items) {
          sheet = [...sheet, {
            Grower: tableDataItem["grower"],
            Agtron: tableDataItem["agtron"],
            Refractometer: tableDataItem["refractometer"],

            Mot: tableDataItem["mot"],
            PrGrn: tableDataItem["prGrn"],
            GrGrn: tableDataItem["grGrn"],
            OtherD: tableDataItem["otherD"],
            Clean: tableDataItem["clean"],
            CullTare: tableDataItem["cullTare"],

            grossDeliveredLBS: tableDataItem["grossDeliveredLBS"],
            netDeliveredLBS: tableDataItem["netDeliveredLBS"],

            loadValue: tableDataItem["loadValue"],
          }];
        }

        const csv = Papa.unparse(sheet);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        this.exportDownloadLink = url;

        this.loading = false;
      },
      calculateWeightedAverage: function(values, weights) {
        if (values.length !== weights.length) {
          throw new Error("The length of values and weights arrays must be the same.");
        }

        let sumOfProducts = 0;
        let sumOfWeights = 0;

        for (let i = 0; i < values.length; i++) {
          sumOfProducts += values[i] * weights[i];
          sumOfWeights += weights[i];
        }

        if (sumOfWeights === 0) {
          return sumOfProducts;
        }

        return sumOfProducts / sumOfWeights;
      },
      generateTableData: function(){
        let headers = [{
          text: "Grower",
          value: "grower"
        },{
          text: "Agtron",
          value: "agtron"
        },{
          text: "Refractometer",
          value: "refractometer"
        },{
          text: "Mot",
          value: "mot"
        },{
          text: "GrGrn",
          value: "grGrn"
        },{
          text: "PrGrn",
          value: "prGrn"
        },{
          text: "PrBrk",
          value: "prBrk"
        },{
          text: "OtherD",
          value: "otherD"
        },{
          text: "LimUse",
          value: "limUse"
        },{
          text: "Clean",
          value: "clean"
        },{
          text: "Cull Tare",
          value: "cullTare"
        },{
          text: "Gross Delivered LBS",
          value: "grossDeliveredLBS"
        },{
          text: "Net Delivered LBS",
          value: "netDeliveredLBS"
        },{
          text: "Load Value",
          value: "loadValue"
        },];

        let items = [] // {with headers}
        let weights = [];

        for (const grower of this.growers) {
          const numberOfDeliveries = this.growerDeliveries[grower.id].deliveries.length;
          weights = [...weights, this.growerDeliveries[grower.id].deliveries.length];

          items = [...items, {
            grower: grower.code,
            agtron: numeral(Number(this.growerDeliveriesTotals[grower.id].agtron) / numberOfDeliveries).format('0,0.00'),
            refractometer: numeral(Number(this.growerDeliveriesTotals[grower.id].refractometer) / numberOfDeliveries).format('0,0.00'),

            mot: numeral(Number(this.growerDeliveriesTotals[grower.id].defect_mot) / numberOfDeliveries).format('0,0.00'),
            grGrn: numeral(Number(this.growerDeliveriesTotals[grower.id].defect_grass_green) / numberOfDeliveries).format('0,0.00'),
            prGrn: numeral(Number(this.growerDeliveriesTotals[grower.id].defect_processing_green) / numberOfDeliveries).format('0,0.00'),
            prBrk: numeral(Number(this.growerDeliveriesTotals[grower.id].defect_processing_breakers) / numberOfDeliveries).format('0,0.00'),
            otherD: numeral(Number(this.growerDeliveriesTotals[grower.id].defect_other) / numberOfDeliveries).format('0,0.00'),
            limUse: numeral(Number(this.growerDeliveriesTotals[grower.id].defect_limited_use) / numberOfDeliveries).format('0,0.00'),

            clean: numeral(Number(this.growerDeliveriesTotals[grower.id].cleanPerc) / numberOfDeliveries).format('0,0.00'),
            cullTare: numeral(Number(this.growerDeliveriesTotals[grower.id].total_cull) / numberOfDeliveries).format('0,0.00'),

            // grossDeliveredLBS: numeral(Number(this.growerDeliveriesTotals[grower.id].gross_weight) / numberOfDeliveries).format('0,0.00'),
            // netDeliveredLBS: numeral(Number(this.growerDeliveriesTotals[grower.id].net_delivered) / numberOfDeliveries).format('0,0.00'),
            // loadValue: numeral(Number(this.growerDeliveriesTotals[grower.id].load_value) / numberOfDeliveries).format('0,0.00'),

            grossDeliveredLBS: numeral((this.growerDeliveriesTotals[grower.id].gross_weight).toFixed(2)).format('0,0.00'),
            netDeliveredLBS: numeral((this.growerDeliveriesTotals[grower.id].net_delivered).toFixed(2)).format('0,0.00'),
            loadValue: numeral((this.growerDeliveriesTotals[grower.id].load_value).toFixed(2)).format('0,0.00'),
          }];
        }

        let weightedAveragesFields = {
          agtron: [],
          refractometer: [],
          mot: [],
          grGrn: [],
          prGrn: [],
          prBrk: [],
          otherD: [],
          limUse: [],
          clean: [],
          cullTare: [],
          grossDeliveredLBS: 0,
          netDeliveredLBS: 0,
          loadValue: 0,
        };

        for (const item of items){
          weightedAveragesFields.agtron = [...weightedAveragesFields.agtron, Number(item.agtron)];
          weightedAveragesFields.refractometer = [...weightedAveragesFields.refractometer, Number(item.refractometer)];
          weightedAveragesFields.mot = [...weightedAveragesFields.mot, Number(item.mot)];
          weightedAveragesFields.grGrn = [...weightedAveragesFields.grGrn, Number(item.grGrn)];
          weightedAveragesFields.prGrn = [...weightedAveragesFields.prGrn, Number(item.prGrn)];
          weightedAveragesFields.prBrk = [...weightedAveragesFields.prBrk, Number(item.prBrk)];
          weightedAveragesFields.otherD = [...weightedAveragesFields.otherD, Number(item.otherD)];
          weightedAveragesFields.limUse = [...weightedAveragesFields.limUse, Number(item.limUse)];
          weightedAveragesFields.clean = [...weightedAveragesFields.clean, Number(item.clean)];
          weightedAveragesFields.cullTare = [...weightedAveragesFields.cullTare, Number(item.cullTare)];
          weightedAveragesFields.grossDeliveredLBS += numeral(item.grossDeliveredLBS).value();
          weightedAveragesFields.netDeliveredLBS += numeral(item.netDeliveredLBS).value();
          weightedAveragesFields.loadValue += numeral(item.loadValue).value();
        }

        console.log('weightedAveragesFields', weightedAveragesFields);

        return {
          headers: headers,
          items: items,
          totals: [
          {
              grower: `TOTAL`,
              agtron: numeral(this.calculateWeightedAverage(weightedAveragesFields.agtron, weights)).format('0,0.00'),
              refractometer: numeral(this.calculateWeightedAverage(weightedAveragesFields.refractometer, weights)).format('0,0.00'),

              mot: numeral(this.calculateWeightedAverage(weightedAveragesFields.mot, weights)).format('0,0.00'),
              grGrn: numeral(this.calculateWeightedAverage(weightedAveragesFields.grGrn, weights)).format('0,0.00'),
              prGrn: numeral(this.calculateWeightedAverage(weightedAveragesFields.prGrn, weights)).format('0,0.00'),
              prBrk: numeral(this.calculateWeightedAverage(weightedAveragesFields.prBrk, weights)).format('0,0.00'),
              otherD: numeral(this.calculateWeightedAverage(weightedAveragesFields.otherD, weights)).format('0,0.00'),
              limUse: numeral(this.calculateWeightedAverage(weightedAveragesFields.limUse, weights)).format('0,0.00'),

              clean: numeral(this.calculateWeightedAverage(weightedAveragesFields.clean, weights)).format('0,0.00'),
              cullTare: numeral(this.calculateWeightedAverage(weightedAveragesFields.cullTare, weights)).format('0,0.00'),

              grossDeliveredLBS: numeral(weightedAveragesFields.grossDeliveredLBS).format('0,0.00'),
              netDeliveredLBS: numeral(weightedAveragesFields.netDeliveredLBS).format('0,0.00'),
              loadValue: numeral(weightedAveragesFields.loadValue).format('0,0.00'),
            }
          ]
        }
      }
    },
    watch: {

    },
    mounted: function(){

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      let now = new Date;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      this.startDate = now.toISOString().substr(0, 10);
      this.startMenu = false;
      this.endDate = now.addDays(7 * 2).toISOString().substr(0, 10);
      this.endMenu = false;

      this.loading = true;

      this.loadData();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
