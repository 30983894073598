<template>
  <div class="user_management">
    <v-row>
      <v-col cols="12">

        <h1>User Management</h1>

        <!-- Search Tools -->
        <!-- <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="searchString"
              v-on:input="search()"
              label="Search Users"
              placeholder="Enter name or email"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="selectedRoles"
              :items="roles"
              item-text="name"
              v-on:input="search()"
              attach
              chips
              label="Select Roles Inclusively"
              multiple
              outlined
            ></v-select>
          </v-col>
        </v-row> -->

        <!-- <v-row>
          <v-col cols="2">
            <v-select
              v-model="selectedCountry"
              :items="countries"
              item-text="name"
              v-on:input="search()"
              label="Select Country"
              return-object
              outlined
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-if="Object.keys(this.selectedCountry).length > 0"
              v-model="selectedProvince"
              :items="provinces"
              item-text="name"
              v-on:input="search()"
              label="Select Province"
              return-object
              outlined
            ></v-select>
          </v-col>
            
          <v-col cols="2">
            <v-select
              v-if="Object.keys(this.selectedProvince).length > 0"
              v-model="selectedCity"
              :items="cities"
              item-text="name"
              v-on:input="search()"
              label="Select City"
              return-object
              outlined
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-if="Object.keys(this.selectedProvince).length > 0"
              v-model="selectedRegion"
              :items="regions"
              item-text="name"
              v-on:input="search()"
              label="Select Town"
              return-object
              outlined
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-if="Object.keys(this.selectedProvince).length > 0"
              v-model="selectedTown"
              :items="towns"
              item-text="name"
              v-on:input="search()"
              label="Select Region"
              return-object
              outlined
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-btn v-if="Object.keys(this.selectedCountry).length > 0" v-on:click="clearGeo()">Clear</v-btn>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col>
            <v-btn color="primary" v-on:click="newUserOverlay = true">New User</v-btn>
          </v-col>
        </v-row>

        <!-- List of searched Users -->
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      ID
                    </th>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Username
                    </th>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Phone
                    </th>
                    <th class="text-left">
                      Grower
                    </th>
                    <th class="text-left">
                      Roles
                    </th>

                    <th class="text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="user in users"
                    :key="user.id"
                    :class="user.deactivated ? 'deactivated' : ''"
                  >
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.user_name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td>{{ user.grower ? user.grower.code : "" }}</td>
                    <td>{{ formatRole(user.roles).join(', ') }}</td>
                    <td class="text-right">
                      <v-btn
                        color="primary"
                        @click="selectUser(user)"
                        v-if="!user.deactivated"
                      >
                        edit
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="deactivateUser(user)"
                        v-if="!user.deactivated"
                      >
                        deactivate
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="activateUser(user)"
                        v-if="user.deactivated"
                      >
                        activate
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-overlay
          :absolute="true"
          :value="overlay"
          class="d-flex align-start"
        >
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card light>
                  <v-card-title>Manage User</v-card-title>
                  <v-card-subtitle>You can now manage this specific user.</v-card-subtitle>
                  <v-card-text>
                    <UserManage :user="selectedUser" @update-event="manageUserClose()"/>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="error" text @click="manageUserClose()">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-overlay>

        <v-overlay
          :absolute="true"
          :value="newUserOverlay"
          class="d-flex align-start"
        >
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card light>
                  <v-card-title>New User</v-card-title>
                  <v-card-subtitle>Add a new user to the system.</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="newUser.name"
                          label="New User name"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="newUser.user_name"
                          label="New Username"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="newUser.email"
                          label="New User email"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="newUser.phone"
                          label="New User phone number"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="newUser.password"
                          label="User Password"
                          :type="'password'"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="newUser.roles"
                          :items="roles"
                          item-text="name"
                          attach
                          chips
                          label="Select Roles for this user."
                          multiple
                          outlined
                        ></v-select>
                        <v-select
                          v-if="isGrower"
                          v-model="newUser.grower"
                          :items="growers"
                          item-text="name"
                          attach
                          return-object
                          label="Select Grower this user has access to."
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="error" text @click="newUserClose()">
                      Close
                    </v-btn>
                    <v-btn color="primary" text @click="createNewUser()">
                      Create new user
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-overlay>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import axios from '../service/axios.config';
import UserManage from '../components/UserManage.vue'

export default {
  name: 'UserManagementView',
  components: {
    UserManage
  },
  data: function() {
    return {
      users: [],
      roles: [],
      growers: [],
      selectedRoles: [],
      overlay: false,
      selectedUser: {},
      newUserOverlay: false,
      newUser: {
        name: '',
        user_name: '',
        email: '',
        phone: '',
        password: '',
        roles: [],
        grower: null
      },
      isGrower: false,
    }
  },
  methods: {
    loadData: function(){

      this.users = [];
      this.roles = [];
      this.growers = [];
      this.selectedRoles = [];
      this.overlay = false;
      this.selectedUser = {};
      this.newUserOverlay = false;
      this.newUser = {
        name: '',
        user_name: '',
        email: '',
        phone: '',
        password: '',
        roles: [],
        grower: null
      }
      this.isGrower = false;

      axios.get('/user').then((response) => {
        this.users = response.data.users.sort((a, b) => {
          return a.id - b.id;
        }).sort((a, b) => {
          return a.deactivated - b.deactivated;
        });
      });
      axios.get('/grower').then((response) => {
        this.growers = response.data.growers;
      });
      axios.get('/role').then((response) => {
        this.roles = response.data.roles;
      });
    },
    formatRole(roles){
      return roles.map((role) => {
        return role.name;
      });
    },
    selectUser(user){
      this.overlay = true;
      this.selectedUser = user;
      this.selectedUser.roles.forEach((role) => {
        if(role.name == "grower"){
          this.isGrower = true;
        }
      });
    },
    createNewUser(){
      const newUser = {
        name: this.newUser.name,
        user_name: this.newUser.user_name,
        email: this.newUser.email == "" ? null : this.newUser.email,
        phone: this.newUser.phone,
        password: this.newUser.password,
        roles: this.newUser.roles,
      }

      if(this.newUser.grower){
        newUser.grower_id = this.newUser.grower.id;
      }

      axios.post('/user', newUser).then(() => {
      });

      this.newUser = {
        name: '',
        user_name: '',
        email: '',
        phone: '',
        password: '',
        roles: [],
        grower: null
      }
      this.newUserOverlay = false;
      this.loadData();
    },
    manageUserClose: function(){
      this.overlay = false;
      this.loadData();
    },
    newUserClose: function(){
      this.newUserOverlay = false;
      this.loadData();
    },
    deactivateUser: function(user){
      axios.patch(`/user/${user.id}`, {
        deactivated: true,
      }).then(() => {
        this.loadData();
      });
    },
    activateUser: function(user){
      axios.patch(`/user/${user.id}`, {
        deactivated: false,
      }).then(() => {
        this.loadData();
      });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function() {
    this.loadData();
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>

<style lang="scss" scoped>
  .deactivated {
    background-color: darkGrey;
  }
</style>
