<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-select
          v-model="selectedGrower"
          v-on:change="onChangeGrower()"
          :items="growers"
          item-text="display-text"
          label="Select Grower"
          return-object
          solo
        ></v-select>

        <v-btn color="primary" @click="bulkSaveNewDefaultDelivery()">bulk save ({{ bulkSelectedDates.length }})</v-btn>

        <v-btn color="error" @click="bulkDeactivateDefaultDelivery()">bulk delete ({{ bulkSelectedDates.length }})</v-btn>

        <v-btn @click="bulkSelectClear()">clear selection ({{ bulkSelectedDates.length }})</v-btn>

        <v-card
          class="d-flex pa-2 flex-wrap"
          outlined
          tile
          :key="rerenderCalendar"
        >
          <div v-for="hour in displayCalendar.hours" v-bind:key="hour">
            <h3>{{ hour }}:00</h3>
            <table style="width: 20%">
              <tr>
                <th>-</th>
                <th>:00</th>
                <th>:15</th>
                <th>:30</th>
                <th>:45</th>
              </tr>

              <tr
                class="dateSelectionRow"
                v-for="i in displayCalendar.days"
                v-bind:key="i"
              >
                <td class="dateSelectionRow selected">
                  {{ addDaysToDate(selectedSeason.start, i + 1) }}
                </td>
                <td
                  :class="calendar[`${hour}:0`] ? 'selected' : ''"
                  :id="hour + ':0-' + i"
                  v-on:click="clickDate2(`${hour}:0-${i}`)"
                ></td>
                <td
                  :class="calendar[`${hour}:15`] ? 'selected' : ''"
                  :id="hour + ':15-' + i"
                  v-on:click="clickDate2(`${hour}:15-${i}`)"
                ></td>
                <td
                  :class="calendar[`${hour}:30`] ? 'selected' : ''"
                  :id="hour + ':30-' + i"
                  v-on:click="clickDate2(`${hour}:30-${i}`)"
                ></td>
                <td
                  :class="calendar[`${hour}:45`] ? 'selected' : ''"
                  :id="hour + ':45-' + i"
                  v-on:click="clickDate2(`${hour}:45-${i}`)"
                ></td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="overlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card light>
              <v-card-title>Manage Default Delivery</v-card-title>
              <v-card-text>
                <div v-if="!selectedDefaultDelivery">
                  <p>New Delivery</p>

                  <v-row>
                    <v-col cols="12" class="text-left">
                      <v-btn
                        color="primary"
                        text
                        @click="saveNewDefaultDelivery()"
                        >Save New Delivery</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>

                <div v-if="selectedDefaultDelivery">
                  <v-row>
                    <v-col cols="12" class="text-left">
                      <p>ID: {{ selectedDefaultDelivery.id }}</p>
                      <p>
                        Grower Code: {{ selectedDefaultDelivery.grower.code }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="text-left">
                      <v-btn
                        color="error"
                        text
                        @click="
                          deactivateDefaultDelivery(selectedDefaultDelivery)
                        "
                        v-if="
                          selectedDefaultDelivery.active &&
                          !selectedDefaultDelivery.arrived
                        "
                        >Deactivate Delivery</v-btn
                      >
                    </v-col>
                    <!-- <v-col cols="12" class="text-left">
                      <v-btn
                        color="primary"
                        text
                        @click="
                          activateDefaultDelivery(selectedDefaultDelivery)
                        "
                        v-if="
                          !selectedDefaultDelivery.active &&
                          !selectedDefaultDelivery.arrived
                        "
                        >Activate Delivery</v-btn
                      >
                    </v-col> -->
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="overlay = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "../service/axios.config";

export default {
  name: "DefaultSchedule",
  components: {},
  data: function () {
    return {
      seasons: null,
      selectedSeason: null,
      growers: null,
      selectedGrower: null,
      selectedGrowerDefaultDeliveries: null,
      displayCalendar: {
        hours: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23,
        ],
        days: [0, 1, 2, 3, 4, 5, 6],
      },
      calendar: {},
      rerenderCalendar: false,
      selectedDefaultDelivery: null,
      overlay: false,
      selectedDate: null,
      weekDays: [],
      bulkSelectedDates: []
    };
  },
  methods: {
    addDaysToDate: function (date, additionalDay) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      date = new Date(date);
      date.setDate(date.getDate() + additionalDay);
      return days[date.getDay()];
    },
    addGrowerName: function (data) {
      return data.code;
    },
    onChangeGrower: function () {
      this.loadData();
    },
    loadDataMount: function(){
      
    },
    loadData: function () {

      axios.get("/season").then((response) => {
        this.seasons = response.data.seasons
          .sort((a, b) => {
            return b.id - a.id;
          })
          .map((season) => {
            return season;
          });

        this.seasons.forEach((season, index) => {
          this.seasons[index].default_deliveries = season.default_deliveries.map(
            (defaultDelivery) => {

              let testDate = new Date(defaultDelivery.start + " UTC");
              testDate.setHours(testDate.getHours() - 2);

              defaultDelivery.start = new Date(defaultDelivery.start + " UTC");
              defaultDelivery.end = new Date(defaultDelivery.end + " UTC");
              return defaultDelivery;
            }
          );
        });

        this.selectedSeason = this.seasons[0];

        // Growers
        const thisSeasonArr = response.data.seasons.filter((season) => {
          if (season.active) {
            return season;
          }
        });

        this.growers = thisSeasonArr[0].growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`; //  - ${grower.user.name}
          return grower;
        });

        if(!this.selectedGrower){
          this.selectedGrower = this.growers[0];
        }

        // Old
        Object.entries(this.calendar).forEach(([key]) => {
          document.getElementById(key).removeAttribute("class");
          document.getElementById(key).innerHTML = "";
        });

        this.calendar = {};

        this.selectedGrowerDefaultDeliveries =
          this.selectedSeason.default_deliveries.filter((defaultDelivery) => {
            if ((defaultDelivery.grower_id == this.selectedGrower.id) && defaultDelivery.active ) {
              return defaultDelivery;
            }
          });

        let [seasonYear, seasonMonth, seasonDay] =
          this.selectedSeason.start.split("-");
        let seasonDate = new Date(
          seasonYear,
          seasonMonth - 1,
          seasonDay,
          0,
          0,
          0,
          0
        );

        this.selectedGrowerDefaultDeliveries.forEach((delivery) => {
          // To calculate the time difference of two dates
          let Difference_In_Time =
            delivery.start.getTime() - seasonDate.getTime();

          // To calculate the no. of days between two dates
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

          let deliverySquareID = `${delivery.start.getHours()}:${delivery.start.getMinutes()}-${Math.floor(
            Difference_In_Days
          )}`;

          if (!this.calendar[deliverySquareID]) {
            this.calendar[deliverySquareID] = delivery;
          }
        });

        // Change document queries to inline
        Object.entries(this.calendar).forEach(([key, value]) => {
          document.getElementById(key).removeAttribute("class");
          document.getElementById(key).innerHTML = this.calendar[key].grower.code;
          if (value.active) {
            if (value.arrived) {
              document.getElementById(key).classList.add("arrived");
            } else {
              document.getElementById(key).classList.add("active");
            }
          } else {
            document.getElementById(key).classList.add("notActive");
          }
        });
      });
    },
    bulkSelectClear: function(){
      this.bulkSelectedDates.forEach((bulkSelectedDate) => {
        document.getElementById(bulkSelectedDate.htmlID).classList.remove("bulkSelected");
      });
      this.bulkSelectedDates = [];
    },
    clickDate2: function(id){

      let [hourMinutes, dayAddition] = id.split("-");
      let [hours, minutes] = hourMinutes.split(":");
      let [year, month, day] = this.selectedSeason.start.split("-");
      let date = new Date(year, month - 1, Number(day) + Number(dayAddition), hours, minutes, 0, 0);
      let selectedDate = date;

      this.bulkSelectedDates = this.bulkSelectedDates.filter((bulkSelectedDate) => {
        if(bulkSelectedDate.htmlID != id){
          return bulkSelectedDate;
        }
      });

      this.bulkSelectedDates = [...this.bulkSelectedDates, {
        htmlID: id,
        date: selectedDate,
        delivery: this.calendar[id]
      }]
      document.getElementById(id).classList.add("bulkSelected");
    },
    clickDate: function (id) {

      let [hourMinutes, dayAddition] = id.split("-");
      let [hours, minutes] = hourMinutes.split(":");

      let [seasonYear, seasonMonth, seasonDay] =
        this.selectedSeason.start.split("-");
      let selectedDate = new Date(
        seasonYear,
        seasonMonth - 1,
        Number(seasonDay) + Number(dayAddition),
        hours,
        minutes,
        0,
        0
      );

      // console.log(`${seasonYear}-${seasonMonth}-${seasonDay}`);

      this.selectedDate = selectedDate;
      // this.overlay = true;

      this.selectedDefaultDelivery = this.calendar[id];
      // console.log(this.selectedDefaultDelivery);
      // console.log(this.selectedDate);

      this.bulkSelectedDates = this.bulkSelectedDates.filter((bulkSelectedDate) => {
        if(bulkSelectedDate.htmlID != id){
          return bulkSelectedDate;
        }
      });

      this.bulkSelectedDates = [...this.bulkSelectedDates, {
        htmlID: id,
        date: selectedDate,
        delivery: this.calendar[id]
      }]

      document.getElementById(id).classList.add("bulkSelected");
    },
    bulkSaveNewDefaultDelivery: function (){

      this.bulkSelectedDates.forEach((bulkSelectDate) => {
        if(bulkSelectDate.delivery == undefined){
          axios
          .post("/delivery-default", {
            growerId: this.selectedGrower.id,
            start: bulkSelectDate.date.toISOString(),
            seasonId: this.selectedSeason.id,
          })
          .then(() => {
            this.loadData();
          });
        } 
      });

      this.bulkSelectedDates = [];
    },
    bulkDeactivateDefaultDelivery: function (){

      this.bulkSelectedDates.forEach((bulkSelectDate) => {
        if(bulkSelectDate.delivery !== undefined){
          axios
            .delete(`/delivery-default/${bulkSelectDate.delivery.id}`)
            .then(() => {
              this.loadData();
            });
        }
      });

      this.bulkSelectedDates = [];
    },
    saveNewDefaultDelivery: function () {
      let dateString = this.selectedDate.toISOString();

      axios
        .post("/delivery-default", {
          growerId: this.selectedGrower.id,
          start: dateString,
          seasonId: this.selectedSeason.id,
        })
        .then(() => {
          this.overlay = false;
          this.loadData();
        });
    },
    deactivateDefaultDelivery: function (selectedDefaultDelivery) {
      axios
        .delete(`/delivery-default/${selectedDefaultDelivery.id}`)
        .then(() => {
          this.overlay = false;
          this.loadData();
        });
    },
    activateDefaultDelivery: function () {
      axios
        .post(`/delivery-default/${this.selectedDefaultDelivery.id}/enable`)
        .then(() => {
          this.overlay = false;
          this.loadData();
        });
    },
  },
  watch: {
    selectedGrower: function () {
      this.loadData();
    },
  },
  mounted: function () {
    this.loadData();
  },
  created: () => {},
  destroyed: () => {},
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid gray;
  padding: 2px;
  border-radius: 2px;
}

td:hover {
  cursor: pointer;
  transform: scale(1.1);
  outline: 2px solid gray;
}

.arrived {
  background-color: grey;
}

.active {
  background-color: lightgray;
}

.notActive {
  background-color: red;
}

.bulkSelected {
  transform: scale(1.1);
  outline: 2px solid gray;
}

.dateSelectionRow {
  font-size: 0.75em;
}

.dateSelectionRow td {
  height: 28px;
  margin: 0px;
}

.dateSelectionRow td.active {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.notActive {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.arrived {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.selected {
  padding: 0px;
  margin: 0px;
}
</style>

