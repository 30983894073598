<template>
  <div class="deliveries">
    <v-row>
      <v-col cols="12">
        <h1>Deliveries</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <DailyDeliveryCalandar/>
      </v-col>
    </v-row>
  </div>
</template>

<script>

// import axios from '../service/axios.config';
import DailyDeliveryCalandar from '@/components/DailyDeliveryCalandar.vue';

export default {
  name: 'DeliveriesView',
  components: {
    DailyDeliveryCalandar
  },
  data: function() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {
    
  },
  mounted: function() {

  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
