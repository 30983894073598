<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="4">
        <v-card width="100%">
          <v-card-text>
            <v-text-field
              label="Name"
              outlined
              v-model="updatedGrowerInfo.name"
            ></v-text-field>
            <v-text-field
              label="Code"
              outlined
              v-model="updatedGrowerInfo.code"
            ></v-text-field>
            <v-text-field
              label="Load Size"
              outlined
              v-model="updatedGrowerInfo.loadSize"
            ></v-text-field>

            <v-checkbox
              v-model="outsideProcessor"
              label="Is outside processor"
            ></v-checkbox>

            <v-card-actions>
              <v-btn color="primary" v-on:click="updateGrower()">Update</v-btn>
            </v-card-actions>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="updatedGrowerAddress.streetAddress"
              label="Street Address"
              outlined
            ></v-text-field>
            <v-select
              v-model="updatedGrowerAddress.country"
              :items="countries"
              item-text="name"
              label="Select Country"
              return-object
              outlined
            ></v-select>
            <v-select
              v-if="updatedGrowerAddress.country"
              v-model="updatedGrowerAddress.province"
              :items="updatedGrowerAddress.country.provinces"
              item-text="name"
              label="Select Province"
              return-object
              outlined
            ></v-select>
            <v-select
              v-if="updatedGrowerAddress.province"
              v-model="updatedGrowerAddress.city"
              :items="updatedGrowerAddress.province.cities"
              item-text="name"
              label="Select City"
              return-object
              outlined
            ></v-select>
            <v-select
              v-if="updatedGrowerAddress.province"
              v-model="updatedGrowerAddress.region"
              :items="updatedGrowerAddress.province.regions"
              item-text="name"
              label="Select Region"
              return-object
              outlined
            ></v-select>
            <v-select
              v-if="updatedGrowerAddress.province"
              v-model="updatedGrowerAddress.town"
              :items="updatedGrowerAddress.province.townships"
              item-text="name"
              label="Select Town"
              return-object
              outlined
            ></v-select>
            <v-card-actions>
              <v-btn color="primary" v-on:click="updateAddress()">Update</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <!-- contract -->
        <v-card>
          <v-card-text>
            <v-text-field
              label="Contracted Tons"
              outlined
              dense
              suffix="tons"
              v-model="selectedGrowerSeasonalData.contracted_tons"
            ></v-text-field>
            <v-text-field
              label="Estimated Tons"
              outlined
              dense
              suffix="tons"
              v-model="
                selectedGrowerSeasonalData.estimated_tons_per_acre
              "
            ></v-text-field>

            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="saveSeasonalGrowerData()"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>

        <!-- fields -->
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="newField.name"
              label="Field name"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="newField.acres"
              label="Field acres"
              outlined
              dense
            ></v-text-field>
            <v-card-actions>
              <v-btn color="primary" v-on:click="addFields()"
                >Add field to season</v-btn
              >
            </v-card-actions>
          </v-card-text>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Field Name</th>
                  <th class="text-left">Acres</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="field in selectedGrowerSeasonalData.fields"
                  :key="field.id"
                  @click="selectField(field)"
                >
                  <td class="text-left">{{ field.name }}</td>
                  <td class="text-left">{{ field.acres }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="editFieldOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-card light style="width: 500px">
            <v-card-title>Edit field acres</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="updateFieldName"
                  label="Field Name"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  type="number"
                  v-model="updateFieldAcres"
                  label="Field acres"
                  outlined
                  dense
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="updateFieldFcn()">
                  Confirm
                </v-btn>
                <v-btn
                  color="error"
                  text
                  @click="editFieldOverlay = false"
                >
                  Cancel
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'UserManage',
    components: {},
    props: {
      grower: Object
    },
    data: function() {
      return {
        updatedGrowerInfo: {
          name: null,
          code: null,
          loadSize: null
        },
        updatedGrowerAddress: {
          streetAddress: null,
          country: null,
          province: null,
          city: null,
          town: null,
          region: null
        },
        newField: {
          name: "",
          acres: 0
        },
        countries: [],
        totalAcres: 0,
        selectedGrowerSeasonalData: {},
        currentSeason: {},
        updateField: {},
        updateFieldName: '',
        updateFieldAcres: '',
        editFieldOverlay: false,
        outsideProcessor: false,

      }
    },
    methods: {
      loadData: function() {
        this.updatedGrowerInfo.name = this.grower.name;
        this.updatedGrowerInfo.code = this.grower.code;
        this.updatedGrowerInfo.loadSize = this.grower.load_size;
        this.outsideProcessor = this.grower.outside_processor;

        for (const field of this.grower.grower_fields){
          this.totalAcres += Number(field.acres);
        }

        axios.get('/country').then((response) => {
          this.countries = response.data.countries;

          this.updatedGrowerAddress.streetAddress = this.grower.address.street_address;
          
          this.updatedGrowerAddress.country = this.countries.filter((country) => {
            if(country.id == this.grower.address.country.id){
              return country;
            }
          })[0];

          this.updatedGrowerAddress.province = this.updatedGrowerAddress.country.provinces.filter((province) => {
            if(province.id == this.grower.address.province.id){
              return province;
            }
          })[0];

          this.updatedGrowerAddress.city = this.updatedGrowerAddress.province.cities.filter((city) => {
            if(city.id == this.grower.address.city.id){
              return city;
            }
          })[0];

          // this.updatedGrowerAddress.town = this.updatedGrowerAddress.province.towns.filter((town) => {
          //   if(town.id == this.grower.address.town.id){
          //     return town;
          //   }
          // })[0];

          // this.updatedGrowerAddress.region = this.updatedGrowerAddress.province.regions.filter((region) => {
          //   if(region.id == this.grower.address.region.id){
          //     return region;
          //   }
          // })[0];

          this.updatedGrowerAddress.town = this.grower.address.town;
          this.updatedGrowerAddress.region = this.grower.address.region;
        });

        axios.get("/season", {
          params: {
            active: true,
          }
        }).then((response) => {
          this.currentSeason = response.data.seasons[0];

          axios.get(`/season/${response.data.seasons[0].id}/growers/data`).then((response) => {
            this.selectedGrowerSeasonalData =
              response.data.seasonal_grower_data.filter((growerSeasonData) => {
                if (
                  growerSeasonData.grower_id ==
                  this.grower.id
                ) {
                  return growerSeasonData;
                }
              })[0];

            if (!this.selectedGrowerSeasonalData) {
              this.selectedGrowerSeasonalData = {
                contracted_tons: 0,
                estimated_tons_per_acre: 0,
              };
            }

            this.selectedGrowerSeasonalData.fields = response.data.seasonal_grower_fields.filter((field) => {
              if(field.grower_id == this.grower.id){
                return field;
              }
            });
          });
        });
      },
      updateAddress: function(){
        axios.patch(`/address/${this.grower.address.id}`, {
          streetAddress: this.updatedGrowerAddress.streetAddress,
          countryId: this.updatedGrowerAddress.country.id,
          provinceId: this.updatedGrowerAddress.province.id,
          cityId: this.updatedGrowerAddress.city ? this.updatedGrowerAddress.city.id : null,
          townId: this.updatedGrowerAddress.town ? this.updatedGrowerAddress.town.id : null,
          regionId: this.updatedGrowerAddress.region ? this.updatedGrowerAddress.region.id : null,
        }).then(() => {
        });
      },
      updateGrower: function(){
        axios.patch(`/grower/${this.grower.id}`, {
          name: this.updatedGrowerInfo.name,
          code: this.updatedGrowerInfo.code,
          loadSize: this.updatedGrowerInfo.loadSize,
          outside_processor: this.outsideProcessor,
        }).then(() => {
        });
      },
      // addFields: function(){
      //   axios.post(`/grower/${this.grower.id}/field`, {
      //     name: this.newField.name,
      //     acres: this.newField.acres,
      //   }).then((response) => {
      //     console.log(response.data);
      //   });
      // },
      saveSeasonalGrowerData: function () {
        axios
          .patch(
            `/season/${this.currentSeason.id}/grower/${this.grower.id}/data`,
            {
              contracted_tons: this.selectedGrowerSeasonalData.contracted_tons,
              estimated_tons_per_acre:
                this.selectedGrowerSeasonalData.estimated_tons_per_acre,
            }
          )
          .then(() => {
            this.loadData();
          });
      },
      addFields: function () {
        axios
          .post(`/grower/${this.grower.id}/field`, {
            name: this.newField.name,
            acres: this.newField.acres,
          })
          .then(() => {
            this.loadData();
          });
      },
      selectField: function(field){
        this.updateField = field;
        this.updateFieldName = field.name;
        this.updateFieldAcres = field.acres;
        this.editFieldOverlay = true;
      },
      updateFieldFcn: function(){

        axios
          .patch(`/grower/${this.updateField.grower_id}/field/${this.updateField.id}`, {
            acres: Number(this.updateFieldAcres),
            name: this.updateFieldName
          })
          .then(() => {
            this.editFieldOverlay = false;
            this.loadData(this.selectedGrowerForSeasonalData);
          });
      }
    },
    watch: {

    },
    mounted: function (){

      this.loadData();
      
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

