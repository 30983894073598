<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="newWasteType"
              label="New Tomato variety code"
              solo
            ></v-text-field>
            <v-btn v-on:click="store()" color="primary" block>Add</v-btn>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">
            <v-list>
              <template v-for="(wasteType, index) in wasteTypes">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="wasteType.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                  <v-divider
                    v-if="index < wasteTypes.length - 1"
                    :key="index"
                  ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'WasteTypeSettings',
    components: {},
    data: function() {
      return {
        wasteTypes: [],
        newWasteType: ""
      }
    },
    methods: {
      store: function(){
        axios.post(`/waste_type`, {
          name: this.newWasteType
        }).then((response) => {
          this.wasteTypes = [response.data.waste_types, ...this.wasteTypes];
        });
        this.newWasteType = "";
      }
    },
    watch: {

    },
    mounted: function() {
      axios.get('/waste_type').then((response) => {
        this.wasteTypes = response.data.waste_types;
      });
    },
    created: function() {

    },
    destroyed: function() {

    }
  }
</script>
