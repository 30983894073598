export const weightedAverage = (values, weights) => {

	if (values.length !== weights.length) {
		throw new Error("The length of values and weights arrays must be the same.");
	}

	let sumOfProducts = 0;
	let sumOfWeights = 0;

	for (let i = 0; i < values.length; i++) {
		sumOfProducts += Number(values[i]) * Number(weights[i]);
		sumOfWeights += Number(weights[i]);
	}

	if (sumOfWeights == 0) {
		return sumOfProducts;
	}

	return Number(sumOfProducts) / Number(sumOfWeights);
}
