<template>
    <v-row>
      <v-col cols="12">
        <v-card width="100%">
          <v-card-text>
            <div id="printable">

              <!-- Information -->
              <div>
                <p><b>Grower Statement</b></p>
                <p>Grower: {{ selectedGrower.code }} - {{ selectedGrower.name }}</p>
                <p>Date range: {{startDate.toDateString()}} - {{endDate.toDateString()}}</p>
              </div>

              <!-- Delivery table -->
              <v-simple-table dense width="1200px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="padding-right: 10px">Date</th>
                      <th class="text-left" style="padding-right: 10px">Cert #</th>
                      <th class="text-left" style="padding-right: 10px">Agtron</th>
                      <th class="text-left" style="padding-right: 10px">%CHG</th>
                      <th class="text-left" style="padding-right: 10px">Delivered Gross</th>
                      <th class="text-left" style="padding-right: 10px">Delivered Net</th>
                      <th class="text-left" style="padding-right: 10px">Value per ton</th>
                      <th class="text-left" style="padding-right: 10px">Load Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="delivery in calculatedDeliveries"
                      :key="delivery.id"
                    >
                      <td style="padding-right: 20px">{{ delivery.date }}</td>
                      <td style="padding-right: 20px">{{ delivery.cert }}</td>
                      <td style="padding-right: 20px">{{ delivery.agtron }}</td>
                      <td style="padding-right: 20px">{{ delivery.chg.toFixed(2) }}%</td>
                      <td style="padding-right: 20px">{{ delivery.gross | number('0,0') }}</td>
                      <td style="padding-right: 20px">{{ delivery.net | number('0,0')}}</td>
                      <td style="padding-right: 20px">{{ delivery.price | currency }}</td>
                      <td>{{ (delivery.value/ 100) | currency }}</td>
                    </tr>
                    <tr>
                      <td>TOTAL</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{{ totalGross.value | number('0,0') }}</td>
                      <td>{{ totalNet.value | number('0,0') }}</td>
                      <td>-</td>
                      <td>{{ (totalValue.value/ 100) | currency }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>Tons: {{ totalNet.value/2000 | number('0,0.00') }}</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div style="padding-top: 50px; padding-bottom: 50px;">
                <hr/>
              </div>

              <!-- Calculations -->
              <v-simple-table dense width="1200px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="padding-right: 50px">Deductions</th>
                      <th class="text-left" style="padding-right: 50px">Quantity</th>
                      <th class="text-left" style="padding-right: 50px">Value</th>
                      <th class="text-center" style="padding-right: 50px">Units</th>
                      <th class="text-left" style="padding-right: 50px">Amount</th>
                    </tr>
                  </thead>
                  <tbody>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">PRD DEDUCTION CR (10% LOAD VALUE)</td>
                      <td>{{ (calculationsQty.prd_ded_cr/ 100) | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.prd_ded_cr }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.prd_ded_cr/100) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">PRD DEDUCTION DB</td>
                      <td>{{ (calculationsQty.prd_ded_db/ 100) | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.prd_ded_db }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.prd_ded_db/100) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">MARKETING/INSPECTION FEES</td>
                      <td>{{ (calculationsQty.mrk_fee / 2000) | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.mrk_fee }}</td>
                      <td style="text-align: center;">$/TON</td>
                      <td>{{ (calculationsAmt.mrk_fee) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">MARKETING/INSPECTION HST</td>
                      <td>{{ (calculationsQty.mrk_hst/2000) | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.mrk_hst }}</td>
                      <td style="text-align: center;">$/TON</td>
                      <td>{{ (calculationsAmt.mrk_hst) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">PLANTS - LOCAL HYBRID</td>
                      <td>{{ calculationsQty.plant | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.plant }}</td>
                      <td style="text-align: center; padding-right: 10px; padding-left: 10px;">$/1000 PLANTS</td>
                      <td>{{ (calculationsAmt.plant) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">REPLANTS > 8% HYBRID</td>
                      <td>{{ calculationsQty.replant | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.replant }}</td>
                      <td style="text-align: center; padding-right: 10px; padding-left: 10px;">$/1000 PLANTS</td>
                      <td>{{ (calculationsAmt.replant) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">HST ON TOTAL PLANTS</td>
                      <td>{{ calculationsQty.plant_hst | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.plant_hst }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.plant_hst) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">INSURANCE</td>
                      <td>{{ calculationsQty.insurance | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.insurance }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.insurance) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">BLENDING ADJUSTMENT</td>
                      <td>{{ calculationsQty.blend | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.blend }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.blend) | currency }}</td>
                    </tr>

                    <!-- Solid Premium Deductions -->
                    <tr>
                      <td style="padding-right: 10px">SOLIDS PREMIUM</td>
                      <td>{{ calculationsQty.solid | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.solid }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.solid) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">SHUNTING PROGRAM</td>
                      <td>{{ calculationsQty.shtprg | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.shtprg }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.shtprg) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">SHUNTING PROGRAM HST</td>
                      <td>{{ calculationsQty.shtprghst | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.shtprghst }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.shtprghst) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">SHUNTING PROGRAM RECONCILIATION</td>
                      <td>{{ calculationsQty.shtprgrec | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.shtprgrec }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.shtprgrec) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">SHUNTING PROGRAM RECONCILIATION HST</td>
                      <td>{{ calculationsQty.shtprgrechst | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.shtprgrechst }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.shtprgrechst) | currency }}</td>
                    </tr>

                    <!-- PRD Deductions -->
                    <tr>
                      <td style="padding-right: 10px">MISC</td>
                      <td>{{ calculationsQty.misc | number('0,0.00') }}</td>
                      <td>{{ calculationsDisplay.misc }}</td>
                      <td style="text-align: center;">$</td>
                      <td>{{ (calculationsAmt.misc) | currency }}</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td><b>Total Deductions</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ (totalDeductions.value) | currency }}</td>
                    </tr>

                    <tr>
                      <td><b>Amount Payable</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ ((totalValue.value/ 100) - totalDeductions.value) | currency }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" v-on:click="printCertificate()">Print Accounting</v-btn>
      </v-col>
    </v-row>
</template>

<script>

  // import axios from '../service/axios.config';


  export default {
    name: 'CertificateViewer',
    components: {
    
    },
    props: {
      calculatedDeliveries: {},
      calculationsQty: {},
      calculationsDisplay: {},
      calculationsAmt: {},
      startDate: {},
      endDate: {},
      selectedGrower: {},
      totalGross: {},
      totalNet: {},
      totalValue: {},
      totalDeductions: {}
    },
    data: function() {
      return {
      }
    },
    methods: {
      printCertificate: function(){
        this.$htmlToPaper("printable");
      },
    },
    computed: {
    },
    watch: {

    },
    mounted: function(){
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

<style scoped>
  th{
    padding-right: 20px;
  }
</style>
