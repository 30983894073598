/**
 * This function by default will round the value to the nearest integer value.
 * 5 > up and 5 < down. Then there is a second optional called factor. By
 * default it is set to 0 which will shift the decimal 0 places. You can set
 * the factor to be a positive or negative integer value which indicates how
 * many decimal places to shift the value. Factor of -1 will shift the place 1
 * to the left. Factor of 1 will shift the decimal 1 to the right.
 */

const decimalShiftRound = (val, factor = 0) => {
	return Math.round(Number(val) * Math.pow(10, Number(factor)));
};

module.exports = decimalShiftRound;
