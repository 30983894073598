<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Scheduled vs Delivered 
          </v-col>
          <v-col align-self="end">
            <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-select
              v-model="selectedGrower"
              :items="growers"
              item-text="display-text"
              label="Select Grower"
              return-object
              solo
              :disabled="allGrowers"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-checkbox v-model="allGrowers" label="All Growers"></v-checkbox>
          </v-col>
        </v-row>        
        

        <v-row>
          <!-- <v-col cols="3">
            <v-menu
              v-model="selectedDatePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDatePicker"
                  label="Select date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDatePicker"
                @input="selectedDatePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col> -->

          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Start date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="3">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Select end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>


          <v-col cols="3">
            <v-btn color="primary" v-on:click="search()">Search</v-btn>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <v-simple-table
              fixed-header
              height="500px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Tons Scheduled</th>
                    <th class="text-left">Tons Delivered</th>
                    <th class="text-left">Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left"><b>Total (Loads: {{ deliveries.length }})</b></td>
                    <td class="text-left">{{ calculations.totalTonsScheduled / 2000 | number('0,0.00') }}</td>
                    <td class="text-left">{{ calculations.totalTonsDelivered / 2000 | number('0,0.00') }}</td>
                    <td class="text-left">{{ calculations.totalDifference / 2000 | number('0,0.00') }}</td>
                  </tr>
                  <tr>
                    <td class="text-left"><b>Average</b></td>
                    <td class="text-left">{{ calculations.averageTonsScheduled / 2000 | number('0,0.00') }}</td>
                    <td class="text-left">{{ calculations.averageTonsDelivered / 2000 | number('0,0.00') }}</td>
                    <td class="text-left">{{ calculations.averageDifference / 2000 | number('0,0.00') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import axios from '../../service/axios.config';

  export default {
    name: 'ScheduledDelivered',
    components: {},
    data: function() {
      return {
        growers: [],
        selectedDatePicker: null,
        selectedDatePickerMenu: false,
        deliveries: [],
        season: {},
        selectedGrower: {},
        loading: false,
        allGrowers: false,
        calculations: {
          totalTonsScheduled: 0,
          totalTonsDelivered: 0,
          totalDifference: 0,
          averageTonsScheduled: 0,
          averageTonsDelivered: 0,
          averageDifference: 0,
        },
        startDate: null,
        startMenu: false,
        endDate: null,
        endMenu: false,
      }
    },
    methods: {
      search: function(){
        this.loadData();
      },
      loadData: function(){

        this.loading = true;
        this.deliveries = [];
        this.calculations = {
          totalTonsScheduled: 0,
          totalTonsDelivered: 0,
          totalDifference: 0,
          averageTonsScheduled: 0,
          averageTonsDelivered: 0,
          averageDifference: 0,
        };

        let [yearS, monthS, dayS] = this.startDate.split('-');
        let queryStartDate = new Date(yearS, monthS-1, dayS, 0, 0, 0, 0);
        let startOfDay = queryStartDate.toISOString();

        let [yearE, monthE, dayE] = this.endDate.split('-');
        let queryEndDate = new Date(yearE, monthE-1, dayE, 0, 0, 0, 0);
          
        let Difference_In_Days = (queryEndDate.getTime() - queryStartDate.getTime()) / (1000 * 3600 * 24);

        let peramsObj = {
          date: startOfDay,
          addDays: Difference_In_Days
        }

        if(!this.allGrowers && this.selectedGrower){
          peramsObj.growerId = this.selectedGrower.id;
        }

        // Query
        axios.get("/delivery", {
          params: peramsObj,
        }).then((res) => {
          this.deliveries = res.data.deliveries;
          
          for (const delivery of this.deliveries) {

            this.calculations.totalTonsScheduled += Number(delivery.grower.load_size) * 2000;

            if(delivery.weight_in && delivery.weight_out){
              this.calculations.totalTonsDelivered += Number(delivery.weight_in) - Number(delivery.weight_out);
            }

            this.calculations.averageTonsScheduled = this.calculations.totalTonsScheduled / this.deliveries.length;
            this.calculations.averageTonsDelivered = this.calculations.totalTonsDelivered / this.deliveries.length;

            this.calculations.totalDifference = this.calculations.totalTonsScheduled - this.calculations.totalTonsDelivered;
            this.calculations.averageDifference = this.calculations.averageTonsScheduled - this.calculations.averageTonsDelivered;
          }

          this.loading = false;
        });
      },
    },
    watch: {

    },
    mounted: function(){

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      let now = new Date;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      this.startDate = now.toISOString().substr(0, 10);
      this.startMenu = false;
      this.endDate = now.addDays(7 * 2).toISOString().substr(0, 10);
      this.endMenu = false;

      this.loading = true;

      axios.get("/season", {
        params: {
          active: true,
        },
      }).then((res) => {
        this.season = res.data.seasons[0];

        this.growers = res.data.seasons[0].growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`;
          return grower;
        }).sort((a, b) => {
          return a.code - b.code;
        });

        this.selectedGrower = this.growers[0];

        this.loadData();
      });
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
