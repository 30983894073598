<template>
  <div class="season">
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Permanent Schedule</h1>
        <DefaultSchedule />
      </v-col>
    </v-row>

    <!-- New default delivery -->
    <v-overlay
      :absolute="true"
      :value="newDefaultDeliveryOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-card light>
            <v-card-title>New Default Delivery</v-card-title>
            <v-card-text>
              <v-select
                v-model="newDefaultDeliveryObject.grower"
                :items="growers"
                item-text="code"
                label="Select grower for delivery."
                solo
                return-object
              ></v-select>
              <p>{{ newDefaultDeliveryObject.startDate }}</p>
              <v-time-picker
                format="ampm"
                v-model="newDefaultDeliveryObject.startTime"
                :allowed-minutes="(m) => m % 15 === 0"
              ></v-time-picker>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                text
                @click="newDefaultDeliveryOverlay = false"
              >
                Close
              </v-btn>
              <v-btn color="primary" text @click="saveNewDefaultDeliver()">
                Save Season
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

    <!-- Edit default delivery -->
    <v-overlay
      :absolute="true"
      :value="editDefaultDeliveryOverlay"
      class="d-flex align-start"
      v-if="selectedDefaultDelivery"
    >
      <v-row>
        <v-col cols="12">
          <v-card light>
            <v-card-title>New Default Delivery</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="text-left">
                  <p>ID: {{ selectedDefaultDelivery.id }}</p>
                  <p>Grower Code: {{ selectedDefaultDelivery.grower.code }}</p>
                  <p>Frequency: {{ selectedDefaultDelivery.frequency }} days</p>
                  <p>Active: {{ selectedDefaultDelivery.active }}</p>
                  <p>Start: {{ selectedDefaultDelivery.start }}</p>
                  <p>End: {{ selectedDefaultDelivery.end }}</p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                text
                @click="
                  editDefaultDeliveryOverlay = false;
                  selectedDefaultDelivery = null;
                "
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                text
                v-on:click="deactivateDefaultDelivery()"
                v-if="selectedDefaultDelivery.active"
              >
                Deactivate Default Delivery
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import axios from "../service/axios.config";
import DefaultSchedule from "@/components/DefaultSchedule.vue";

export default {
  name: "DefaultDeliveriesView",
  components: {
    DefaultSchedule,
  },
  data: function () {
    return {
      selectedSeason: {},
      selectedSeasonEvents: [],
      seasons: [],
      seasonStart: "",
      defaultEnd: "",
      newSeasonOverlay: false,
      newDefaultDeliveryOverlay: false,
      seasonTypes: [],
      startMenu: false,
      endMenu: false,
      newSeasonObject: {
        seasonType: {},
        tomatoPrice: 0,
        start: "",
        end: "",
      },
      newDefaultDeliveryObject: {
        grower: {},
        startDate: "",
        startTime: "",
      },
      growers: [],
      editDefaultDeliveryOverlay: false,
      selectedDefaultDelivery: null,
    };
  },
  methods: {
    loadData: function () {
      // reset data obj
      (this.selectedSeason = {}),
        (this.selectedSeasonEvents = []),
        (this.seasons = []),
        (this.seasonStart = ""),
        (this.defaultEnd = ""),
        (this.newSeasonOverlay = false),
        (this.newDefaultDeliveryOverlay = false),
        (this.seasonTypes = []),
        (this.startMenu = false),
        (this.endMenu = false),
        (this.newSeasonObject = {
          seasonType: {},
          tomatoPrice: 0,
          start: "",
          end: "",
        }),
        (this.newDefaultDeliveryObject = {
          grower: {},
          startDate: "",
          startTime: "",
        }),
        (this.growers = []),
        (this.editDefaultDeliveryOverlay = false),
        (this.selectedDefaultDelivery = null),
        // Request
        axios.get("/season").then((response) => {
          const thisSeasonArr = response.data.seasons.filter((season) => {
            if (season.active) {
              return season;
            }
          });
          this.selectedSeason = thisSeasonArr[0];

          this.growers = thisSeasonArr[0].growers;
        });

      axios.get("/season-type").then((response) => {
        this.seasonTypes = response.data.seasonTypes;
      });
    },
    getEventColor(event) {
      if (event.active) {
        return "primary";
      } else {
        return "red";
      }
    },
    deactivateDefaultDelivery: function () {
      axios
        .delete(`/delivery-default/${this.selectedDefaultDelivery.id}`)
        .then(() => {
          this.editDefaultDeliveryOverlay = false;
          this.loadData();
        });
    },
    activateDefaultDelivery: function () {
      axios
        .post(`/delivery-default/${this.selectedDefaultDelivery.id}/activate`)
        .then(() => {
          this.editDefaultDeliveryOverlay = false;
          this.loadData();
        });
    },
    showEvent: function ({ nativeEvent, event }) {
      this.editDefaultDeliveryOverlay = true;
      this.selectedDefaultDelivery = event;
      console.log(nativeEvent);
    },
    newSeason: function () {
      this.newSeasonOverlay = true;
    },
    saveNewSeason: function () {
      axios
        .post("/season", {
          typeId: this.newSeasonObject.seasonType.id,
          tomatoPrice: this.newSeasonObject.tomatoPrice,
          start: this.newSeasonObject.start,
          end: this.newSeasonObject.end,
        })
        .then(() => {
        });

      this.newSeasonObject = {
        seasonType: {},
        tomatoPrice: 0,
        start: "",
        end: "",
      };

      this.newSeasonOverlay = false;
    },
    saveNewDefaultDeliver: function () {
      // get selected time from box id
      let [hours, minutes] = this.newDefaultDeliveryObject.startTime.split(":");
      let [year, month, day] =
        this.newDefaultDeliveryObject.startDate.split("-");
      let date = new Date(year, month - 1, day, hours, minutes, 0, 0);
      let dateString = date.toISOString();

      axios
        .post("/delivery-default", {
          growerId: this.newDefaultDeliveryObject.grower.id,
          start: dateString,
          seasonId: this.selectedSeason.id,
        })
        .then(() => {
          this.newDefaultDeliveryOverlay = false;
        });
    },
    selectNewDefaultDeliveryDate: function (event) {
      this.newDefaultDeliveryObject.startDate = new Date(
        event.year,
        event.month - 1,
        event.day,
        0,
        0,
        0,
        0
      )
        .toISOString()
        .split("T")[0];
      this.newDefaultDeliveryOverlay = true;
    },
  },
  computed: {
    authenticated: function () {
      return this.$store.getters["AuthStore/authenticated"];
    },
  },
  watch: {
    selectedSeason: function () {
      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

      this.seasonStart = new Date(this.selectedSeason.start)
        .toISOString()
        .split("T")[0];
      this.defaultEnd = new Date(this.selectedSeason.start)
        .addDays(13)
        .toISOString()
        .split("T")[0];

      this.selectedSeasonEvents = this.selectedSeason.default_deliveries.map(
        (el) => {
          el.start = new Date(el.start + " UTC");
          el.end = new Date(el.end + " UTC");
          el.name = el.grower.code;
          el.timed = true;
          return el;
        }
      );
    },
  },
  mounted: function () {
    this.loadData();
  },
  created: () => {},
  destroyed: () => {},
};
</script>
