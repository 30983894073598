<template>
  <v-container>
    <v-card>
      <v-card-title>Yard Load</v-card-title>
      <v-card-subtitle>This table shows all of the loads that have been scaled in and in the yard.</v-card-subtitle>
      <v-card-text>

        <v-tabs
          v-model="tab"
        >
          <v-tab>Table</v-tab>
          <v-tab>Chart</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="Table"
          >
            <v-card>
              <v-card-text>
                <v-row class="text-center">
          <v-col cols="12">
            <v-simple-table
            fixed-header
              height="500px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <!-- cert data -->
                    <th class="text-left">Delivery</th>
                    <th class="text-left">Certificate</th>
                    <th class="text-left">Stage</th>
                    <th class="text-left">Grower</th>
                    <th class="text-left">Tons</th>
                    <th class="text-left">Variety</th>

                    <!-- Grade info -->
                    <th class="text-left">M.O.T</th>
                    <th class="text-left">Grass Green</th>
                    <th class="text-left">Processing Green</th>
                    <th class="text-left">Processing Breakers</th>
                    <th class="text-left">Other Defects</th>
                    <th class="text-left">Limited Use</th>
                    <th class="text-left">Agtron</th>
                    <th class="text-left">Refractometer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="delivery in calculatedDeliveriesCheckedIn"
                    :key="delivery.id"
                  >
                    <!-- cert data -->
                    <td>{{ delivery.id }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.seasonal_certificate_id : "-" }}</td>
                    <td>
                      <v-chip v-if="delivery.stage == 'Rejected'" color="red">{{ delivery.stage }}</v-chip>
                      <v-chip v-if="delivery.stage != 'Rejected'">{{ delivery.stage }}</v-chip>
                    </td>
                    <td>{{ delivery.grower.code }}</td>
                    <td>{{ delivery.grower.load_size }}</td>
                    <td>{{ delivery.variety ? delivery.variety.code : "-" }}</td>

                    <!-- grade info -->
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_mot : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_grass_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_breakers : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_other : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_limited_use : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.agtron : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.refractometer : "-" }}</td>
                  </tr>

                  <tr
                    v-for="delivery in calculatedDeliveriesGrading"
                    :key="delivery.id"
                  >
                    <!-- cert data -->
                    <td>{{ delivery.id }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.seasonal_certificate_id : "-" }}</td>
                    <td>
                      <v-chip v-if="delivery.stage == 'Rejected'" color="red">{{ delivery.stage }}</v-chip>
                      <v-chip v-if="delivery.stage != 'Rejected'">{{ delivery.stage }}</v-chip>
                    </td>
                    <td>{{ delivery.grower.code }}</td>
                    <td>{{ delivery.grower.load_size }}</td>
                    <td>{{ delivery.variety ? delivery.variety.code : "-" }}</td>

                    <!-- grade info -->
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_mot : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_grass_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_breakers : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_other : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_limited_use : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.agtron : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.refractometer : "-" }}</td>
                  </tr>

                  <tr
                    v-for="delivery in calculatedDeliveriesGraded"
                    :key="delivery.id"
                  >
                    <!-- cert data -->
                    <td>{{ delivery.id }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.seasonal_certificate_id : "-" }}</td>
                    <td>
                      <v-chip v-if="delivery.stage == 'Rejected'" color="red">{{ delivery.stage }}</v-chip>
                      <v-chip v-if="delivery.stage != 'Rejected'">{{ delivery.stage }}</v-chip>
                    </td>
                    <td>{{ delivery.grower.code }}</td>
                    <td>{{ delivery.grower.load_size }}</td>
                    <td>{{ delivery.variety ? delivery.variety.code : "-" }}</td>

                    <!-- grade info -->
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_mot : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_grass_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_breakers : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_other : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_limited_use : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.agtron : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.refractometer : "-" }}</td>
                  </tr>

                  <tr
                    v-for="delivery in calculatedDeliveriesScaled"
                    :key="delivery.id"
                  >
                    <!-- cert data -->
                    <td>{{ delivery.id }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.seasonal_certificate_id : "-" }}</td>
                    <td>
                      <v-chip v-if="delivery.stage == 'Rejected'" color="red">{{ delivery.stage }}</v-chip>
                      <v-chip v-if="delivery.stage != 'Rejected'">{{ delivery.stage }}</v-chip>
                    </td>
                    <td>{{ delivery.grower.code }}</td>
                    <td>{{ delivery.grower.load_size }}</td>
                    <td>{{ delivery.variety ? delivery.variety.code : "-" }}</td>

                    <!-- grade info -->
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_mot : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_grass_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_breakers : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_other : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_limited_use : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.agtron : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.refractometer : "-" }}</td>
                  </tr>

                  <tr
                    v-for="delivery in calculatedDeliveriesRejected"
                    :key="delivery.id"
                  >
                    <!-- cert data -->
                    <td>{{ delivery.id }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.seasonal_certificate_id : "-" }}</td>
                    <td>
                      <v-chip v-if="delivery.stage == 'Rejected'" color="red">{{ delivery.stage }}</v-chip>
                      <v-chip v-if="delivery.stage != 'Rejected'">{{ delivery.stage }}</v-chip>
                    </td>
                    <td>{{ delivery.grower.code }}</td>
                    <td>{{ delivery.grower.load_size }}</td>
                    <td>{{ delivery.variety ? delivery.variety.code : "-" }}</td>

                    <!-- grade info -->
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_mot : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_grass_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_green : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_processing_breakers : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_other : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.defect_limited_use : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.agtron : "-" }}</td>
                    <td>{{ delivery.active_grade ? delivery.active_grade.refractometer : "-" }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            :key="Chart"
          >
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <ApexChart type="donut" :options="{
                      chart: {
                        id: 'YardLoadChart'
                      },
                      labels: ['Rejected', 'Scaling', 'Graded', 'Checked In']
                    }" :series="[chart.RejectedTons, chart.ScalingTons, chart.GradedTons, chart.CheckedInTons]">
                    </ApexChart>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axios from '../../service/axios.config';

  export default {
    name: 'YardLoad',
    components: {},
    data: function() {
      return {
        tab: null,
        currentSeason: {},
        deliveries: [],
        calculatedDeliveries: [],
        calculatedDeliveriesRejected: [],
        calculatedDeliveriesScaled: [],
        calculatedDeliveriesScaling: [],
        calculatedDeliveriesGraded: [],
        calculatedDeliveriesGrading: [],
        chart: {
          RejectedTons: 0,
          ScalingTons: 0,
          GradedTons: 0,
          CheckedInTons: 0,
          GradingTons: 0,
        }
      }
    },
    methods: {
      loadData: async function(){
        const seasonRes = await axios.get("/season", {
          params: {
            active: true,
          }
        });

        // Get season and grower data
        this.currentSeason = seasonRes.data.seasons[0];

        const deliveries = await axios.get("/dashboard/yardload");
        this.deliveries = deliveries.data.deliveries;

        this.deliveries.forEach((delivery) => {
          this.calculateDelivery(delivery);
        });

        // console.log(this.calculatedDeliveries);
      },
      calculateDelivery: function(delivery){
        let newCalculatedDelivery = delivery;

        // set active grade
        newCalculatedDelivery.active_grade = null;
        if(newCalculatedDelivery.grades.length > 0){
          newCalculatedDelivery.active_grade = newCalculatedDelivery.grades[0];
          newCalculatedDelivery.grades.forEach((grade) => {
            if(grade.id > newCalculatedDelivery.active_grade.id){
              newCalculatedDelivery.active_grade = grade;
            }
          });
        }

        // add delivery status 
        // Current (rejected, scaling, graded, checkedIn)
        // Todo: (Checked in, Graded, Sent to Scale, Scaled in, Late, ***Not checked in in current hour)
        if(delivery.rejected){
          this.chart.RejectedTons += Number(delivery.grower.load_size);
          newCalculatedDelivery.stage = "Rejected";
        } else if(delivery.weight_in){
          this.chart.ScalingTons += Number(delivery.grower.load_size);
          newCalculatedDelivery.stage = "Scaling";
        } else if(delivery.sentToGrade){
          this.chart.GradingTons += Number(delivery.grower.load_size);
          newCalculatedDelivery.stage = "Grading";
        } else if(delivery.graded){
          this.chart.GradedTons += Number(delivery.grower.load_size);
          newCalculatedDelivery.stage = "Graded";
        } else if(delivery.arrived){
          this.chart.CheckedInTons += Number(delivery.grower.load_size);
          newCalculatedDelivery.stage = "Checked In";
        }
        
        this.calculatedDeliveries = [...this.calculatedDeliveries, newCalculatedDelivery]

        this.calculatedDeliveriesRejected = this.calculatedDeliveries.filter((delivery) => {
          if(delivery.stage == "Rejected"){
            return true;
          }
          return false;
        });

        this.calculatedDeliveriesScaling = this.calculatedDeliveries.filter((delivery) => {
          if(delivery.stage == "Scaling"){
            return true;
          }
          return false;
        });

        this.calculatedDeliveriesGraded = this.calculatedDeliveries.filter((delivery) => {
          if(delivery.stage == "Graded"){
            return true;
          }
          return false;
        });

        this.calculatedDeliveriesGrading = this.calculatedDeliveries.filter((delivery) => {
          if(delivery.stage == "Grading"){
            return true;
          }
          return false;
        });

        this.calculatedDeliveriesCheckedIn = this.calculatedDeliveries.filter((delivery) => {
          if(delivery.stage == "Checked In"){
            return true;
          }
          return false;
        });
      }
    },
    watch: {
    },
    mounted: function(){
      this.loadData();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
