
const floor = (number, significance = 1, mode = 0) => {
  if (significance === 0) {
      throw new Error('Significance cannot be zero.');
  }
  
  significance = Math.abs(significance); // Ensure significance is positive
  
  // Calculate the base floor value
  let floorValue = Math.floor(Math.abs(number) / significance) * significance;

  // Adjust based on the sign of the number
  if (number < 0 && (mode === 0 || significance === 1)) {
      floorValue = -floorValue;
  } else if (number < 0 && mode !== 0) {
      floorValue = -Math.ceil(Math.abs(number) / significance) * significance;
  }
  
  return floorValue;
}

export default {
  floor
}