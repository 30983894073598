import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/apexcharts';
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue2Filters from 'vue2-filters'
import VueApexCharts from 'vue-apexcharts'


Date.prototype.addDays = function (days) {
	let date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: [
		'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
		'https://unpkg.com/kidlat-css/css/kidlat.css',
	],
};

Vue.use(Vue2Filters)
Vue.use(VueHtmlToPaper, options);
Vue.use(VueApexCharts);

Vue.component('ApexChart', VueApexCharts);
