const grade = (tearValues, cert) => {
	const gradeSingleValue = (
		category,
		totalSampleWeight,
		categoryWeight,
	) => {
		// Generate grade calculation object
		let gradeCalculation = {
			lbs: categoryWeight,
			percentage: Math.round((categoryWeight / totalSampleWeight) * 100),
			reject: false,
			cullTear: 0,
		};

		// Check if category is rejectable
		if (gradeCalculation.percentage > tearValues[category].rejection) {
			gradeCalculation.reject = true;
		}

		let remainderPercentage = gradeCalculation.percentage;

		tearValues[category].compounding.forEach((tearObj) => {
			if (
				gradeCalculation.percentage >= tearObj.from &&
				gradeCalculation.percentage > 0
			) {
				let percentageAllocated =
					tearObj.to - (tearObj.from > 0 ? tearObj.from - 1 : tearObj.from);

				if (gradeCalculation.percentage <= tearObj.to) {
					gradeCalculation.cullTear += remainderPercentage * tearObj.tear;
					remainderPercentage = 0;
				} else {
					gradeCalculation.cullTear += percentageAllocated * tearObj.tear;
					remainderPercentage -= percentageAllocated;
				}
			}
		});

		return gradeCalculation;
	};

	return {
		mot: gradeSingleValue('mot', cert.total_sample_weight, cert.defect_mot, 3),
		grassGreen: gradeSingleValue(
			'grassGreen',
			cert.total_sample_weight,
			cert.defect_grass_green,
			3
		),
		processingGreen: gradeSingleValue(
			'processingGreen',
			cert.total_sample_weight,
			cert.defect_processing_green,
			99
		),
		processingBreakers: gradeSingleValue(
			'processingBreakers',
			cert.total_sample_weight,
			cert.defect_processing_breakers,
			99
		),
		otherDefects: gradeSingleValue(
			'otherDefects',
			cert.total_sample_weight,
			cert.defect_other,
			7
		),
		limitedUse: gradeSingleValue(
			'limitedUse',
			cert.total_sample_weight,
			cert.defect_limited_use,
			20
		),

		agtron: cert.agtron,
		refractometer: cert.refractometer,

		totalSampleWeight: cert.total_sample_weight,
	};
};

module.exports = grade;
