<template>
  <v-container>
    <v-card>
      <v-card-title>Raw Tomato</v-card-title>
      <v-card-subtitle>This section will compare the average grading data based on tomato variety over the season.</v-card-subtitle>
      <v-card-text>

        <v-checkbox v-model="includeTransfer" label="Include transfer loads"></v-checkbox>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Select start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Select end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" v-on:click="calculate()">Calculate</v-btn>
          </v-col>
        </v-row>

        <v-tabs
          v-model="tab"
        >
          <v-tab>Table</v-tab>
          <v-tab>Chart</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="Table"
          >
            <v-card>
              <v-card-text>
                <v-row class="text-center">
          <v-col cols="12">
            <!-- <v-simple-table
              fixed-header
              height="500px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Variety
                    </th>
                    <th class="text-left">
                      Tons
                    </th>
                    <th class="text-left"> 
                      Loads
                    </th>
                    <th class="text-left">
                      M.O.T
                    </th>
                    <th class="text-left">
                      Grass Green
                    </th>
                    <th class="text-left">
                      Processing Green
                    </th>
                    <th class="text-left">
                      Processing Breakers
                    </th>
                    <th class="text-left">
                      Other Defects
                    </th>
                    <th class="text-left">
                      Limited Use
                    </th>
                    <th class="text-left">
                      Agtron
                    </th>
                    <th class="text-left">
                      Refractometer
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="grade in varietyGrade"
                    :key="grade.id"
                    :v-if="grade.tons > 0"
                  >
                    <td><b>{{ grade.code }}</b></td>
                    <td>{{ grade.tons | number('0,0.00') }}</td>
                    <td>{{ grade.loads }}</td>
                    <td>{{ (grade.defect.mot.total / grade.defect.mot.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.grass_green.total / grade.defect.grass_green.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.processing_green.total / grade.defect.processing_green.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.processing_breakers.total / grade.defect.processing_breakers.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.other.total / grade.defect.other.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.limited_use.total / grade.defect.limited_use.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.agtron.total / grade.defect.agtron.count) | number('0,0.00')}}</td>
                    <td>{{ (grade.defect.refractometer.total / grade.defect.refractometer.count) | number('0,0.00')}}</td>
                  </tr>

                  <tr>
                    <td><b>TOTAL</b></td>
                    <td>{{ totals.tons | number('0,0.00') }}</td>
                    <td>{{ totals.loads }}</td>
                    <td>{{ (totals.defect.mot.total / totals.defect.mot.count) * 100 | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.grass_green.total / totals.defect.grass_green.count) * 100 | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.processing_green.total / totals.defect.processing_green.count) * 100 | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.processing_breakers.total / totals.defect.processing_breakers.count) * 100 | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.other.total / totals.defect.other.count) * 100 | number('0,0.00')}}%</td>
                    <td>{{ (totals.defect.limited_use.total / totals.defect.limited_use.count) * 100 | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.agtron.total / totals.defect.agtron.count) | number('0,0.00') }}</td>
                    <td>{{ (totals.defect.refractometer.total / totals.defect.refractometer.count) | number('0,0.00') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->

            <v-data-table
              :headers="generateTableData().headers"
              :items="generateTableData().items"
              disable-pagination
              hide-default-footer
              fixed-header
              height="500px"
            ></v-data-table>

          </v-col>
        </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            :key="Chart"
          >
            <v-card>
              <v-card-text>
                <ApexChart type="bar" :options="{
                  chart: {
                    id: 'RawTomatoChart'
                  },
                }" :series="[{
                  name: 'Defect Percentage',
                  data: this.chartData
                }]">
                </ApexChart>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axios from '../../service/axios.config';
import numeral from 'numeral';

  export default {
    name: 'RawTomato',
    components: {},
    data: function() {
      return {
        chartData: [],
        tab: null,
        startMenu: false,
        startDate: null,
        endMenu: false,
        endDate: null,
        additionalDays: 1,
        deliveries: null,
        varietyGrade: [],
        includeTransfer: false,
        varieties: [],
        totals: {
          loads: 0,
          tons: 0,
          defect: {
            mot: {
              total: 0,
              count: 0
            },
            grass_green: {
              total: 0,
              count: 0
            },
            processing_green: {
              total: 0,
              count: 0
            },
            processing_breakers: {
              total: 0,
              count: 0
            },
            other: {
              total: 0,
              count: 0
            },
            limited_use: {
              total: 0,
              count: 0
            },
            agtron: {
              total: 0,
              count: 0
            },
            refractometer: {
              total: 0,
              count: 0
            },
          }
        },
      }
    },
    methods: {
      loadData: function(){

        let now = new Date;
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);

        // Format start date
        this.startDate = now.toISOString().substr(0, 10);
        this.endDate = now.addDays(10).toISOString().substr(0, 10);

        axios.get("/season", {
          params: {
            active: true,
          },
        }).then((res) => {
          this.varieties = res.data.seasons[0].varieties;
          this.calculate();
        });

      },
      calculate: function(){
        let [yearS, monthS, dayS] = this.startDate.split('-');
        let queryStartDate = new Date(yearS, monthS-1, dayS, 0, 0, 0, 0);

        let [yearE, monthE, dayE] = this.endDate.split('-');
        let queryEndDate = new Date(yearE, monthE-1, dayE, 0, 0, 0, 0);
          
        let Difference_In_Days = (queryEndDate.getTime() - queryStartDate.getTime()) / (1000 * 3600 * 24);

        // Query all deliveries
        axios.get('/delivery', {
          params: {
            scale_date: queryStartDate,
            scale_addDays: Difference_In_Days,
          }
        }).then((response) => {

          // console.log('response:', response.data);

          // Format deliveries
          this.deliveries = response.data.deliveries.map((el) => {
            el.start = new Date(el.start + ' UTC');
            el.end = new Date(el.end + ' UTC');
            el.name = el.grower.code;
            el.timed = true;
            return el;
          });

          // console.log(this.deliveries);

          let varietyGrade = [];

          this.varieties.forEach((variety) => {
            varietyGrade[variety.id] = {
              id: variety.id,
              code: variety.code,
              loads: 0,
              tons: 0,
              defect: {
                mot: {
                  total: 0,
                  count: 0
                },
                grass_green: {
                  total: 0,
                  count: 0
                },
                processing_green: {
                  total: 0,
                  count: 0
                },
                processing_breakers: {
                  total: 0,
                  count: 0
                },
                other: {
                  total: 0,
                  count: 0
                },
                limited_use: {
                  total: 0,
                  count: 0
                },
                agtron: {
                  total: 0,
                  count: 0
                },
                refractometer: {
                  total: 0,
                  count: 0
                },
              }
            }
          });

          // console.log('varietyGrade:', varietyGrade);

          // Calculate
          this.deliveries.forEach((delivery) => {
            let validGrade = delivery.grades[0];
            if(validGrade && delivery.variety_id != null && varietyGrade[delivery.variety_id]){

              varietyGrade[delivery.variety_id].loads += 1;

              varietyGrade[delivery.variety_id].tons += Number(Number(delivery.weight_in) - Number(delivery.weight_out)) / 2000;

              varietyGrade[delivery.variety_id].defect.mot.total += (Number(validGrade.defect_mot) / Number(validGrade.total_sample_weight));
              varietyGrade[delivery.variety_id].defect.mot.count += 1;

              varietyGrade[delivery.variety_id].defect.grass_green.total += (Number(validGrade.defect_grass_green) / Number(validGrade.total_sample_weight));
              varietyGrade[delivery.variety_id].defect.grass_green.count += 1;

              varietyGrade[delivery.variety_id].defect.processing_green.total += (Number(validGrade.defect_processing_green) / Number(validGrade.total_sample_weight));
              varietyGrade[delivery.variety_id].defect.processing_green.count += 1;

              varietyGrade[delivery.variety_id].defect.processing_breakers.total += (Number(validGrade.defect_processing_breakers) / Number(validGrade.total_sample_weight));
              varietyGrade[delivery.variety_id].defect.processing_breakers.count += 1;

              varietyGrade[delivery.variety_id].defect.other.total += (Number(validGrade.defect_other) / Number(validGrade.total_sample_weight));
              varietyGrade[delivery.variety_id].defect.other.count += 1;

              varietyGrade[delivery.variety_id].defect.limited_use.total += (Number(validGrade.defect_limited_use) / Number(validGrade.total_sample_weight));
              varietyGrade[delivery.variety_id].defect.limited_use.count += 1;

              varietyGrade[delivery.variety_id].defect.agtron.total += Number(validGrade.agtron);
              varietyGrade[delivery.variety_id].defect.agtron.count += 1;

              varietyGrade[delivery.variety_id].defect.refractometer.total += Number(validGrade.refractometer);
              varietyGrade[delivery.variety_id].defect.refractometer.count += 1;
            }
          });

          // CalculateTotals

          let totals = {
            loads: 0,
            tons: 0,
            defect: {
              mot: {
                total: 0,
                count: 0
              },
              grass_green: {
                total: 0,
                count: 0
              },
              processing_green: {
                total: 0,
                count: 0
              },
              processing_breakers: {
                total: 0,
                count: 0
              },
              other: {
                total: 0,
                count: 0
              },
              limited_use: {
                total: 0,
                count: 0
              },
              agtron: {
                total: 0,
                count: 0
              },
              refractometer: {
                total: 0,
                count: 0
              },
            }
          }

          for (const key in varietyGrade) {
            const gradeCalc = varietyGrade[key];

            totals.tons += Number(gradeCalc.tons);
            totals.loads += Number(gradeCalc.loads);

            totals.defect.mot.total += Number(gradeCalc.defect.mot.total);
            totals.defect.mot.count += Number(gradeCalc.defect.mot.count);

            totals.defect.grass_green.total += Number(gradeCalc.defect.grass_green.total);
            totals.defect.grass_green.count += Number(gradeCalc.defect.grass_green.count);

            totals.defect.processing_green.total += Number(gradeCalc.defect.processing_green.total);
            totals.defect.processing_green.count += Number(gradeCalc.defect.processing_green.count);

            totals.defect.processing_breakers.total += Number(gradeCalc.defect.processing_breakers.total);
            totals.defect.processing_breakers.count += Number(gradeCalc.defect.processing_breakers.count);

            totals.defect.other.total += Number(gradeCalc.defect.other.total);
            totals.defect.other.count += Number(gradeCalc.defect.other.count);

            totals.defect.limited_use.total += Number(gradeCalc.defect.limited_use.total);
            totals.defect.limited_use.count += Number(gradeCalc.defect.limited_use.count);

            totals.defect.agtron.total += Number(gradeCalc.defect.agtron.total);
            totals.defect.agtron.count += Number(gradeCalc.defect.agtron.count);

            totals.defect.refractometer.total += Number(gradeCalc.defect.refractometer.total);
            totals.defect.refractometer.count += Number(gradeCalc.defect.refractometer.count);

            // console.log(gradeCalc);
          }

          // console.log(totals);

          this.totals = totals;
          this.varietyGrade = Object.values(varietyGrade).filter(item => {
            return item.loads > 0;
          });

          let tempChartData = [];
          Object.values(varietyGrade).forEach((varietyGradeItem) => {

            // console.log('varietyGradeItem:', varietyGradeItem);

            if(varietyGradeItem.loads > 0){
              let totalDefectPercentageMOT = (varietyGradeItem.defect.mot.total / varietyGradeItem.defect.mot.count) * 100;
              let totalDefectPercentageGG = (varietyGradeItem.defect.grass_green.total / varietyGradeItem.defect.grass_green.count) * 100;
              let totalDefectPercentagePG = (varietyGradeItem.defect.processing_green.total / varietyGradeItem.defect.processing_green.count) * 100;
              let totalDefectPercentagePB = (varietyGradeItem.defect.processing_breakers.total / varietyGradeItem.defect.processing_breakers.count) * 100;
              let totalDefectPercentageOD = (varietyGradeItem.defect.other.total / varietyGradeItem.defect.other.count) * 100;
              let totalDefectPercentageLU = (varietyGradeItem.defect.limited_use.total / varietyGradeItem.defect.limited_use.count) * 100;

              tempChartData.push({
                x: varietyGradeItem.code,
                y: (totalDefectPercentageMOT + totalDefectPercentageGG + totalDefectPercentagePG + totalDefectPercentagePB + totalDefectPercentageOD + totalDefectPercentageLU).toFixed(2)
              });
            }
          });

          this.chartData = tempChartData;

        });
      },
      generateTableData: function(){
        let headers = [{
          text: "Variety",
          value: "variety"
        },{
          text: "Tons",
          value: "tons"
        },{
          text: "Loads",
          value: "loads"
        },{
          text: "M.O.T",
          value: "mot"
        },{
          text: "Grass Green",
          value: "grassGreen"
        },{
          text: "Processing Green",
          value: "processingGreen"
        },{
          text: "Processing Breakers",
          value: "processingBreakers"
        },{
          text: "Other Defects",
          value: "otherDefects"
        },{
          text: "Limited Use",
          value: "limitedUse"
        },{
          text: "Agtron",
          value: "agtron"
        },{
          text: "Refractometer",
          value: "refractometer"
        },];

        let items = [] // {with headers}

        for (let grade of this.varietyGrade) {
          if(grade.tons > 0){
            items = [...items, {
              variety: grade.code,
              tons: numeral(grade.tons).format('0,0.00'),
              loads: grade.loads,
              mot: numeral((grade.defect.mot.total / grade.defect.mot.count) * 100).format('0,0.00'),
              grassGreen: numeral((grade.defect.grass_green.total / grade.defect.grass_green.count) * 100).format('0,0.00'),
              processingGreen: numeral((grade.defect.processing_green.total / grade.defect.processing_green.count) * 100).format('0,0.00'),
              processingBreakers: numeral((grade.defect.processing_breakers.total / grade.defect.processing_breakers.count) * 100).format('0,0.00'),
              otherDefects: numeral((grade.defect.other.total / grade.defect.other.count) * 100).format('0,0.00'),
              limitedUse: numeral((grade.defect.limited_use.total / grade.defect.limited_use.count) * 100 ).format('0,0.00'),
              agtron: numeral((grade.defect.agtron.total / grade.defect.agtron.count)).format('0,0.00'),
              refractometer: numeral((grade.defect.refractometer.total / grade.defect.refractometer.count)).format('0,0.00'),
            }]
          }
        }

        return {
          headers: headers,
          items: items,
          totals: [
            {
              variety: `TOTAL`,
              tons: numeral(this.totals.tons).format('0,0.00'),
              loads: this.totals.loads,
              mot: numeral((this.totals.defect.mot.total / this.totals.defect.mot.count) * 100).format('0,0.00'),
              grassGreen: numeral((this.totals.defect.grass_green.total / this.totals.defect.grass_green.count) * 100).format('0,0.00'),
              processingGreen: numeral((this.totals.defect.processing_green.total / this.totals.defect.processing_green.count) * 100).format('0,0.00'),
              processingBreakers: numeral((this.totals.defect.processing_breakers.total / this.totals.defect.processing_breakers.count) * 100 ).format('0,0.00'),
              otherDefects: numeral((this.totals.defect.other.total / this.totals.defect.other.count) * 100).format('0,0.00'),
              limitedUse: numeral((this.totals.defect.limited_use.total / this.totals.defect.limited_use.count) * 100 ).format('0,0.00'),
              agtron: numeral((this.totals.defect.agtron.total / this.totals.defect.agtron.count)).format('0,0.00'),
              refractometer: numeral((this.totals.defect.refractometer.total / this.totals.defect.refractometer.count)).format('0,0.00'),
            }
          ]
        }
      }
    },
    watch: {
      // startDate: function(){
      //   this.calculate();
      // },
      // endDate: function(){
      //   this.calculate();
      // }
    },
    mounted: function(){

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      this.loadData();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
