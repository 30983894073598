<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Tomato Delivered 
          </v-col>
          <v-col align-self="end">
            <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle>
        <v-row>
          <v-col>
            <v-btn
              v-on:click="generateExport()"
            >
              Generate
            </v-btn>

            <a v-if="exportDownloadLink" :href="exportDownloadLink" target="_blank" download="tomato_delivered_export.csv">Download Export</a>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        
        <v-select
          v-model="selectedGrower"
          v-on:change="loadData()"
          :items="growers"
          item-text="display-text"
          label="Select Grower"
          return-object
          solo
        ></v-select>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Select start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Select end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-checkbox v-model="includeTransfer" label="Include transfer loads"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" v-on:click="search()">Search</v-btn>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <b>Contracted Tons:</b> {{selectedGrower.grower_seasonal_data[0].contracted_tons | number('0,0.00')}}
            <b>Delivered Tons:</b> {{ (netDelivered / 2000) | number('0,0.00') }}
            <b>Delivered Percentage:</b> {{ ((netDelivered / 2000) / selectedGrower.grower_seasonal_data[0].contracted_tons) * 100 | number('0,0.00')}}%
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">

            <v-data-table
              :headers="generateTableData().headers"
              :items="generateTableData().items"
              disable-pagination
              hide-default-footer
              fixed-header
              height="500px"
            ></v-data-table>

            <br>

            <v-data-table
              :headers="generateTableData().headers"
              :items="generateTableData().totals"
              disable-pagination
              hide-default-footer
              fixed-header
            ></v-data-table>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import axios from '../../service/axios.config';
  import Papa from 'papaparse';
  import numeral from 'numeral';
  import decimalShiftRound from '@/util/decimalShiftRound';
  import {weightedAverage} from '@/util/weightedAverage';
  import excell from '@/util/excell';

  export default {
    name: 'TomatoDeliveredV2',
    components: {},
    data: function() {
      return {
        growers: [],
        startDate: null,
        startMenu: false,
        endDate: null,
        endMenu: false,
        includeTransfers: false,
        deliveries: [],
        season: {},
        selectedGrower: {},
        loading: false,
        includeTransfer: false,
        exportDownloadLink: null,
        grossDelivered: 0,
        netDelivered: 0,
        value: 0,
        currentDeliveredTons: 0,
        calcTotAvg: {
          agtron: 0,
          refractometer: 0,
          defect_mot: 0,
          defect_grass_green: 0,
          defect_processing_green: 0,
          defect_processing_breakers: 0,
          defect_other: 0,
          defect_limited_use: 0,
          cleanPerc: 0,
          total_cull: 0,
          gross_weight: 0,
          net_delivered: 0,
          load_value: 0,
        },
        totals: [],
        totalsWeights: []
      }
    },
    methods: {
      search: function(){
        this.loadData();
      },
      loadData: function(){
        this.loading = true;
        this.exportDownloadLink = null;
        this.deliveries = [];
        this.currentDeliveredTons = 0;
        this.netDelivered = 0;

        // Format date string
        let [yearS, monthS, dayS] = this.startDate.split('-');
        let queryStartDate = new Date(yearS, monthS-1, dayS, 0, 0, 0, 0);

        let [yearE, monthE, dayE] = this.endDate.split('-');
        let queryEndDate = new Date(yearE, monthE-1, dayE, 0, 0, 0, 0);
          
        let Difference_In_Days = (queryEndDate.getTime() - queryStartDate.getTime()) / (1000 * 3600 * 24);

        // Query
        axios.get("/delivery", {
          params: {
            growerId: this.selectedGrower.id,
            scaled: true,
            date: queryStartDate,
            addDays: Difference_In_Days
          },
        }).then((res) => {
          this.deliveries = res.data.deliveries.map((delivery) => {
            delivery.grades[0].clean_lbs = Number(delivery.grades[0].calculated_grade) - Number(delivery.grades[0].defect_grass_green) - Number(delivery.grades[0].defect_limited_use) - Number(delivery.grades[0].defect_mot) - Number(delivery.grades[0].defect_other) - Number(delivery.grades[0].defect_processing_breakers) - Number(delivery.grades[0].defect_processing_green);
            delivery.grades[0].total_cull = Number(delivery.grades[0].calculated_grade.grassGreen.cullTear) + Number(delivery.grades[0].calculated_grade.limitedUse.cullTear) + Number(delivery.grades[0].calculated_grade.mot.cullTear) + Number(delivery.grades[0].calculated_grade.otherDefects.cullTear) + Number(delivery.grades[0].calculated_grade.processingBreakers.cullTear) + Number(delivery.grades[0].calculated_grade.processingGreen.cullTear);
            delivery.net_delivered = Math.round(((Number(delivery.weight_in) - Number(delivery.weight_out)) - (((Number(delivery.weight_in) - Number(delivery.weight_out)) / 100) * Number(delivery.grades[0].total_cull))));
            delivery.load_value = ((delivery.net_delivered / 2000) * Number(this.season.tomato_price)) / 100;
            delivery.cleanPerc = 100 - (Number(delivery.grades[0].calculated_grade.mot.percentage) + Number(delivery.grades[0].calculated_grade.grassGreen.percentage) + Number(delivery.grades[0].calculated_grade.processingGreen.percentage) + Number(delivery.grades[0].calculated_grade.processingBreakers.percentage) + Number(delivery.grades[0].calculated_grade.otherDefects.percentage) + Number(delivery.grades[0].calculated_grade.limitedUse.percentage))
            return delivery;
          }).sort((a,b) => {
            return new Date(a.scaled_at) - new Date (b.scaled_at);
          });

          console.log(this.deliveries);

          const totals = {
            agtron: {
              total: 0,
              values: [],
            },
            refractometer: {
              total: 0,
              values: [],
            },
            defect_mot: {
              total: 0,
              values: [],
            },
            defect_grass_green: {
              total: 0,
              values: [],
            },
            defect_processing_green: {
              total: 0,
              values: [],
            },
            defect_processing_breakers: {
              total: 0,
              values: [],
            },
            defect_other: {
              total: 0,
              values: [],
            },
            defect_limited_use: {
              total: 0,
              values: [],
            },
            cleanPerc: {
              total: 0,
              values: [],
            },
            total_cull: {
              total: 0,
              values: [],
            },
            gross_weight: {
              total: 0,
              values: [],
            },
            net_delivered: {
              total: 0,
              values: [],
            },
            load_value: {
              total: 0,
              values: [],
            },
          }

          const totalsWeights = [];

          for(const delivery of this.deliveries){
            totals.agtron.total += Number(delivery.grades[0].agtron);
            totals.agtron.values.push(Number(delivery.grades[0].agtron));

            totals.refractometer.total += Number(delivery.grades[0].refractometer);
            totals.refractometer.values.push(Number(delivery.grades[0].refractometer));

            totals.defect_mot.total += Number(delivery.grades[0].calculated_grade.mot.percentage);
            totals.defect_mot.values.push(Number(delivery.grades[0].calculated_grade.mot.percentage));

            totals.defect_grass_green.total += Number(delivery.grades[0].calculated_grade.grassGreen.percentage);
            totals.defect_grass_green.values.push(Number(delivery.grades[0].calculated_grade.grassGreen.percentage));

            totals.defect_processing_green.total += Number(delivery.grades[0].calculated_grade.processingGreen.percentage);
            totals.defect_processing_green.values.push(Number(delivery.grades[0].calculated_grade.processingGreen.percentage));

            totals.defect_processing_breakers.total += Number(delivery.grades[0].calculated_grade.processingBreakers.percentage);
            totals.defect_processing_breakers.values.push(Number(delivery.grades[0].calculated_grade.processingBreakers.percentage));

            totals.defect_other.total += Number(delivery.grades[0].calculated_grade.otherDefects.percentage);
            totals.defect_other.values.push(Number(delivery.grades[0].calculated_grade.otherDefects.percentage));

            totals.defect_limited_use.total += Number(delivery.grades[0].calculated_grade.limitedUse.percentage);
            totals.defect_limited_use.values.push(Number(delivery.grades[0].calculated_grade.limitedUse.percentage));

            totals.cleanPerc.total += Number(decimalShiftRound(delivery.cleanPerc));
            totals.cleanPerc.values.push(Number(decimalShiftRound(delivery.cleanPerc)));

            totals.total_cull.total += Number(delivery.grades[0].total_cull);
            totals.total_cull.values.push(Number(delivery.grades[0].total_cull));

            totals.gross_weight.total += Number((Number(delivery.weight_in) - Number(delivery.weight_out)).toFixed(2));
            totals.gross_weight.values.push(Number((Number(delivery.weight_in) - Number(delivery.weight_out)).toFixed(2)));

            totals.net_delivered.total += Number((delivery.net_delivered).toFixed(2));
            totals.net_delivered.values.push(Number((delivery.net_delivered).toFixed(2)));

            totals.load_value.total += Number(delivery.load_value.toFixed(2));
            totals.load_value.values.push(Number(delivery.load_value.toFixed(2)));

            totalsWeights.push(Number((Number(delivery.weight_in) - Number(delivery.weight_out))));
          }

          console.log(totals);

          this.totals = totals;
          this.totalsWeights = totalsWeights;

          this.loading = false;
        });
      },
      generateExport: function(){
        this.loading = true;
        let sheet = [];

        for (const delivery of this.deliveries) {

          const row = {
            certNumber: delivery.grades[0].id,
            gradedAt: new Date(delivery.grades[0].created_at).toLocaleString(),
            weighedAt: new Date(delivery.scaled_at).toLocaleString(),
            trailerNumber: delivery.trailer_number,
            field: delivery.field.name,
            variety: delivery.variety.code,
            price: (Number(this.season.tomato_price) / 100).toFixed(2),

            Agtron: Number(delivery.grades[0].agtron).toFixed(2),
            Refrac: Number(delivery.grades[0].refractometer).toFixed(2),
            Mot: ((Number(delivery.grades[0].defect_mot) / Number(delivery.grades[0].total_sample_weight)) * 100).toFixed(2),
            PrGrn: ((Number(delivery.grades[0].defect_processing_green) / Number(delivery.grades[0].total_sample_weight)) * 100).toFixed(2),
            GrGrn: ((Number(delivery.grades[0].defect_grass_green) / Number(delivery.grades[0].total_sample_weight)) * 100).toFixed(2),
            OtherD: ((Number(delivery.grades[0].defect_other) / Number(delivery.grades[0].total_sample_weight)) * 100).toFixed(2),
            Clean: Number(delivery.grades[0].clean_lbs).toFixed(2),
            CullTare: Number(delivery.grades[0].total_cull).toFixed(2),

            grossDeliveredLBS: (Number(delivery.weight_in) - Number(delivery.weight_out)).toFixed(2),
            netDeliveredLBS: Number(delivery.net_delivered).toFixed(2),

            loadValue: numeral(delivery.load_value).format('0,0.00')
          }

          sheet = [...sheet, row];
        }

        const csv = Papa.unparse(sheet);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        this.exportDownloadLink = url;

        this.loading = false;
      },
      generateTableData: function(){
        let headers = [{
          text: "Cert#",
          value: "certNum"
        },{
          text: "Graded at",
          value: "gradedAt"
        },{
          text: "Scale Out At",
          value: "scaledOutAt"
        },{
          text: "Trailer",
          value: "trailer"
        },{
          text: "Field",
          value: "field"
        },{
          text: "Variety",
          value: "variety"
        },{
          text: "Price",
          value: "price"
        },{
          text: "Agtron",
          value: "agtron"
        },{
          text: "Refractometer",
          value: "refractometer"
        },{
          text: "Mot",
          value: "mot"
        },{
          text: "GrGrn",
          value: "grGrn"
        },{
          text: "PrGrn",
          value: "prGrn"
        },{
          text: "PrBrk",
          value: "prBrk"
        },{
          text: "OtherD",
          value: "otherD"
        },{
          text: "LimUse",
          value: "limUse"
        },{
          text: "Clean",
          value: "clean"
        },{
          text: "Cull Tare",
          value: "cullTare"
        },{
          text: "Gross Delivered LBS",
          value: "grossDeliveredLBS"
        },{
          text: "Net Delivered LBS",
          value: "netDeliveredLBS"
        },{
          text: "Load Value",
          value: "loadValue"
        },];

        let items = []; // {with headers}

        for (let delivery of this.deliveries) {
            items = [...items, {
              certNum: delivery.grades[0].seasonal_certificate_id,
              gradedAt: new Date(delivery.grades[0].created_at).toLocaleString(),
              scaledOutAt: new Date(delivery.scaled_at).toLocaleString(),
              trailer: delivery.trailer_number,
              field: delivery.field.name,
              variety: delivery.variety.code,
              price: "$" + numeral((Number(this.season.tomato_price) / 100)).format('0,0.00'),
              agtron: numeral(delivery.grades[0].agtron).format('0,0.00'),
              refractometer: numeral(delivery.grades[0].refractometer).format('0,0.00'),

              mot: numeral(delivery.grades[0].calculated_grade.mot.percentage).format('0,0') + "%",
              grGrn: numeral(delivery.grades[0].calculated_grade.grassGreen.percentage).format('0,0') + "%",
              prGrn: numeral(delivery.grades[0].calculated_grade.processingGreen.percentage).format('0,0') + "%",
              prBrk: numeral(delivery.grades[0].calculated_grade.processingBreakers.percentage).format('0,0') + "%",
              otherD: numeral(delivery.grades[0].calculated_grade.otherDefects.percentage).format('0,0') + "%",
              limUse: numeral(delivery.grades[0].calculated_grade.limitedUse.percentage).format('0,0') + "%",

              clean: numeral(decimalShiftRound(delivery.cleanPerc)).format('0,0') + "%",
              cullTare: numeral(delivery.grades[0].total_cull).format('0,0') + "%",
              grossDeliveredLBS: numeral((Number(delivery.weight_in) - Number(delivery.weight_out)).toFixed(2)).format('0,0.00'),
              netDeliveredLBS: numeral(delivery.net_delivered).format('0,0.00'),
              loadValue: numeral(delivery.load_value).format('0,0.00'),
            }];
        }

        return {
          headers: headers,
          items: items,
          totals: [
            {
              certNum: `TOTAL`,
              gradedAt: null,
              scaledOutAt: null,
              trailer: null,
              field: null,
              variety: null,

              price: "$" + numeral(weightedAverage(this.totals.agtron.values, this.totalsWeights)).format('0,0.00'),
              agtron: numeral(weightedAverage(this.totals.agtron.values, this.totalsWeights).toFixed(2)).format('0,0.00'),
              refractometer: numeral(weightedAverage(this.totals.refractometer.values, this.totalsWeights).toFixed(2)).format('0,0.00'),
              
              mot: numeral((weightedAverage(this.totals.defect_mot.values, this.totalsWeights)).toFixed(2)).format('0,0.00') + "%",
              grGrn: numeral(weightedAverage(this.totals.defect_grass_green.values, this.totalsWeights).toFixed(2)).format('0,0.00') + "%",
              prGrn: numeral(weightedAverage(this.totals.defect_processing_green.values, this.totalsWeights).toFixed(2)).format('0,0.00') + "%",
              prBrk: numeral(weightedAverage(this.totals.defect_processing_breakers.values, this.totalsWeights).toFixed(2)).format('0,0.00') + "%",
              otherD: numeral(weightedAverage(this.totals.defect_other.values, this.totalsWeights).toFixed(2)).format('0,0.00') + "%",
              limUse: numeral(weightedAverage(this.totals.defect_limited_use.values, this.totalsWeights).toFixed(2)).format('0,0.00') + "%",
              clean: numeral(weightedAverage(this.totals.cleanPerc.values, this.totalsWeights).toFixed(2)).format('0,0.00') + "%",
              cullTare: numeral(excell.floor((weightedAverage(this.totals.total_cull.values, this.totalsWeights)*100), 2)/100).format('0,0.00') + "%",

              grossDeliveredLBS: numeral(Number(this.totals.gross_weight.total).toFixed(2)).format('0,0.00'),
              netDeliveredLBS: numeral(Number(this.totals.net_delivered.total).toFixed(2)).format('0,0.00'),
              loadValue: numeral(Number(this.totals.load_value.total).toFixed(2)).format('0,0.00'),
            }
          ]
        }
      }
    },
    watch: {

    },
    mounted: function(){

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      let now = new Date;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      this.startDate = now.toISOString().substr(0, 10);
      this.startMenu = false;
      this.endDate = now.addDays(7 * 2).toISOString().substr(0, 10);
      this.endMenu = false;

      this.loading = true;

      axios.get("/season", {
        params: {
          active: true,
        },
      }).then((res) => {
        this.season = res.data.seasons[0];

        this.growers = res.data.seasons[0].growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`;
          return grower;
        }).sort((a, b) => {
          return a.code - b.code;
        });

        this.selectedGrower = this.growers[0];

        this.loadData();
      });
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
