<template>
  <div class="settings">

    <h1>System Settings</h1>

    <!-- Location Settings -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Location Settings</v-card-title>
          <v-card-text>Use this section to update the available geographical locations in the system.</v-card-text>
          <v-card-actions>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Edit
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <!-- Country settings card -->
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>Country</v-card-title>
                        <v-card-text>Use this section to edit the available countries in the system.</v-card-text>
                        <v-card-actions>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Edit
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <CountrySettings/>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Province settings card -->
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                          <v-card-title>Province</v-card-title>
                          <v-card-text>Use this section to edit the available provinces in the system.</v-card-text>
                          <v-card-actions>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Edit
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <ProvinceSettings/>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- City settings card -->
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>City</v-card-title>
                        <v-card-text>Use this section to edit the available cities in the system.</v-card-text>
                        <v-card-actions>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Edit
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <CitySettings/>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Region settings card -->
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                          <v-card-title>Region</v-card-title>
                          <v-card-text>Use this section to edit the available regions in the system.</v-card-text>
                          <v-card-actions>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Edit
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <RegionSettings/>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Town settings card -->
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                          <v-card-title>Town</v-card-title>
                          <v-card-text>Use this section to edit the available towns in the system.</v-card-text>
                          <v-card-actions>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Edit
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <TownSettings/>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Variety Settings</v-card-title>
          <v-card-text>Use this section to update the available tomato varieties in the system.</v-card-text>
          <v-card-actions>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Edit
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <VarietySettings/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Waste Types Settings</v-card-title>
          <v-card-text>Use this section to update the available waste types in the system.</v-card-text>
          <v-card-actions>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Edit
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <WasteTypeSettings/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import CountrySettings from '../components/CountrySettings.vue'
import ProvinceSettings from '../components/ProvinceSettings.vue'
import CitySettings from '../components/CitySettings.vue'
import RegionSettings from '../components/RegionSettings.vue'
import TownSettings from '../components/TownSettings.vue'
import VarietySettings from '../components/VarietySettings.vue'
import WasteTypeSettings from '@/components/WasteTypeSettings.vue'

export default {
  name: 'SettingsView',
  components: {
    CountrySettings,
    ProvinceSettings,
    CitySettings,
    RegionSettings,
    TownSettings,
    VarietySettings,
    WasteTypeSettings
  },
  data: function() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: () => {

  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
