const axios = require('axios');
// import Cookies from 'js-cookie';
import store from '../store';
import router from '../router';

let axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosInstance.interceptors.request.use((request) => {
	if (store.getters['AuthStore/authenticated']) {
		request.headers[
			'Authorization'
		] = `Bearer ${store.getters['AuthStore/token']}`;
	}

	return request;
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		if (error.response.status == 401) {
			store.dispatch('AuthStore/logout');
			router.push('/login');
		}

		return Promise.reject(error);
	}
);

export default axiosInstance;
