import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import HomeView from '../views/HomeView.vue';
import GrowerDashboardView from '../views/GrowerDashboardView.vue';
import LoginView from '../views/LoginView.vue';
import DashboardView from '../views/DashboardView.vue';
import DeliveriesView from '../views/DeliveriesView.vue';
import DefaultDeliveriesView from '../views/DefaultDeliveriesView.vue';
import ReceivingView from '../views/ReceivingView.vue';
import GradingView from '../views/GradingView.vue';
import YardManagementView from '../views/YardManagementView.vue';
import ScalingView from '../views/ScalingView.vue';
import SeasonView from '../views/SeasonView.vue';
import GrowerManagementView from '../views/GrowerManagementView.vue';
import UserManagementView from '../views/UserManagementView.vue';
import SettingsView from '../views/SettingsView.vue';
import CertificateFixView from '../views/CertificateFixView.vue';
import TransferLoadViewNew from '../views/TransferLoadViewNew.vue';
import WasteManagement from '../views/WasteManagement.vue';
import AccountingView from '../views/AccountingView.vue';
import ReportingView from '../views/ReportingView.vue'

Vue.use(VueRouter);

const routes = [
  {
		path: '/grower-dashboard',
		name: 'grower-dashboard',
		component: GrowerDashboardView,
	},
	{
		path: '/',
		name: 'home',
		component: HomeView,
	},
  {
		path: '/reporting',
		name: 'reporting',
		component: ReportingView,
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: DashboardView,
	},
	{
		path: '/deliveries',
		name: 'deliveries',
		component: DeliveriesView,
	},
	{
		path: '/default_deliveries',
		name: 'default_deliveries',
		component: DefaultDeliveriesView,
	},
	{
		path: '/receiving',
		name: 'receiving',
		component: ReceivingView,
	},
	{
		path: '/grading',
		name: 'grading',
		component: GradingView,
	},
  {
		path: '/yard_management',
		name: 'yard_management',
		component: YardManagementView,
	},
	{
		path: '/scaling',
		name: 'scaling',
		component: ScalingView,
	},
	{
		path: '/season',
		name: 'season',
		component: SeasonView,
	},
	{
		path: '/certificate_fix',
		name: 'certificate_fix',
		component: CertificateFixView,
	},
	{
		path: '/waste_management',
		name: 'waste_management',
		component: WasteManagement,
	},
	{
		path: '/accounting',
		name: 'accounting',
		component: AccountingView,
	},
	{
		path: '/grower_management',
		name: 'grower_management',
		component: GrowerManagementView,
	},
	{
		path: '/user_management',
		name: 'user_management',
		component: UserManagementView,
	},
	{
		path: '/settings',
		name: 'settings',
		component: SettingsView,
	},
	{
		path: '/transfer_load',
		name: 'transfer_load',
		component: TransferLoadViewNew,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	let authenticated = await store.getters['AuthStore/authenticated'];  

	if (!authenticated && to.name != 'login') {
		next({ name: 'login' });
	} else {
		next();
	}
});

export default router;
