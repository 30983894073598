<template>
  <v-container>

    <v-row>
      <v-col cols="12">
        <v-container>

          <v-card light>

            <v-card-title>Input from scale</v-card-title>

            <v-card-text>
              <p>Select scale to get input from</p>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="getScale(1)">
                Get Scale 1
              </v-btn>
              <v-btn color="primary" text @click="getScale(2)">
                Get Scale 2
              </v-btn>
              <v-btn color="error" text @click="close()">
                close
              </v-btn>
            </v-card-actions>

          </v-card>

        </v-container>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import {HighburyScaleInterface} from '@highburycorp/scalesio';

export default {
  name: "ScaleInput",
  components: {
  },
  data: function () {
    return {
      weight: null,
    };
  },
  methods: {
    loadData: function () {
      
    },
    close: function(){
      this.$emit('close', true);
    },
    getScale: function (scaleNumber) {

      const scaleInterface = new HighburyScaleInterface({
        baudRate: 4800,
        dataBits: 8,
        stopBits: 1,
        parity: 'none',
        verbose: true,
        timeout: 1500
      });

      scaleInterface.open({
        filters: [{
            usbVendorId: 0x0403 // FTDI
        },{
            usbVendorId: 0x067B // Prolific
        },{
            usbVendorId: 0x10C4 // Silicon Labs
        }]
      }).then($this => {
        $this.getWeight({
            protocol: 'awtx', // Scale house 1310 indicator custom protocol
            parameter: scaleNumber // Scale #
        }).then(scale => {
              this.$emit('scaleRead', {
                weight: parseInt(scale.weight),
                scale: scaleNumber
              });
        }).catch(error => {
            console.log('Oooops that did\'nt work, try again or reset the interface to try a different device.');
            console.error(error);
        }).finally(() => {
            // document.querySelector('#get-weight').innerText = 'Get Weight';
            // document.querySelector('#get-weight').dispatchEvent(new Event('ready'));
        });
      }).catch(error => {
          console.error(error);
          // document.querySelector('#get-weight').innerText = 'Get Weight';
          // document.querySelector('#get-weight').dispatchEvent(new Event('ready'));
      });
    }
  },
  watch: {

  },
  mounted: function () {
    this.loadData();
  },
  created: () => {},
  destroyed: () => {},
};
</script>
