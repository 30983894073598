<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-row>
          <v-col cols="10">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDatePicker"
                  label="Selected date for deliveries"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDatePicker"
                @input="dateSelectEvent"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-btn v-if="!importedDeliveriesFound" color="primary" @click="importDefaultPopup = true"
              >Import Default</v-btn
            >
          </v-col>
        </v-row>

        <v-btn color="primary" @click="openBulkActionOverlay()">bulk action ({{ bulkSelectedDates.length }})</v-btn>

        <v-btn @click="bulkSelectClear()">clear selection ({{ bulkSelectedDates.length }})</v-btn>

        <v-card
          class="d-flex pa-2 flex-wrap"
          outlined
          tile
          :key="rerenderCalendar"
        >
          <div v-for="hour in hours" v-bind:key="hour">
            <h3>{{ hour }}:00</h3>
            <h5>{{ hourlyTonnage[hour] }}</h5>

            <table style="width: 20%">
              <tr>
                <th>:00</th>
                <th>:15</th>
                <th>:30</th>
                <th>:45</th>
              </tr>

              <tr class="dateSelectionRow" v-for="i in is" v-bind:key="i">
                <td
                  :class="getClasses(hour, 0, i)"
                  :id="hour + ':0-' + i"
                  v-on:click="clickDate(`${hour}:0-${i}`)"
                >{{ calendar[`${hour}:0-${i}`] ? calendar[`${hour}:0-${i}`].grower.code : "" }}</td>
                <td
                  :class="getClasses(hour, 15, i)"
                  :id="hour + ':15-' + i"
                  v-on:click="clickDate(`${hour}:15-${i}`)"
                >{{ calendar[`${hour}:15-${i}`] ? calendar[`${hour}:15-${i}`].grower.code : "" }}</td>
                <td
                  :class="getClasses(hour, 30, i)"
                  :id="hour + ':30-' + i"
                  v-on:click="clickDate(`${hour}:30-${i}`)"
                >{{ calendar[`${hour}:30-${i}`] ? calendar[`${hour}:30-${i}`].grower.code : "" }}</td>
                <td
                  :class="getClasses(hour, 45, i)"
                  :id="hour + ':45-' + i"
                  v-on:click="clickDate(`${hour}:45-${i}`)"
                >{{ calendar[`${hour}:45-${i}`] ? calendar[`${hour}:45-${i}`].grower.code : "" }}</td>
              </tr>
            </table>
          </div>
        </v-card>

        <v-overlay
          :z-index="11"
          :absolute="true"
          :value="overlay"
          class="d-flex align-start"
        >
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card light>
                  <v-card-title>Manage Delivery</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <p>New Delivery</p>

                        <v-select
                          v-model="newDeliveryGrower"
                          :items="growers"
                          item-text="display-text"
                          label="Select grower for delivery."
                          solo
                          return-object
                        ></v-select>

                        <v-checkbox
                          v-model="newDeliveryIsExtra"
                          label="Extra load"
                        ></v-checkbox>

                        <v-row>
                          <v-col cols="12" class="text-left">
                            <v-btn color="primary" text @click="bulkSaveNewDefaultDelivery()"
                              >Save New Delivery</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-btn
                            color="primary"
                            text
                            @click="bulkActivateDefaultDelivery(selectedDelivery)"
                            >Activate Deliveries</v-btn
                          >
                        </v-row>
                        <v-row>
                          <v-btn
                            color="error"
                            text
                            @click="bulkDeactivateDefaultDelivery(selectedDelivery)"
                            >Cancel Deliveries</v-btn
                          >
                        </v-row>
                        <v-row>
                          <v-btn
                            color="error"
                            text
                            @click="bulkDeleteDefaultDelivery(selectedDelivery)"
                            >Delete Deliveries</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="error" text @click="closeOverlay()">
                      Close
                    </v-btn>
                  </v-card-actions>

                  <!-- <CertificateViewer :delivery="selectedDelivery" /> -->
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-overlay>

        <!-- Import default popup -->
        <v-overlay
          :z-index="11"
          :absolute="true"
          :value="importDefaultPopup"
          class="d-flex align-start"
        >
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card light>
                  <v-card-title>Confirm import Permanent Schedule.</v-card-title>
                  <v-card-text>
                    <p>Do you confirm the import of the Permanent Schedule for:</p>
                    <h3>{{ selectedDatePicker }}</h3>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="importDefault()">
                      Import
                    </v-btn>
                    <v-btn color="error" text @click="importDefaultPopup = false">
                      Close
                    </v-btn>
                  </v-card-actions>

                  <!-- <CertificateViewer :delivery="selectedDelivery" /> -->
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-overlay>

      </v-col>
    </v-row>

    <v-snackbar v-model="previousDayWarning" timeout="2000">
      This is a previous day.
    </v-snackbar>

    <v-snackbar v-model="noSelectionWarning" timeout="2000">
      You have no selected dates.
    </v-snackbar>

  </v-container>
</template>

<script>
import axios from "../service/axios.config";
// import CertificateViewer from "@/components/CertificateViewer.vue";

export default {
  name: "DailyDeliveryCalandar",
  components: {
    // CertificateViewer,
  },
  data: function () {
    return {
      noSelectionWarning: false,
      importedDeliveriesFound: false,
      previousDayWarning: false,
      today: null,
      importDefaultPopup: false,
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      is: [1, 2, 3, 4, 5],
      calendar: {},
      deliveries: [],
      overlay: false,
      selectedDate: "",
      selectedDelivery: false,
      newDeliveryGrower: {},
      growers: [],
      currentSeason: {},
      menu: false,
      selectedDatePicker: "",
      queryDate: "",
      rerenderCalendar: false,
      selectedStatus: false,
      hourlyTonnage: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
      },
      bulkSelectedDates: [],
      newDeliveryIsExtra: true,
    };
  },
  methods: {
    loadData: function () {
      this.noSelectionWarning = false;
      this.bulkSelectedDates = [];
      this.importedDeliveriesFound = false;
      this.previousDayWarning = false;
      this.calendar = new Object();
      this.deliveries = new Array();
      this.selectedDate = "";
      this.selectedDelivery = false;
      this.newDeliveryGrower = {};
      this.growers = [];
      this.selectedStatus = false;

      this.hourlyTonnage = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
      };

      this.rerenderCalendar = !this.rerenderCalendar;
      this.newDeliveryIsExtra = true;

      // let now = this.queryDate;
      // now.setHours(0);
      // now.setMinutes(0);
      // now.setSeconds(0);

      let [year, month, day] = this.selectedDatePicker.split("-");
      let date = new Date(year, month - 1, day, 0, 0, 0, 0);
      let startOfDay = date.toISOString();

      axios
        .get("/delivery", {
          params: {
            date: startOfDay,
          },
        })
        .then((response) => {
          this.deliveries = response.data.deliveries.map((el) => {

            el.start = new Date(el.start + " UTC");
            el.end = new Date(el.end + " UTC");
            el.name = el.grower.code;
            el.timed = true;
            return el;
          });


          this.deliveries.forEach((delivery) => {
            if (!delivery.canceled && delivery.active) {
              let date = new Date(delivery.start);
              this.hourlyTonnage[date.getHours()] += Number(
                delivery.grower.load_size
              );
            }

            if(delivery.default_id !== null){
              this.importedDeliveriesFound = true;
            }
          });

          this.deliveries.forEach((delivery) => {
            let date = new Date(delivery.start);
            if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${1}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${1}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${2}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${2}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${3}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${3}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${4}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${4}`] =
                delivery;
            } else if (
              !this.calendar[`${date.getHours()}:${date.getMinutes()}-${5}`]
            ) {
              this.calendar[`${date.getHours()}:${date.getMinutes()}-${5}`] =
                delivery;
            }
          });
        });
    },
    getClasses: function(hour, minute, i){
      // Active, not active, arrived, canceled, done
      // Change doument queries

      let classString = "";

      if(this.calendar[`${hour}:${minute}`]){
        classString = classString + "selected ";
      }

      if(this.calendar[`${hour}:${minute}-${i}`]){
        console.log(this.calendar[`${hour}:${minute}-${i}`]);
        if (this.calendar[`${hour}:${minute}-${i}`].canceled || !this.calendar[`${hour}:${minute}-${i}`].active) {
          classString = classString + "canceled ";
        } else if (this.calendar[`${hour}:${minute}-${i}`].scaled) {
          classString = classString + "done ";
        } else if (this.calendar[`${hour}:${minute}-${i}`].sentToScale) {
          classString = classString + "scale ";
        }  else if (this.calendar[`${hour}:${minute}-${i}`].graded || this.calendar[`${hour}:${minute}-${i}`].sentToGrade) {
          classString = classString + "grade ";
        } else if (this.calendar[`${hour}:${minute}-${i}`].arrived) {
          classString = classString + "checked_in ";
        } else {
          classString = classString + "delivery ";
        }

        if(this.calendar[`${hour}:${minute}-${i}`].default_id == null && this.calendar[`${hour}:${minute}-${i}`].active){
          classString = classString + "extra ";
        }
      }

      return classString;
    },
    openBulkActionOverlay: function(){
      if(this.bulkSelectedDates.length > 0){
        this.overlay = true;
      } else {
        this.noSelectionWarning = true;
      }
      
    },
    clickDate: function (id) {
      axios.get("/season", {
        params: {
          active: true,
        }
      }).then((response) => {
        this.currentSeason = response.data.seasons[0];
        this.growers = response.data.seasons[0].growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`;
          return grower;
        }).sort((a, b) => {
          return a.code - b.code;
        });
      });

      // this.overlay = true;
      this.selectedDate = id;
      this.selectedDelivery = this.calendar[id];

      // if(this.selectedDelivery){
      //   if (this.selectedDelivery.weight_out) {
      //     this.selectedStatus = {
      //       val: 100,
      //       msg: "Weighed Out",
      //     };
      //   } else if (this.selectedDelivery.weight_in) {
      //     this.selectedStatus = {
      //       val: 75,
      //       msg: "Weighed In",
      //     };
      //   } else if (this.selectedDelivery.grades.length > 0) {
      //     this.selectedStatus = {
      //       val: 50,
      //       msg: "Graded",
      //     };
      //   } else if (this.selectedDelivery.arrived) {
      //     this.selectedStatus = {
      //       val: 25,
      //       msg: "Arrived",
      //     };
      //   } else {
      //     this.selectedStatus = {
      //       val: 0,
      //       msg: "Awaiting Arrival",
      //     };
      //   }
      // }

      // Date warning
      let [hours, minutes] = this.selectedDate.split("-")[0].split(":");
      let [year, month, day] = this.selectedDatePicker.split("-");
      let date = new Date(year, month - 1, day, hours, minutes, 0, 0);
      let dateString = date.toISOString();

      if(dateString < this.today.toISOString()){
        this.previousDayWarning = true;
      }

      this.bulkSelectedDates = this.bulkSelectedDates.filter((bulkSelectedDate) => {
        if(bulkSelectedDate.htmlID != id){
          return bulkSelectedDate;
        }
      });

      this.bulkSelectedDates = [...this.bulkSelectedDates, {
        htmlID: id,
        date: dateString,
        delivery: this.calendar[id]
      }]

      document.getElementById(id).classList.add("bulkSelected");
    },
    bulkSelectClear: function(){
      this.bulkSelectedDates.forEach((bulkSelectedDate) => {
        document.getElementById(bulkSelectedDate.htmlID).classList.remove("bulkSelected");
      });
      this.bulkSelectedDates = [];
    },
    bulkSaveNewDefaultDelivery: async function(){
      for (const bulkSelectDate of this.bulkSelectedDates) {
        await axios.post("/delivery", {
          growerId: this.newDeliveryGrower.id,
          start: bulkSelectDate.date,
          seasonId: this.currentSeason.id,
          extra: this.newDeliveryIsExtra
        });
      }

      this.loadData();
      this.overlay = false;
      this.closeOverlay();
    },
    bulkDeactivateDefaultDelivery: async function(){
      for (const bulkSelectDate of this.bulkSelectedDates) {
        let updateDelivery = bulkSelectDate.delivery;
        updateDelivery.active = false;
        await axios.patch(`/delivery/${updateDelivery.id}`, updateDelivery)
      }

      this.closeOverlay();
      this.loadData();
    },
    bulkActivateDefaultDelivery: async function(){
      for (const bulkSelectDate of this.bulkSelectedDates) {
        let updateDelivery = bulkSelectDate.delivery;
        updateDelivery.active = true;
        await axios.patch(`/delivery/${updateDelivery.id}`, updateDelivery)
      }

      this.closeOverlay();
      this.loadData();
    },  
    bulkDeleteDefaultDelivery: async function(){
      for (const bulkSelectDate of this.bulkSelectedDates) {
        await axios.delete(`/delivery/${bulkSelectDate.delivery.id}`);
      }

      this.loadData();
      this.overlay = false;
      this.closeOverlay();
    },
    closeOverlay: function () {
      this.overlay = false;
      this.selectedDate = "";
      this.selectedDelivery = false;
      this.bulkSelectClear();
    },
    cancelDelivery: function (delivery) {
      let updateDelivery = delivery;
      updateDelivery.active = false;

      axios
        .patch(`/delivery/${updateDelivery.id}`, updateDelivery)
        .then(() => {
          this.loadData();
          this.closeOverlay();
        });
    },
    activateDelivery: function (delivery) {
      let updateDelivery = delivery;
      updateDelivery.active = true;

      axios
        .patch(`/delivery/${updateDelivery.id}`, updateDelivery)
        .then(() => {
          this.loadData();
          this.closeOverlay();
        });
    },
    saveNewDelivery: function () {
      // get selected time from box id
      let [hours, minutes] = this.selectedDate.split("-")[0].split(":");

      let [year, month, day] = this.selectedDatePicker.split("-");
      let date = new Date(year, month - 1, day, hours, minutes, 0, 0);

      let dateString = date.toISOString();

      axios
        .post("/delivery", {
          growerId: this.newDeliveryGrower.id,
          start: dateString,
          seasonId: this.currentSeason.id,
        })
        .then(() => {
          this.loadData();
          this.overlay = false;
        });
    },
    importDefault: function () {
      axios.get("/season").then((response) => {
        this.currentSeason = response.data.seasons.sort((a, b) => {
          return b.id - a.id;
        })[0];

        let [year, month, day] = this.selectedDatePicker.split("-");
        let date = new Date(year, month - 1, day, 0, 0, 0, 0);
        let startOfDay = date.toISOString();

        axios
          .post("/delivery/import", {
            start: startOfDay,
            seasonId: this.currentSeason.id,
          })
          .then(() => {
            this.importDefaultPopup = false;
            this.loadData();
          });
      });
    },
    dateSelectEvent: function(){
      this.menu = false;
      this.loadData();
    }
  },
  watch: {
    // selectedDatePicker: function () {
    //   // let [year, month, day] = this.selectedDatePicker.split('-');
    //   // this.queryDate = new Date(year, month-1, day, 0, 0, 0, 0);

    //   // this.loadData();
    // },
  },
  mounted: function () {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    this.selectedDatePicker = now.toISOString().substr(0, 10);
    let [year, month, day] = this.selectedDatePicker.split("-");
    this.queryDate = new Date(year, month - 1, day, 0, 0, 0, 0);

    this.today = new Date(this.queryDate);

    this.loadData();
  },
  created: () => {},
  destroyed: () => {},
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid gray;
  padding: 2px;
  border-radius: 2px;
}

td:hover {
  cursor: pointer;
  transform: scale(1.1);
  outline: 2px solid gray;
}

.canceled {
  background-color: red;
}

/* .done {
  background-color: LightGreen;
} */

.scale {
  background-color: black;
  color: white;
}

.grade {
  background-color: lightgreen;

}

.checked_in {
  background-color: dodgerblue;
}

.delivery {
  background-color: lightgray;
}

.bulkSelected {
  transform: scale(1.1);
  outline: 2px solid gray;
}

.extra {
  text-decoration: underline;
}

.dateSelectionRow {
  font-size: 0.75em;
}

.dateSelectionRow td {
  height: 28px;
  margin: 0px;
}

.dateSelectionRow td.delivery {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.canceled {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.done {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.arrived {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.active {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.notActive {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.extra {
  padding: 0px;
  margin: 0px;
}

.dateSelectionRow td.extraDeactivated {
  padding: 0px;
  margin: 0px;
}
</style>
