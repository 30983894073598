<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <YardLoad/>
        <TonsToGo/>
        <QualityGrower/>
        <RawTomato/>
        <Certificates/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  // import YardLoad from './growerDashboardTables/YardLoad.vue';
  // import TonsToGo from './growerDashboardTables/TonsToGo.vue';
  // import QualityGrower from './growerDashboardTables/QualityGrower.vue';
  // import RawTomato from './growerDashboardTables/RawTomato.vue';
  import Certificates from './growerDashboardTables/Certificates.vue';

  import YardLoad from './growerDashboardTables/YardLoad-v2.vue';
  import TonsToGo from './growerDashboardTables/TonsToGo-v2.vue';
  import QualityGrower from './growerDashboardTables/QualityGrower-v2.vue';
  import RawTomato from './growerDashboardTables/RawTomato-v2.vue';

  export default {
    name: 'GrowerDashBoard',
    components: {
      YardLoad,
      TonsToGo,
      QualityGrower,
      RawTomato,
      Certificates
    },
    data: function() {
      return {
      }
    },
    methods: {

    },
    watch: {

    },
    mounted: function() {

    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

