<template>
  <div class="TransferLoadView">
    <h1>Transfer Load</h1>
    <p>Enter the load details for HCC loads transferred to an Outside Processor.</p>

    <v-card>
      <v-card-text>

        <v-row>
          <v-col>
            <v-select
              v-model="selectedProcessor"
              :items="processors"
              item-text="display-text"
              label="Select outside processor for delivery."
              outlined
              return-object
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="outsideProcessorCertificate"
              label="Outside Processor Certificate"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-select
              v-model="selectedGrower"
              :items="growers"
              item-text="display-text"
              label="Select grower for delivery."
              outlined
              return-object
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedGrowerField"
              :items="selectedGrowerFields"
              item-text="name"
              label="Select grower field"
              outlined
              return-object
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedVariety"
              :items="varieties"
              item-text="code"
              label="Select tomato variety"
              outlined
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-text-field
              type="number"
              v-model="valuePerTon"
              label="Value / Ton"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="number"
              v-model="netTonsDelivered"
              label="Net Tons Delivered"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="number"
              :disabled="true"
              v-model="loadValueComp"
              label="Load Value"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn block class="mt-5" color="primary" v-on:click="saveNewTransferLoad()">Save</v-btn>

      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-simple-table
          fixed-header
          height="500px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Processor</th>
                <th class="text-left">Processor Cert</th>
                <th class="text-left">Grower</th>
                <th class="text-left">Field</th>
                <th class="text-left">Variety</th>
                <th class="text-left">Value / Ton</th>
                <th class="text-left">Net Tons</th>

                <th class="text-left">Load Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="load in allTransferLoads"
                :key="load.id"
              >
                <td class="text-left">{{ load.processor.code }}</td>
                <td class="text-left">{{ load.processor_certificate }}</td>
                <td class="text-left">{{ load.grower.code }}</td>
                <td class="text-left">{{ load.field.name }}</td>
                <td class="text-left">{{ load.variety.code }}</td>
                <td class="text-left">${{ (load.value / 100) | number('0,0.00') }}</td>
                <td class="text-left">{{ load.tons | number('0,0.00') }}</td>

                <td class="text-left">${{ ((load.value / 100) * load.tons) | number('0,0.00') }}</td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import axios from "../service/axios.config";

export default {
  name: 'TransferLoadView',
  components: {
  },
  data: function() {
    return {
      processors: [],
      selectedProcessor: {},
      outsideProcessorCertificate: '',
      currentSeason: {},
      growers: [],
      selectedGrower: {},
      selectedGrowerField: {},
      selectedVariety: {},
      varieties: [],
      valuePerTon: 0,
      netTonsDelivered: 0,
      loadValue: 0,
      allTransferLoads: []
    }
  },
  methods: {
    loadData: function(){
      axios.get("/season", {
        params: {
          active: true,
        }
      }).then((response) => {
        this.currentSeason = response.data.seasons[0];

        this.varieties = this.currentSeason.varieties;
        this.selectedVariety = this.varieties[0];

        this.growers = response.data.seasons[0].growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`;
          return grower;
        });

        this.selectedGrower = this.growers[0];

        this.selectedGrowerField = this.selectedGrowerFields[0];

        this.processors = this.growers.filter((grower) => {
          return grower.outside_processor;
        });

        this.selectedProcessor = this.processors[0];
      });

      axios.get("/transfer_load").then((response) => {
        this.allTransferLoads = response.data.loads;
      });
    },
    saveNewTransferLoad: function(){
      axios.post("/transfer_load", {
        season_id: this.currentSeason.id,
        processor_id: this.selectedProcessor.id,
        processor_certificate: this.outsideProcessorCertificate,
        grower_id: this.selectedGrower.id,
        field_id: this.selectedGrowerField.id,
        variety_id: this.selectedVariety.id,
        value: ~~(this.valuePerTon * 100),
        tons: this.netTonsDelivered,
      }).then(() => {
      });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    },
    selectedGrowerFields: function(){
      return this.selectedGrower.grower_fields.filter((field) => {
        if(field.season_id == this.currentSeason.id){
          return field;
        }
      });
    },
    loadValueComp: function(){
      return (this.valuePerTon * this.netTonsDelivered).toFixed(2);
    }
  },
  watch: {

  },
  mounted: function (){
    this.loadData();
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
