<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- <TomatoDelivered/> -->
        <TomatoDeliveredV2/>
        <TomatoDeliveredSummary/>
        <ScheduledDelivered/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

// import TomatoDelivered from '@/components/dashboardTables/TomatoDelivered.vue';
import TomatoDeliveredV2 from '@/components/dashboardTables/TomatoDeliveredV2.vue';
import TomatoDeliveredSummary from '@/components/dashboardTables/TomatoDeliveredSummary.vue';
import ScheduledDelivered from '@/components/dashboardTables/ScheduledDelivered.vue';

export default {
  name: 'ReportingView',
  components: {
    // TomatoDelivered,
    TomatoDeliveredV2,
    TomatoDeliveredSummary,
    ScheduledDelivered
  },
  data: function() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: () => {

  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
