<template>
  <div class="Accounting">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Selection</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="selectedGrower"
                      :items="growers"
                      item-text="display-text"
                      return-object
                      label="Select Grower"
                      outlined
                      @change="loadDataReset(selectedGrower)"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="startMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Start date range"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="startMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="endMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="Select end date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="endMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn block color="primary" v-on:click="getDeliveries()">Get Deliveries</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Selected Deliveries</v-card-title>
              <v-card-text>

                <!-- export  -->
                <v-row>
                  <v-col>
                    <v-progress-circular
                      v-if="loadingExport"
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>

                    <v-btn
                      v-on:click="generateExport()"
                    >
                      Generate
                    </v-btn>

                    <a v-if="exportDownloadLink" :href="exportDownloadLink" target="_blank" download="accounting_deliveries.csv">Download Export</a>
                  </v-col>
                </v-row>
        
                <v-progress-circular
                  v-if="loadingDeliveries"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>

                <v-simple-table dense fixed-header height="200px" v-if="!loadingDeliveries">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Date</th>
                        <th class="text-left">Cert #</th>
                        <th class="text-left">Agtron</th>
                        <th class="text-left">%CHG</th>
                        <th class="text-left">Delivered Gross</th>
                        <th class="text-left">Delivered Net</th>
                        <th class="text-left">Value per ton</th>
                        <th class="text-left">Load Value</th>
                        <th class="text-left">Transfer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="delivery in calculatedDeliveries"
                        :key="delivery.id"
                      >
                        <td>{{ delivery.date }}</td>
                        <td>{{ delivery.cert }}</td>
                        <td>{{ delivery.agtron }}</td>
                        <td>{{ delivery.chg.toFixed(2) }}%</td>
                        <td>{{ delivery.gross | number('0,0') }}</td>
                        <td>{{ delivery.net | number('0,0')}}</td>
                        <td>{{ delivery.price | currency() }}</td>
                        <td>{{ (delivery.value/ 100) | currency }}</td>
                        <td>{{ delivery.transfer }}</td>
                      </tr>
                      <tr>
                        <td>TOTAL</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{{ totalGross | number('0,0') }}</td>
                        <td>{{ totalNet | number('0,0') }}</td>
                        <td>-</td>
                        <td>{{ (totalValue/ 100) | currency}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Tons: {{ (totalNet/2000) | number('0,0.00') }}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions></v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <!-- Calc section -->
        <v-card>
          <v-card-text>

            <v-row>
              <v-col cols="3">
                <h3 style="text-align: center;">Deduction & Qty</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">Value</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">Unity</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">Amount</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  :value="(calculationsQty.prd_ded_cr/ 100).toFixed(2)"
                  type="text"
                  label="PRD DEDUCTION CR"
                  disabled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.prd_ded_cr"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.prd_ded_cr/100) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  :value="(calculationsQty.prd_ded_db/ 100).toFixed(2)"
                  type="text"
                  label="PRD DEDUCTION DB"
                  disabled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.prd_ded_db"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.prd_ded_db/100) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  :value="(calculationsQty.mrk_fee / 2000).toFixed(2)"
                  type="text"
                  label="MARKETING/INSPECTION FEES"
                  disabled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.mrk_fee"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$/TON</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.mrk_fee) | currency}}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  :value="(calculationsQty.mrk_hst/2000).toFixed(2)"
                  type="text"
                  label="MARKETING/INSPECTION HST"
                  disabled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.mrk_hst"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$/TON</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.mrk_hst) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.plant"
                  type="text"
                  label="PLANTS - LOCAL HYBRID"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.plant"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$/1000 Plants</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.plant) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.replant"
                  type="text"
                  label="REPLANTS>8% HYBRID"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.replant"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$/1000 Plants</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.replant) | currency}}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  :value="(calculationsQty.plant_hst)"
                  type="text"
                  label="HST ON TOTAL PLANTS"
                  disabled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.plant_hst"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.plant_hst) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.insurance"
                  type="text"
                  label="INSURANCE"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.insurance"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.insurance) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.blend"
                  type="text"
                  label="BLENDING ADJUSTMENT"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.blend"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.blend) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.solid"
                  type="text"
                  label="SOLIDS PREMIUM"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.solid"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.solid) | currency }}</h3>
              </v-col>
            </v-row>

            <!-- Add shunting -->
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.shtprg"
                  type="text"
                  label="SHUNTING PROGRAM"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.shtprg"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.shtprg) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.shtprghst"
                  type="text"
                  label="SHUNTING PROGRAM HST"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.shtprghst"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.shtprghst) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.shtprgrec"
                  type="text"
                  label="SHUNTING PROGRAM RECONCILIATION"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.shtprgrec"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.shtprgrec) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.shtprgrechst"
                  type="text"
                  label="SHUNTING PROGRAM RECONCILIATION HST"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.shtprgrechst"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.shtprgrechst) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsQty.misc"
                  type="text"
                  label="MISC"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="calculationsDisplay.misc"
                  type="text"
                  prefix="$"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">$</h3>
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (calculationsAmt.misc) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-btn
                  color="primary"
                  @click="updateValues()"
                >
                  Update Values
                </v-btn>
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col cols="3">
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <h3>Total Deductions</h3>
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ (this.totalDeductions) | currency }}</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <h3>Amount Payable</h3>
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col cols="3">
                <h3 style="text-align: center;">{{ ((totalValue/ 100) - this.totalDeductions) | currency }}</h3>
              </v-col>
            </v-row>

            <v-card-actions class="pt-10">
              <v-row>
                <v-col cols="9">
                  <v-btn block color="primary" v-on:click="calculate()">Calculate</v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn block color="primary" v-on:click="accountingViewerOpen = true">Print</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-progress-circular
                    v-if="loadingCalculationExport"
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>

                  <v-btn
                    v-on:click="generateExportCalculation()"
                  >
                    Generate
                  </v-btn>

                  <a v-if="exportCalculationDownloadLink" :href="exportCalculationDownloadLink" target="_blank" download="accounting_calculation_deliveries.csv">Download Export</a>
                </v-col>
              </v-row>

            </v-card-actions>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="accountingViewerOpen"
      width="100%"
      
    >
      <AccountingViewer 
        :calculatedDeliveries="calculatedDeliveries"
        :calculationsQty="calculationsQty"
        :calculationsDisplay="calculationsDisplay"
        :calculationsAmt="calculationsAmt"
        :startDate="new Date(startDate)"
        :endDate="new Date(endDate)"
        :selectedGrower="selectedGrower"
        :totalGross="{value: totalGross}"
        :totalNet="{value: totalNet}"
        :totalValue="{value: this.totalValue}"
        :totalDeductions="{value: totalDeductions}"
      />
      <v-btn color="error" v-on:click="accountingViewerOpen = false">Close</v-btn>
    </v-overlay>

  </div>
</template>

<script>

import AccountingViewer from '@/components/AccountingViewer.vue';

import axios from '../service/axios.config';
import Papa from 'papaparse';
import numeral from 'numeral';

export default {
  name: 'AccountingView',
  components: {
    AccountingViewer
  },
  data: function() {
    return {
      loadingDeliveries: false,
      growers: [],
      selectedGrower: {},
      startDate: null,
      startMenu: false,
      endDate: null,
      endMenu: false,
      deliveries: [],
      calculationsQty: {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      },
      calculationsAmt: {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      },
      calculationsValue: {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      },
      calculationsDisplay: {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      },
      calculatedDeliveries: [],
      totalGross: 0,
      totalNet: 0,
      totalValue: 0,
      totalDeductions: 0,
      amountPayable: 0,
      currentSeason: null,
      accountingViewerOpen: false,
      loadingExport: false,
      exportDownloadLink: null,
      loadingCalculationExport: false,
      exportCalculationDownloadLink: null
    }
  },
  methods: {
    loadDataReset: function(selectedGrowerRefresh = null){

      let now = new Date;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      // Format start date
      this.startDate = now.toISOString().substr(0, 10);
      this.endDate = now.addDays(10).toISOString().substr(0, 10);

      this.growers = [];
      this.selectedGrower = {};
      this.startDate = now.toISOString().substr(0, 10);
      this.startMenu = false;
      this.endDate = now.addDays(7 * 2).toISOString().substr(0, 10);
      this.endMenu = false;
      this.deliveries = [];
      this.calculations = {};
      this.calculatedDeliveries = [];
      this.totalGross = 0;
      this.totalNet = 0;
      this.totalValue = 0;
      this.currentSeason = null;
      this.accountingViewerOpen = false;
      this.calculationsQty = {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      };
      this.calculationsAmt = {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      };
      this.calculationsValue = {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      };
      this.calculationsDisplay = {
        prd_ded_cr: 0,
        prd_ded_db: 0,
        mrk_fee: 0,
        mrk_hst: 0,
        plant: 0,
        replant: 0,
        plant_hst: 0,
        insurance: 0,
        blend: 0,
        solid: 0,
        shtprg: 0,
        shtprghst: 0,
        shtprgrec: 0,
        shtprgrechst: 0,
        misc: 0,
      };

      // axios.get(`/grower`).then((response) => {
      //   this.selectedGrower = response.data.growers[0];
      //   this.growers = response.data.growers;

      //   this.getDeliveries();
      // });

      axios.get('/season', {
        params: {
          active: true,
        }
      }).then((response) => {

        this.currentSeason = response.data.seasons[0];
        this.calculationsValue = response.data.seasons[0].accounting_data;

        for (let key in this.calculationsValue) {
          this.calculationsDisplay[key] = this.calculationsValue[key] / 100;
        }

        this.growers = this.currentSeason.growers.map((grower) => {
          grower["display-text"] = `${grower.code} - ${grower.name}`; //  - ${grower.user.name}
          return grower;
        }).sort((a,b) => {
          return a.code - b.code;
        });

        if(selectedGrowerRefresh){
          this.selectedGrower = this.growers.find(growerInstance => {
            return growerInstance.id == selectedGrowerRefresh.id;
          });
        } else {
          this.selectedGrower = this.growers[0];
        }
        

        this.getDeliveries();

        // console.log(this.calculationsValue);
        // console.log(this.calculationsDisplay);
      });
    },
    generateExport: function(){
      this.loadingExport = true;
      let sheet = [];

      for (const delivery of this.calculatedDeliveries) {

        const row = {
          date: delivery.date,
          cert: delivery.cert,
          agtron: delivery.agtron,
          chg: delivery.chg.toFixed(2),
          delivered_gross: numeral(delivery.gross).format('0,0'),
          delivered_net: numeral(delivery.net).format('0,0'),
          value_per_ton: delivery.price,
          load_value: (delivery.value / 100),
          transfer: delivery.transfer,
        }

        sheet = [...sheet, row];
      }

      const csv = Papa.unparse(sheet);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      this.exportDownloadLink = url;

      this.loadingExport = false;
    },
    generateExportCalculation: function(){
      this.loadingExportCalculation = true;
      let sheet = [];

      let order = [
        "prd_ded_cr",
        "prd_ded_db",
        "mrk_fee",
        "mrk_hst",
        "plant",
        "replant",
        "plant_hst",
        "insurance",
        "blend",
        "solid",
        "shtprg",
        "shtprghst",
        "shtprgrec",
        "shtprgrechst",
        "misc"
      ];

      for (const index of order) {

        const row = {
          name: index,
          quantity: this.calculationsQty[index],
          value: this.calculationsDisplay[index],
          ammount: this.calculationsAmt[index],
        }

        sheet = [...sheet, row];
      }

      const csv = Papa.unparse(sheet);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      this.exportCalculationDownloadLink = url;

      this.loadingExportCalculation = false;
    },
    getDeliveries: function(){

      this.loadingDeliveries = true;

      // Passed start date formatting for query
      let [yearS, monthS, dayS] = this.startDate.split('-');
      let queryStartDate = new Date(yearS, monthS-1, dayS, 0, 0, 0, 0);

      let [yearE, monthE, dayE] = this.endDate.split('-');
      let queryEndDate = new Date(yearE, monthE-1, dayE, 0, 0, 0, 0);
        
      let Difference_In_Days = (queryEndDate.getTime() - queryStartDate.getTime()) / (1000 * 3600 * 24);

      axios.get('/delivery', {
        params: {
          scaled: true,
          scale_date: queryStartDate,
          scale_addDays: Difference_In_Days + 1
        }
      }).then((response) => {

        this.deliveries = response.data.deliveries.filter((el) => {
          if(el.grower.id == this.selectedGrower.id){
            el.start = new Date(el.start + ' UTC');
            el.end = new Date(el.end + ' UTC');
            el.scaled_at = new Date(el.scaled_at + ' UTC');
            el.name = el.grower.code;
            el.timed = true;
            return el;
          }
        });

        this.calculatedDeliveries = this.deliveries.map((delivery) => {
          let totalCullTear = delivery.grades[0].calculated_grade.mot.cullTear + delivery.grades[0].calculated_grade.grassGreen.cullTear + delivery.grades[0].calculated_grade.processingGreen.cullTear + delivery.grades[0].calculated_grade.processingBreakers.cullTear + delivery.grades[0].calculated_grade.otherDefects.cullTear + delivery.grades[0].calculated_grade.limitedUse.cullTear;
          return {
            scaledAt: new Date(delivery.scaled_at),
            date: `${delivery.scaled_at.getFullYear()}-${delivery.scaled_at.getMonth()+1}-${delivery.scaled_at.getDate()}`,
            cert: delivery.grades[0] ? delivery.grades[0].seasonal_certificate_id : 0,
            agtron: delivery.grades[0] ? delivery.grades[0].agtron : 0,
            chg: totalCullTear,
            gross: delivery.weight_in && delivery.weight_out ? delivery.weight_in - delivery.weight_out : 0,
            //net: delivery.weight_in - delivery.weight_out,
            net: (delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * totalCullTear),
            price: delivery.season.tomato_price / 100,
            value: (((delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * totalCullTear)) / 2000) * delivery.season.tomato_price,
            //value: (((delivery.weight_in - delivery.weight_out) / 2000) * delivery.season.tomato_price),
            transfer: (delivery.outside_processor && delivery.outside_processor_certificate) ? `${delivery.outside_processor} #${delivery.outside_processor_certificate}` : '-'
          }
        }).sort((a, b) => {
          return new Date(a.scaledAt) - new Date(b.scaledAt);
        });

        this.totalGross = 0;
        this.totalNet = 0;
        this.totalValue = 0;

        for(const delivery of this.calculatedDeliveries){
          this.totalGross += Number(delivery.gross);
          this.totalNet += Number(delivery.net);
          this.totalValue += Number(delivery.value);
        }

        this.loadingDeliveries = false;
      });
    },
    calculate: function(){
      // Add quantity 1
      this.calculationsQty.prd_ded_cr = this.totalValue;
      this.calculationsQty.prd_ded_db = this.totalValue;
      this.calculationsQty.mrk_fee = this.totalNet;
      this.calculationsQty.mrk_hst = this.totalNet;

      // Calculate value for plant and replant to be used in other qty
      this.calculationsAmt.plant = (Number(this.calculationsQty.plant) * Number(this.calculationsDisplay.plant)) / 1000;
      this.calculationsAmt.replant = (Number(this.calculationsQty.replant) * Number(this.calculationsDisplay.replant)) / 1000;

      // finish qty
      this.calculationsQty.plant_hst = this.calculationsAmt.plant + this.calculationsAmt.replant;

      // do calc of values
      this.calculationsAmt.prd_ded_cr = this.calculationsQty.prd_ded_cr * this.calculationsDisplay.prd_ded_cr;
      this.calculationsAmt.prd_ded_db = 0;
      this.calculationsAmt.mrk_fee = (this.calculationsQty.mrk_fee * this.calculationsDisplay.mrk_fee) / 2000;
      this.calculationsAmt.mrk_hst = (this.calculationsQty.mrk_hst * this.calculationsDisplay.mrk_hst) / 2000;
      this.calculationsAmt.plant_hst = (this.calculationsAmt.plant + this.calculationsAmt.replant) * this.calculationsDisplay.plant_hst;
      this.calculationsAmt.insurance = Number(this.calculationsQty.insurance * this.calculationsDisplay.insurance);
      this.calculationsAmt.blend = Number(this.calculationsQty.blend * this.calculationsDisplay.blend);
      this.calculationsAmt.misc = Number(this.calculationsQty.misc * this.calculationsDisplay.misc);

      this.calculationsAmt.solid = this.calculationsQty.solid * this.calculationsDisplay.solid;

      // shunting (Not differed than above, just added later)
      this.calculationsAmt.shtprg = this.calculationsQty.shtprg * this.calculationsDisplay.shtprg;
      this.calculationsQty.shtprghst = this.calculationsAmt.shtprg.toFixed(2);
      this.calculationsAmt.shtprghst = this.calculationsQty.shtprghst * this.calculationsDisplay.shtprghst;
      this.calculationsAmt.shtprgrec = this.calculationsQty.shtprgrec * this.calculationsDisplay.shtprgrec;
      this.calculationsAmt.shtprgrechst = this.calculationsDisplay.shtprgrechst * this.calculationsDisplay.shtprgrec;

      this.totalDeductions = (this.calculationsAmt.prd_ded_cr / 100) + (this.calculationsAmt.prd_ded_db / 100) + this.calculationsAmt.mrk_fee + this.calculationsAmt.mrk_hst + this.calculationsAmt.plant + this.calculationsAmt.replant + this.calculationsAmt.plant_hst + this.calculationsAmt.insurance + this.calculationsAmt.blend + this.calculationsAmt.misc + this.calculationsAmt.solid;
      this.totalDeductions += this.calculationsAmt.shtprg + this.calculationsAmt.shtprghst + this.calculationsAmt.shtprgrec + this.calculationsAmt.shtprgrechst;
    },
    updateValues: function(){
      let calculationsDisplayFormatted = {};

      for (let key in this.calculationsDisplay) {
        calculationsDisplayFormatted[key] = this.calculationsDisplay[key] * 100;
      }

      axios.patch(`/season/${this.currentSeason.id}`, {
        accounting_data: calculationsDisplayFormatted,
      }).then(() => {
        this.calculationsValue = calculationsDisplayFormatted;
        this.calculate();
      });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {
    
  },
  mounted: function() {

    Date.prototype.addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }

    this.loadDataReset();
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
