<template>
  <div>
    <v-row class="flex justify-center">
      <v-col md="6" sm="12">
        <v-card class="flex justify-center">
          <h1 class="text-center">Login</h1>
          <LogIn/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import LogIn from '@/components/LogIn.vue';

export default {
  name: 'LoginView',
  components: {
    LogIn
  },
  data: function() {
    return {

    }
  },
  methods: {
  },
  computed: {

  },
  watch: {

  },
  mounted: () => {
    
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
