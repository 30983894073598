<template>
  <div class="home">
    <v-row>
      <v-col>
        <v-row>
          <h1>Yard Management</h1>
        </v-row>

        <v-row>
          <v-card width="100%">
            <v-card-text>
              <!-- Here will be all loads that have been checked in and no processing, can be bypassed, averaged or grade -->
              <v-card-title>Arrived</v-card-title>

              <v-list>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Load ID
                        </th>
                        <th class="text-left">
                          Grower
                        </th>
                        <th class="text-left">
                          Trailer
                        </th>
                        <th class="text-left">
                          Field
                        </th>
                        <th class="text-left">
                          Variety
                        </th>
                        <th class="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="delivery in deliveriesArrived"
                        :key="delivery.id"
                      >
                        <td>{{ delivery.id }}</td>
                        <td>{{ delivery.grower.code }}</td>
                        <td>{{ delivery.trailer_number }}</td>
                        <td>{{ delivery.field.name }}</td>
                        <td>{{ delivery.variety.code }}</td>
                        <td class="text-right">
                          <v-btn color="primary" v-on:click="grade(delivery)">Grade</v-btn>
                          <v-btn color="primary" v-on:click="openBypass(delivery)">Bypass</v-btn>
                          <v-btn color="primary" v-on:click="openAverage(delivery);">Average</v-btn>
                          <v-btn color="primary" v-on:click="undoFromCheckin(delivery);">Undo</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-list>

            </v-card-text>
          </v-card>
        </v-row>

        <v-row>
          <v-card width="100%">
            <v-card-text>
              <!-- List of loads actively being graded -->
              <v-card-title>Grading</v-card-title>

              <v-list>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Load ID
                        </th>
                        <th class="text-left">
                          Grower
                        </th>
                        <th class="text-left">
                          Trailer
                        </th>
                        <th class="text-left">
                          Field
                        </th>
                        <th class="text-left">
                          Variety
                        </th>
                        <th class="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="delivery in deliveriesGrading"
                        :key="delivery.id"
                      >
                        <td>{{ delivery.id }}</td>
                        <td>{{ delivery.grower.code }}</td>
                        <td>{{ delivery.trailer_number }}</td>
                        <td>{{ delivery.field.name }}</td>
                        <td>{{ delivery.variety.code }}</td>
                        <td class="text-right">
                          <v-btn color="primary" v-on:click="undoFromGrade(delivery)">Undo</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-list>

            </v-card-text>
          </v-card>
        </v-row>

        <v-row>
          <v-card width="100%">
            <v-card-text>
              <!-- Here will be all loads that have been graded, can be regraded or sent to scale -->
              <v-card-title>Graded</v-card-title>

              <v-list>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Load ID
                        </th>
                        <th class="text-left">
                          Cert#
                        </th>
                        <th class="text-left">
                          Grower
                        </th>
                        <th class="text-left">
                          Trailer
                        </th>
                        <th class="text-left">
                          Field
                        </th>
                        <th class="text-left">
                          Variety
                        </th>
                        <th class="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="delivery in deliveriesGraded"
                        :key="delivery.id"
                      >
                        <td>{{ delivery.id }}</td>
                        <td>{{ delivery.grades[0].id }}</td>
                        <td>{{ delivery.grower.code }}</td>
                        <td>{{ delivery.trailer_number }}</td>
                        <td>{{ delivery.field.name }}</td>
                        <td>{{ delivery.variety.code }}</td>
                        <td class="text-right">
                          <v-btn color="primary" v-on:click="printCert(delivery)">Certificate</v-btn>
                          <v-btn color="primary" v-on:click="scale(delivery)">Scale</v-btn>
                          <v-btn v-if="twoValidGrades(delivery)" color="primary" v-on:click="regrade(delivery)">Regrade</v-btn>
                          <v-btn color="primary" v-on:click="reject(delivery)">Reject</v-btn>
                          <v-btn v-if="delivery.grades.length >= 1" color="primary" v-on:click="voidCert(delivery)">Void</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-list>

            </v-card-text>
          </v-card>
        </v-row>

        <v-row>
          <v-card width="100%">
            <v-card-text>
              <!-- Here will be all loads that have been graded, can be regraded or sent to scale -->
              <v-card-title>Scaling</v-card-title>

              <v-list>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Load ID
                        </th>
                        <th class="text-left">
                          Cert#
                        </th>
                        <th class="text-left">
                          Grower
                        </th>
                        <th class="text-left">
                          Trailer
                        </th>
                        <th class="text-left">
                          Field
                        </th>
                        <th class="text-left">
                          Variety
                        </th>
                        <th class="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="delivery in deliveriesScaling"
                        :key="delivery.id"
                      >
                        <td>{{ delivery.id }}</td>
                        <td>{{ delivery.grades[0].id }}</td>
                        <td>{{ delivery.grower.code }}</td>
                        <td>{{ delivery.trailer_number }}</td>
                        <td>{{ delivery.field.name }}</td>
                        <td>{{ delivery.variety.code }}</td>
                        <td class="text-right">
                          <v-btn color="primary" v-on:click="undoFromScaling(delivery)">undo</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-list>

            </v-card-text>
          </v-card>
        </v-row>
    
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="viewCertificate"
      width="100%"
      
    >
      <CertificateViewer :deliveryId="selectedDeliveryId" />
      <v-btn color="error" v-on:click="closeCert()">Close</v-btn>
    </v-overlay>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="openBypassOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card light style="width:300px">
              <v-card-title>Bypass certificate </v-card-title>
              <v-card-text>
                <p>Load Id: {{ bypassSelectedDelivery.id ? bypassSelectedDelivery.id : "" }}</p>
                <p>Grower: {{ bypassSelectedDelivery.grower ? bypassSelectedDelivery.grower.code : "" }}</p>
                <p>Certificate: {{ bypassSelectedDelivery.grades && bypassSelectedDelivery.grades.length > 0 ? ypassSelectedDelivery.grades[0].id : "NONE" }}</p>
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Bypass reason"
                  v-model="bypassReason"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="bypass()">
                  Save
                </v-btn>
                <v-btn color="error" text @click="openBypassOverlay = false">
                  Close
                </v-btn>
              </v-card-actions>

              <!-- <CertificateViewer :delivery="selectedDelivery" /> -->
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-overlay>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="openAverageOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card light style="width:300px">
              <v-card-title>Confirm average.</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Average reason"
                      v-model="averageReason"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <CertificatePreview :gradeData="previewAverageGrade"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="average()">
                  Save
                </v-btn>
                <v-btn color="error" text @click="openAverageOverlay = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-overlay>
    
  </div>
</template>

<script>

import axios from '../service/axios.config';

import CertificateViewer from '@/components/CertificateViewer.vue';
import CertificatePreview from '@/components/CertificatePreview.vue';

export default {
  name: 'YardManagementView',
  components: {
    CertificateViewer,
    CertificatePreview
  },
  data: function() {
    return {
      deliveries: [], // This is all deliveries that are checked in and not scaled out
      deliveriesArrived: [],
      deliveriesGrading: [],
      deliveriesGraded: [],
      deliveriesScaling: [],
      viewCertificate: false,
      selectedDeliveryId: null,
      openBypassOverlay: false,
      bypassSelectedDelivery: {},
      bypassReason: "",
      openAverageOverlay: false,
      averageSelectedDelivery: {},
      averageReason: "",
      previewAverageGrade: {
        defect_mot: '',
        defect_grass_green: '',
        defect_processing_green: '',
        defect_processing_breakers: '',
        defect_other: '',
        defect_limited_use: '',
        total_sample_weight: '',
        agtron: '',
        refractometer: ''
      },
    }
  },
  methods: {
    loadData: function() {
      axios.get('/delivery', {
          params: {
            arrived: true,
            scaled: false,
            active: true,
            rejected: false
          }
        }).then((response) => {

          this.deliveries = response.data.deliveries;

          // arrived but no processing
          this.deliveriesArrived = response.data.deliveries.filter((delivery) => {
            if(!delivery.sentToGrade && !delivery.graded && !delivery.sentToScale){
              return delivery
            }
          });

          this.deliveriesArrived.sort((a, b) => {
            return a.id - b.id;
          });

          // currently being graded
          this.deliveriesGrading = response.data.deliveries.filter((delivery) => {
            if(delivery.sentToGrade && !delivery.sentToScale){
              return delivery
            }
          });

          // finished grading
          this.deliveriesGraded = response.data.deliveries.filter((delivery) => {
            if(!delivery.sentToGrade && delivery.graded && !delivery.sentToScale){
              return delivery
            }
          });

          // At scale
          this.deliveriesScaling = response.data.deliveries.filter((delivery) => {
            if(!delivery.sentToGrade && delivery.graded && delivery.sentToScale){
              return delivery
            }
          });
        });
    },
    twoValidGrades: function(delivery){
      let validDeliveryCount = 0;

      delivery.grades.forEach(grade => {
        if(!grade.voided){
          validDeliveryCount ++;
        }
      });

      if(validDeliveryCount >= 2){
        return false;
      } else {
        return true;
      }
    },
    grade: function(delivery) {
      let updateDelivery = delivery;
      updateDelivery.sentToGrade = true;
      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then(() => {
        this.loadData();
      });
    },
    openBypass: function(delivery) {
      console.log(delivery);
      this.bypassSelectedDelivery = delivery;
      this.openBypassOverlay = true;
    },
    bypass: function() {
      axios.post(`/delivery/${this.bypassSelectedDelivery.id}/bypass`, {
        bypass_reason: this.bypassReason
      }).then(() => {
        this.openBypassOverlay = false;
        this.bypassReason = "";
        this.loadData();
      });
    },
    average: function() {
      axios.post(`/delivery/${this.averageSelectedDelivery.id}/average`, {
        average_reason: this.averageReason
      }).then(() => {
        this.openAverageOverlay = false;
        this.averageReason = "";
        this.loadData();
      });
    },
    openAverage: function(delivery) {

      axios.get(`/delivery/${delivery.id}/average`).then((response) => {
        this.previewAverageGrade.defect_mot = ((Number(response.data.grades[0].calculated_grade.mot.lbs) + Number(response.data.grades[1].calculated_grade.mot.lbs) / 2)),
        this.previewAverageGrade.defect_grass_green = ((Number(response.data.grades[0].calculated_grade.grassGreen.lbs) + Number(response.data.grades[1].calculated_grade.grassGreen.lbs) / 2)),
        this.previewAverageGrade.defect_processing_green = ((Number(response.data.grades[0].calculated_grade.processingGreen.lbs) + Number(response.data.grades[1].calculated_grade.processingGreen.lbs) / 2)),
        this.previewAverageGrade.defect_processing_breakers = ((Number(response.data.grades[0].calculated_grade.processingBreakers.lbs) + Number(response.data.grades[1].calculated_grade.processingBreakers.lbs) / 2)),
        this.previewAverageGrade.defect_other = ((Number(response.data.grades[0].calculated_grade.otherDefects.lbs) + Number(response.data.grades[1].calculated_grade.otherDefects.lbs) / 2)),
        this.previewAverageGrade.defect_limited_use = ((Number(response.data.grades[0].calculated_grade.limitedUse.lbs) + Number(response.data.grades[1].calculated_grade.limitedUse.lbs) / 2)),
        this.previewAverageGrade.total_sample_weight = ((Number(response.data.grades[0].calculated_grade.totalSampleWeight) + Number(response.data.grades[1].calculated_grade.totalSampleWeight) / 2)),
        this.previewAverageGrade.agtron = ((Number(response.data.grades[0].calculated_grade.agtron) + Number(response.data.grades[1].calculated_grade.agtron) / 2)),
        this.previewAverageGrade.refractometer = ((Number(response.data.grades[0].calculated_grade.refractometer) + Number(response.data.grades[1].calculated_grade.refractometer) / 2))
      });

      this.averageSelectedDelivery = delivery;
      this.openAverageOverlay = true;
    },
    printCert: function(delivery) {
      this.selectedDeliveryId = delivery.id;
      this.viewCertificate = true;
    },
    closeCert: function(){
      this.selectedDeliveryId = null;
      this.viewCertificate = false;
    },
    scale: function(delivery) {
      let updateDelivery = delivery;
      updateDelivery.sentToGrade = false;
      updateDelivery.graded = true;
      updateDelivery.sentToScale = true;
      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then(() => {
        this.loadData();
      });
    },
    regrade: function(delivery) {
      let updateDelivery = delivery;
      updateDelivery.sentToGrade = true;
      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then(() => {
        this.loadData();
      });
    },
    voidCert: function(delivery) {
      // Send delivery back to grade
      let updateDelivery = delivery;
      updateDelivery.sentToGrade = true;
      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then((response) => {

        // Update grade to be void
        let updateGrade = response.data.delivery.grades[0];
        updateGrade.voided = true;
        updateGrade.voided_reason = "reason";
        axios.patch(`/grade/${updateGrade.id}`, updateGrade).then(() => {
          this.loadData();
        });
      });
    },
    undoFromScaling: function(delivery){
      let updateDelivery = delivery;
      updateDelivery.sentToScale = false;
      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then(() => {
        this.loadData();
      });
    },
    undoFromGrade: function(delivery){
      let updateDelivery = delivery;
      updateDelivery.sentToGrade = false;
      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then(() => {
        this.loadData();
      });
    },
    undoFromCheckin: function(delivery){
      let updateDelivery = delivery;
      updateDelivery.field_id = null;
      updateDelivery.arrived = false;
      updateDelivery.trailer_number = null;
      updateDelivery.variety_id = null;
      updateDelivery.checkin_user_id = null;
      updateDelivery.arrived_at = null;

      axios.patch(`/delivery/${delivery.id}`, updateDelivery).then(() => {
        this.loadData();
      });
    },
    reject: function(delivery){
      axios.post(`/delivery/${delivery.id}/reject`).then(() => {
        this.loadData();
      });
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {

  },
  mounted: function() {
    this.loadData();

  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
