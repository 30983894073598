<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <v-rom>
          <v-col>
            <v-select
              v-model="selectedCountry"
              :items="countries"
              item-text="name"
              item-value="abbr"
              return-object
              label="Select Country"
              outlined
              v-on:change="selectCountry()"
            ></v-select>
          </v-col>
        </v-rom>

        <v-row v-if="Object.keys(selectedCountry).length != 0">
          <v-col cols="12">
            <v-text-field
              v-model="newProvince"
              label="New province name"
              solo
            ></v-text-field>
            <v-btn v-on:click="store()" color="primary" block>Add</v-btn>
          </v-col>
        </v-row>


        <v-row v-if="Object.keys(selectedCountry).length != 0">
          <v-col cols="12">
            <v-list>
              <template v-for="(province, index) in provinces">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="province.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn v-on:click="destroy(province)" color="error">Delete</v-btn>
                  </v-list-item-action>
                </v-list-item>
                  <v-divider
                    v-if="index < provinces.length - 1"
                    :key="index"
                  ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'ProvinceSettings',
    components: {},
    data: function() {
      return {
        countries: [],
        selectedCountry: {},
        provinces: [],
        newProvince: ''
      }
    },
    methods: {
      destroy: function(province){
        axios.delete(`/province/${province.id}`).then((response) => {
          if(response.status == 200){
            this.provinces = this.provinces.filter((loopedProvince) => {
              if(loopedProvince.id != province.id){
                return loopedProvince;
              }
            });
          }
        });
      },
      store: function(){
        axios.post(`/province`, {
          name: this.newProvince,
          countryId: this.selectedCountry.id
        }).then((response) => {
          this.provinces = [...this.provinces, response.data.province];
        });
        this.newProvince = '';
      },
      selectCountry: function(){
        axios.get('/province', {
          params: {
            countryId: this.selectedCountry.id
          }
        }).then((response) => {
          this.provinces = response.data.provinces;
        });
      }
    },
    watch: {

    },
    mounted: function() {
      axios.get('/country').then((response) => {
        this.countries = response.data.countries;
      });
    },
    created: function() {

    },
    destroyed: function() {

    }
  }
</script>
