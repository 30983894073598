<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="newVariety.code"
              label="New Tomato variety code"
              solo
            ></v-text-field>
            <v-textarea
              v-model="newVariety.description"
              solo
              name="input-7-4"
              label="New Tomato variety description"
            ></v-textarea>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="newVariety.juice"
                  label="Juice"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="newVariety.research"
                  label="Research"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="newVariety.active"
                  label="Active"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-btn v-on:click="store()" color="primary" block>Add</v-btn>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">
            <v-list>
              <template v-for="(variety, index) in varieties">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="variety.code"></v-list-item-title>
                    <v-list-item-content>{{variety.description}}</v-list-item-content>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          :input-value="variety.juice"
                          label="Juice"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          :input-value="variety.research"
                          label="Research"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          :input-value="variety.active"
                          label="Active"
                          disabled
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn v-on:click="destroy(variety)" color="error">Deactivate</v-btn>
                  </v-list-item-action>
                </v-list-item>
                  <v-divider
                    v-if="index < varieties.length - 1"
                    :key="index"
                  ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from '../service/axios.config';

  export default {
    name: 'VarietySettings',
    components: {},
    data: function() {
      return {
        varieties: [],
        newVariety: {
          code: '',
          description: '',
          juice: false,
          research: false,
          active: true
        }
      }
    },
    methods: {
      destroy: function(variety){
        axios.delete(`/variety/${variety.id}`).then((response) => {
          if(response.status == 200){
            this.varieties = this.varieties.map((loopedVarieties) => {
              if(loopedVarieties.id == variety.id){
                loopedVarieties.active = false;
              }
              return loopedVarieties;
            });
          }
        });
      },
      store: function(){
        axios.post(`/variety`, this.newVariety).then((response) => {
          this.varieties = [...this.varieties, response.data.variety];
        });
        this.newVariety = {
          code: '',
          description: '',
          juice: false,
          research: false,
          active: true
        };
      }
    },
    watch: {

    },
    mounted: function() {
      axios.get('/variety').then((response) => {
        this.varieties = response.data.varieties;
      });
    },
    created: function() {

    },
    destroyed: function() {

    }
  }
</script>
