<template>
  <div class="scaling">
    <h1>Grading</h1>

    <!-- <CertificateViewer :delivery="selectedDelivery"/> -->

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-container
            style="height: 80vh"
            class="overflow-y-auto"
          >
            <v-list two-line>
                <v-list-item v-for="(delivery, index) in deliveries" :key="delivery.id" v-on:click="selectDelivery(delivery)">
                  <v-list-item-content>
                    <v-list-item-title>{{ 'Grower ' + (delivery.grower ? delivery.grower.code : '' ) + ': ' + (delivery.grower ? delivery.grower.name : '' ) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ 'Trailer No. ' + delivery.trailer_number }}</v-list-item-subtitle>
                    <!-- <v-list-item-title v-text="'Trailer No. ' + delivery.trailer_number"></v-list-item-title>
                    <v-list-item-subtitle v-text="'Grower: ' + delivery.grower.code"></v-list-item-subtitle> -->
                    <v-list-item-subtitle>{{ 'Grades: ' + delivery.grades.length }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ delivery.start.toLocaleTimeString() }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-divider
                    v-if="index < deliveries.length - 1"
                    :key="index"
                  ></v-divider>
                </v-list-item>
            </v-list>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>Delivery Viewer</v-card-title>
          <v-card-text v-if="selectedDelivery && Object.keys(selectedDelivery ? selectedDelivery : {}).length > 0">
            <v-row>
              <v-col cols="12" class="text-left">
                <p>ID: {{selectedDelivery.id}}</p>
                <p>Trailer No: {{selectedDelivery.trailer_number}}</p>
                <p>Grower Code: {{selectedDelivery.grower ? selectedDelivery.grower.code : ''}} - {{ (selectedDelivery.grower ? selectedDelivery.grower.name : '') }}</p>
                <p>Start: {{selectedDelivery.start.toLocaleTimeString()}}</p>
                <p>End: {{selectedDelivery.end.toLocaleTimeString()}}</p>
                <p>Weight In: {{selectedDelivery.weight_in}}</p>
                <p>Weight Out: {{selectedDelivery.weight_out}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-btn v-if="!graded" color="primary" v-on:click="grade()">Grade</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn v-if="selectedDelivery.grades.length > 0" color="primary" v-on:click="openCertOverlay()">Print Cert</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn v-if="graded && certPrinted" color="primary" v-on:click="done()">Done</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      :value="overlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card light>
              <v-card-title>Grade</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    Grower: {{ (selectedDelivery.grower ? selectedDelivery.grower.code : '') }} - {{ (selectedDelivery.grower ? selectedDelivery.grower.name : '') }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="gradeData.defect_mot"
                      label="M.O.T"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.defect_grass_green"
                      label="Grass Green"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.defect_processing_green"
                      label="Processing Green"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.defect_processing_breakers"
                      label="Processing Breakers"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.defect_other"
                      label="Other"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.defect_limited_use"
                      label="Limited Use"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <!-- <v-text-field
                      v-model="gradeData.total_sample_weight"
                      label="Total Sample Weight"
                      outlined
                      :disabled="true"
                    ></v-text-field> -->
                    <v-text-field
                      v-model="gradeData.clean_weight"
                      label="Total Clean Weight"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.agtron"
                      label="AGTRON"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                    <v-text-field
                      v-model="gradeData.refractometer"
                      label="Refractometer"
                      outlined
                      :disabled="inVerification"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="inVerification">
                    <CertificatePreview :gradeData="gradeData"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="closeOverlay()">
                  Close
                </v-btn>
                <v-btn v-if="!inVerification" color="primary" text @click="confirmGrade()">
                  Confirm
                </v-btn>
                <v-btn v-if="inVerification" color="primary" text @click="saveGrade()">
                  save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-overlay>

    <!-- <v-overlay
      :z-index="11"
      :absolute="true"
      :value="certOverlay"
      class="d-flex align-start"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card light>
              <v-card-title>Certificate Viewer</v-card-title>
              <v-card-text>
                <CertificateViewer :delivery="selectedDelivery"/>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" v-on:click="certOverlay = false">close</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-overlay> -->

    <!-- <CertificateViewer :delivery="selectedDelivery"/> -->

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="certOverlay"
      width="100%"
      
    >
      <CertificateViewer :deliveryId="selectedDelivery.id" />
      <v-btn color="error" v-on:click="closeCert()">Close</v-btn>
    </v-overlay>

  </div>
</template>

<script>

import axios from '../service/axios.config';
import CertificateViewer from '@/components/CertificateViewer.vue';
import CertificatePreview from '@/components/CertificatePreview.vue';

export default {
  name: 'GradingView',
  components: {
    CertificateViewer,
    CertificatePreview
  },
  data: function() {
    return {
      selectedDelivery: {},
      deliveries: [],
      overlay: false,
      gradeData: {
        defect_mot: '',
        defect_grass_green: '',
        defect_processing_green: '',
        defect_processing_breakers: '',
        defect_other: '',
        defect_limited_use: '',
        total_sample_weight: '',
        clean_weight: '',
        agtron: '',
        refractometer: ''
      },
      certOverlay: false,
      key: true,
      inVerification: false,
      certPrinted: false,
      graded: false,
    }
  },
  methods: {
    done: function(){
      this.deliveries = null;
      this.loadData();
    },
    loadData: function(){
      this.selectedDelivery = {};
      this.deliveries = [];
      this.gradeData = {
        defect_mot: '',
        defect_grass_green: '',
        defect_processing_green: '',
        defect_processing_breakers: '',
        defect_other: '',
        defect_limited_use: '',
        total_sample_weight: '',
        clean_weight: '',
        agtron: '',
        refractometer: ''
      }
      this.overlay = false;
      this.certOverlay = false;
      this.inVerification = false;
      this.key = true;
      this.certPrinted = false;
      this.graded = false;



      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      axios.get('/delivery', {
        params: {
          sentToGrade: true
        }
      }).then((response) => {
        this.deliveries = response.data.deliveries.filter((el) => {
          if(!el.weight_in){
            el.start = new Date(el.start  + ' UTC');
            el.end = new Date(el.end  + ' UTC');
            return el;
          }
        }).sort((el1, el2) => {
          return el1.start - el2.start;
        });

        this.selectDelivery(this.deliveries[0]);
      });
    },
    confirmGrade: function(){
      // setTimeout(() => {
      //   this.inVerification = true;
      // }, 1500);
      this.gradeData.total_sample_weight = Number(this.gradeData.defect_mot) + Number(this.gradeData.defect_grass_green) + Number(this.gradeData.defect_processing_green) + Number(this.gradeData.defect_processing_breakers) + Number(this.gradeData.defect_other) + Number(this.gradeData.defect_limited_use) + Number(this.gradeData.clean_weight);
      this.inVerification = true;
    },
    openCertOverlay: function(){
      this.certOverlay = true;
      this.certPrinted = true;
    },
    closeCert(){
      this.certOverlay = false;
    },
    selectDelivery: function(delivery){
      this.selectedDelivery = delivery;
      this.selectDeliveryGrade(delivery.grades[delivery.grades.length - 1]);
    },
    selectDeliveryGrade(grade){
      this.selectedGrade = grade;
    },
    grade(){
      this.overlay = true;
    },
    saveGrade(){
      axios.post('/grade', {
        delivery_id: this.selectedDelivery.id,
        variety_id: this.selectedDelivery.variety_id,
        defect_mot: this.gradeData.defect_mot,
        defect_grass_green: this.gradeData.defect_grass_green,
        defect_processing_green: this.gradeData.defect_processing_green,
        defect_processing_breakers: this.gradeData.defect_processing_breakers,
        defect_other: this.gradeData.defect_other,
        defect_limited_use: this.gradeData.defect_limited_use,
        total_sample_weight: this.gradeData.total_sample_weight,
        agtron: this.gradeData.agtron,
        refractometer: this.gradeData.refractometer,
      }).then((request) => {
        this.deliveries = this.deliveries.map((delivery) => {
          if(request.data.grade.id == delivery.id){
            delivery.grades = [...delivery.grades, request.data.grade];
          }
          return delivery;
        });

        this.selectedDelivery.grades = [...this.selectedDelivery.grades, request.data.grade];
        this.selectedGrade = request.data.grade;

        this.closeOverlay();

        this.inVerification = false;

        this.graded = true;
      });
    },
    closeOverlay(){
      this.gradeData = {
        defect_mot: '',
        defect_grass_green: '',
        defect_processing_green: '',
        defect_processing_breakers: '',
        defect_other: '',
        defect_limited_use: '',
        total_sample_weight: '',
        agtron: '',
        refractometer: ''
      }
      this.overlay = false;
      this.inVerification = false;
    }
  },
  computed: {
    authenticated: function() {
      return this.$store.getters['AuthStore/authenticated'];
    }
  },
  watch: {
    selectedGrade: function(){
    }
  },
  mounted: function(){

    this.loadData();
  },
  created: () => {

  },
  destroyed: () => {

  }
}
</script>
